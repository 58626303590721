<template>
	<v-card height="100%">
		<v-card-text>
			<h3 class="mb-3 fs--23 fw--500">Quick Adjust</h3>
			<v-row align="center" no-gutters>
				<v-col cols="4" md="4" xl="3">
					<span class="fs--14 fw--500">Stickiness:</span>
				</v-col>
				<v-col cols="5" md="4" xl="6">
					<v-slider v-model="data.stickiness" color="pink" :min="10" :max="45" thumb-label hide-details>
						<template #thumb-label>
							<span class="px-2">{{ data.stickiness }}</span>
						</template>
					</v-slider>
				</v-col>

				<v-col cols="3" md="4" xl="3" class="text-right">
					<v-btn
						depressed
						color="pink"
						:small="$vuetify.breakpoint.mdOnly"
						:x-small="$vuetify.breakpoint.xsOnly"
						@click="actions.globalQuickAdjust(StickerQuickAdjustField.STICKINESS)">
						<span>Apply</span>
					</v-btn>
				</v-col>

				<v-col cols="4" md="4" xl="3" class="mt-3 mb-3">
					<span class="fs--14 fw--500">Volume:</span>
				</v-col>
				<v-col cols="5" md="4" xl="6" class="mt-3 mb-3">
					<v-slider v-model="data.volume" :min="0" :max="100" thumb-label color="pink" hide-details>
						<template #thumb-label>
							<span class="px-2">{{ data.volume }}%</span>
						</template>
					</v-slider>
				</v-col>

				<v-col cols="3" md="4" xl="3" class="text-right">
					<v-btn
						depressed
						color="pink"
						:small="$vuetify.breakpoint.mdOnly"
						:x-small="$vuetify.breakpoint.xsOnly"
						@click="actions.globalQuickAdjust(StickerQuickAdjustField.VOLUME)">
						<span>Apply</span>
					</v-btn>
				</v-col>
				<template v-if="doShowBits">
					<v-col cols="4" md="2" lg="4" xl="3">
						<span class="fs--14 fw--500">Bits:</span>
					</v-col>
					<v-col cols="4" md="6" lg="4" xl="6" class="pl-2">
						<BitsDropdown
							:sku="data.sku"
							:isGlobalTts="isGlobalTTS"
							@onChange="actions.changeBits"></BitsDropdown>
					</v-col>
					<v-col cols="4" md="4" xl="3" class="text-right">
						<v-btn
							depressed
							color="pink"
							:small="$vuetify.breakpoint.mdOnly"
							:x-small="$vuetify.breakpoint.xsOnly"
							@click="actions.globalQuickAdjust(StickerQuickAdjustField.SKU)">
							<span>Apply</span>
						</v-btn>
					</v-col>
				</template>
				<v-col cols="5" md="5" xl="4" :class="!doShowBits ? 'mt-0 mb-0' : 'mt-3 mb-3'">
					<span class="fs--14 fw--500">Custom Placement:</span>
				</v-col>
				<v-col cols="4" md="3" xl="5" :class="!doShowBits ? 'mt-0 mb-0' : 'mt-3 mb-3'">
					<v-switch
						v-model="data.isStickerPlacementEnabled"
						color="pinkItem"
						class="ma-0 ml-3"
						hide-details></v-switch>
				</v-col>
				<v-col cols="3" md="4" xl="3" class="text-right" :class="!doShowBits ? 'mt-0 mb-0' : 'mt-1'">
					<v-btn
						depressed
						color="pink"
						:small="$vuetify.breakpoint.mdOnly"
						:x-small="$vuetify.breakpoint.xsOnly"
						@click="actions.globalQuickAdjust(StickerQuickAdjustField.CUSTOM_PLACEMENT)">
						<span>Apply</span>
					</v-btn>
				</v-col>
			</v-row>

			<v-row :class="[isTTS && !isGlobalTTS ? 'mt-7 mt-lg-15' : isGlobalTTS ? 'mt-4' : 'mt-7']" no-gutters>
				<v-col v-if="data.isGlobalEnableDisable" cols="12" sm="6" class="px-sm-2 my-2 my-sm-0">
					<v-btn @click="actions.toggleEnabled(true)" depressed color="pink" block outlined class="grid-btn">
						Enable All
					</v-btn>
				</v-col>
				<v-col v-if="data.isGlobalEnableDisable" cols="12" sm="6">
					<v-btn @click="actions.toggleEnabled(false)" depressed color="pink" block outlined class="grid-btn">
						Disable All
					</v-btn>
				</v-col>
			</v-row>
		</v-card-text>
	</v-card>
</template>

<script>
import { computed, reactive } from '@vue/composition-api';
import axios from '@axios';
import { StickerType, StickerDefaults, StickerQuickAdjustField } from '../../../utils/constants';
import { rangeValueConverter } from '../../../utils/sticker';
import BitsDropdown from '@/views/shared/BitsDropdown.vue';
import { mdiChevronDown } from '@mdi/js';

export default {
	props: {
		stickerType: {
			type: String,
			required: true,
		},
	},
	components: {
		BitsDropdown,
	},
	emits: ['onGlobalChangesMade'],
	setup(props, ctx) {
		const data = reactive({
			stickiness: rangeValueConverter.toUiValue(StickerDefaults.STICKINESS, 10, 45, 100),
			volume: StickerDefaults.VOLUME,
			sku: StickerDefaults.SKU,
			isGlobalEnableDisable: props.stickerType != StickerType.REWARD,
			isStickerPlacementEnabled: StickerDefaults.IS_STICKER_PLACEMENT_ENABLED,
			field: null,
		});

		const doShowBits = computed(() => {
			return props.stickerType != StickerType.TTS;
		});

		const actions = {
			// Called after the # of bits is selected.
			changeBits: function (item) {
				data.sku = item.value;
			},
			// Globally enable / disable all stickers.
			toggleEnabled: function (value) {
				let url;
				if (props.stickerType == StickerType.GLOBAL || props.stickerType == StickerType.GLOBAL_AI_TTS) {
					url = `/global-sticker/global-status`;
				} else {
					url = `/custom-sticker/global-status/${props.stickerType}`;
				}

				const payload = {
					isEnabled: value,
					isTts: props.stickerType === StickerType.GLOBAL_AI_TTS,
				};

				axios
					.put(url, payload)
					.then(() => ctx.emit('onGlobalChangesMade'))
					.catch(error => console.log(error));
			},
			// Globally adjust stickers values.
			globalQuickAdjust: key => {
				let url;
				if (props.stickerType == StickerType.GLOBAL || props.stickerType == StickerType.GLOBAL_AI_TTS) {
					url = `/global-sticker/global-settings`;
				} else {
					url = `/custom-sticker/global-settings/${props.stickerType}`;
				}

				// Convert stickiness back to a database value.
				const payload = { ...data };
				payload.stickiness = rangeValueConverter.toDbValue(payload.stickiness, 10, 45, 100);
				payload.field = key;
				payload.isTts = props.stickerType === StickerType.GLOBAL_AI_TTS;

				axios
					.put(url, payload)
					.then(() => ctx.emit('onGlobalChangesMade'))
					.catch(error => console.log(error));
			},
		};

		return {
			actions,
			data,
			StickerType,
			isCustom: props.stickerType == StickerType.CUSTOM,
			isReward: props.stickerType == StickerType.REWARD,
			isGlobal: props.stickerType == StickerType.GLOBAL,
			isTTS: props.stickerType == StickerType.TTS || props.stickerType === StickerType.GLOBAL_AI_TTS,
			isGlobalTTS: props.stickerType === StickerType.GLOBAL_AI_TTS,
			doShowBits,
			StickerQuickAdjustField,
			icons: {
				arrow: mdiChevronDown,
			},
		};
	},
};
</script>
