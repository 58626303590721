<template>
    <div class="mouth-control d-flex flex-column flex-sm-row align-center py-3">
        <div class="mouth-images px-2 d-flex flex-column align-center">
            <h4 class="fs--16 fw--400 text-center">Mouth Style</h4>
            <div class="d-flex align-center">
                <v-btn color="secondary" class="px-0 slide-btn" small depressed
                    @click="actions.slideMouth('left')">
                    <v-icon>{{ icons.arrowLeft }}</v-icon>
                </v-btn>
                <v-card color="lightPurple" flat height="100">
                    <v-carousel v-model="mouthItem" hide-delimiters>
                        <v-carousel-item v-for="(item, i) in mouthImages.items" :key="i"
                            reverse-transition="fade-transition" transition="fade-transition">
                            <template #default>
                                <div :class="mouthConfig.config.isMouthFlipped ? 'flipped' : 'regular'" class="current-mouth cursor-pointer"
                                    :style="{ 'background-image': `url(${actions.getImage('mouth', item, 'slider')})` }"
                                    @click="actions.setCurrentMouth(item)">
                                </div>
                                <div class="text-center image-name fieldFore--text">{{
                                    item.name.substr(0, 12) }}{{ item.name.length > 12 && '...' || '' }}
                                </div>
                            </template>
                        </v-carousel-item>

                        <template v-slot:prev="{ on, attrs }">
                            <v-btn class="arrow-btn left-mouth d-none" icon v-bind="attrs" v-on="on">
                                <v-icon>{{ icons.arrowLeft }}</v-icon>
                            </v-btn>
                        </template>

                        <template v-slot:next="{ on, attrs }">
                            <v-btn class="arrow-btn right-mouth d-none" icon v-bind="attrs" v-on="on">
                                <v-icon>{{ icons.arrowRight }}</v-icon>
                            </v-btn>
                        </template>
                    </v-carousel>
                </v-card>
                <v-btn color="secondary" class="px-0 slide-btn" small depressed
                    @click="actions.slideMouth('right')">
                    <v-icon>{{ icons.arrowRight }}</v-icon>
                </v-btn>
            </div>
            <div class="mt-3 enable-switch position-absolute">
                <v-switch v-model="mouthConfig.config.isMouth" color="pink" label="Enable" hide-details></v-switch>
            </div>

            <div class="mt-3 flip-switch position-absolute">
                <v-switch v-model="mouthConfig.config.isMouthFlipped" color="pink" label="Flip" hide-details></v-switch>
            </div>
        </div>
        <v-divider class="boldPurple mt-4 mt-sm-0" :vertical="$vuetify.breakpoint.smAndUp"></v-divider>
        <div class="mouth-uploader d-flex flex-column align-center justify-center">
            <span class="text-center fs--14">Add Custom <br> Mouth</span>

            <div v-if="!isMouthUploaded" class="mt-3 text-center">
                <div class="fs--13">Talking</div>
                <v-btn color="primary" class="mt-1" small depressed
                    @click="actions.uploadImage('mouth')">
                    Upload
                </v-btn>
            </div>

            <template v-if="isMouthUploaded">
                <div class="d-flex align-center mt-3">
                    <img src="@/assets/icons/check-circle.png" alt="Uploaded">
                    <v-btn depressed small class="px-1 ml-3" min-width="35" min-height="35"
                        color="secondary" @click="actions.removeMouth()">
                        <v-icon color="white">{{ icons.trashIcon }}</v-icon>
                    </v-btn>
                </div>
            </template>

            <input type="file" id="animatedInput" accept="image/gif" class="d-none" @change="actions.onMouthPick"
                ref="mouthInput" />

            <div class="mt-3 text-center">
                <div class="fs--13">Closed</div>
                <v-btn v-if="!isClosedMouthUploaded" color="primary" class="mt-1" depressed small :disabled="!isMouthUploaded"
                    @click="actions.uploadImage('closedMouth')">
                    Upload
                </v-btn>
            </div>

            <template v-if="isClosedMouthUploaded">
                <div class="d-flex align-center mt-3">
                    <img src="@/assets/icons/check-circle.png" alt="Uploaded">
                    <v-btn depressed small class="px-1 ml-3" min-width="35" min-height="35"
                        color="secondary" @click="actions.removeClosedMouth()">
                        <v-icon color="white">{{ icons.trashIcon }}</v-icon>
                    </v-btn>
                </div>
            </template>


            <input type="file" id="closedInput" accept="image/gif, image/x-png" class="d-none" @change="actions.onClosedMouthPick"
                ref="closedMouthInput" />
        </div>
    </div>
</template>

<script>
import { computed, ref, reactive, onMounted, watch } from '@vue/composition-api';
import { TTS_ASSETS_BASE, mouthPresets } from '@/utils/utils';
import { getHostedAssetPath } from '@/utils/config.js';
import { fileToBase64 } from '@/utils/sticker.js';
import { mdiCloudUploadOutline, mdiChevronLeft, mdiChevronRight, mdiUpload, mdiDelete } from '@mdi/js';

export default {
    props: {
        sticker: {
            type: Object,
            required: true,
        },
        customAnimatedMouth: {
            type: Object | String,
            default: null,
        },
        customClosedMouth: {
            type: Object | String,
            default: null,
        },
    },
    emits: ['onMouthConfig', 'onChangeCustomMouth'],
    setup(props, ctx) {

        const presetPath = '0.0.1/tts/'
        const customPath = 'custom_stickers/'
        const base64 = 'data:image/png;base64,'

        const mouthImages = reactive({
            items: [...mouthPresets]
        });

        const mouthFiles = mouthPresets.map(img => img.file);

        const mouthItem = ref(0);

        const mouthConfig = reactive({
            config: {
                isMouth: true,
                isMouthFlipped: false,
                currentMouth: mouthImages.items[0],
                mouthBlob: null,
                closedMouthBlob: null,
            }
        })

        const isMouthUploaded = ref(false);
        const isClosedMouthUploaded = ref(false);

        const mouthInput = ref(null);
        const closedMouthInput = ref(null);
        
        onMounted(() => {
            actions.setMouthConfig();
        })

        watch(mouthConfig.config, (val) => {
            ctx.emit('onMouthConfig', val)
        }, { immediate: true, deep: true })

        

        const actions = {
            setMouthConfig() {
                const tts = { ...props.sticker.tts }

                mouthConfig.config.isMouth = tts.isMouthEnabled
                mouthConfig.config.isMouthFlipped = tts.isMouthFlipped
                mouthConfig.config.currentMouth = tts.mouthImage
                mouthConfig.config.mouthBlob = tts.mouthBlob

                actions.setMouthImage();
                actions.checkCustomAsset();
            },
            setMouthImage() {
                /*
                    1. Create Sticker or Edit Sticker.

                    2. If Create Sticker then check if there is any mouth configured else select first mouth from presets.
                    
                    3. 
                */

                if (props.customAnimatedMouth) {
                    mouthImages.items.push(props.customAnimatedMouth)
                    isMouthUploaded.value = true
                }

                if (props.customClosedMouth) {
                    mouthConfig.config.closedMouthBlob = props.customClosedMouth.file
                    isClosedMouthUploaded.value = true
                }

                if (!props.sticker.id) {
                    const currentMouth = mouthConfig.config.currentMouth;
                    if(!currentMouth) {
                        mouthConfig.config.currentMouth = mouthImages.items[0];
                    } else {
                        // Set the selected eye from the slider
                        const selectedMouth = mouthImages.items.findIndex((img) => img.file === mouthConfig.config.currentMouth.file)

                        if (selectedMouth >= 0) {
                            mouthItem.value = selectedMouth;
                        }
                        // else {
                        //     mouthImages.items.push(mouthConfig.config.currentMouth);
                        //     mouthItem.value = mouthImages.items.length - 1;
                        // }
                    }

                    // Check Closed mouth
                    // if (props.sticker.tts.closedMouthBlob && !props.sticker.tts.closedMouthBlob.startsWith(customPath)) {
                    //     mouthConfig.config.closedMouthBlob = props.sticker.tts.closedMouthBlob
                    //     isClosedMouthUploaded.value = true
                    // }
                } else {
                    // Set Images for Edit
                    if (typeof props.sticker.tts.mouthImage === 'string' && props.sticker.tts.mouthImage.startsWith(presetPath)) {

                        const name = props.sticker.tts.mouthImage.split('/').at(-1);

                        const selectedMouth = mouthImages.items.findIndex((img) => img.file === name)
                        if (selectedMouth >= 0) {
                            mouthItem.value = selectedMouth;
                            mouthConfig.config.currentMouth = mouthImages.items[selectedMouth];
                        }
                    } else if (typeof props.sticker.tts.mouthImage === 'object' && typeof props.sticker.tts.mouthImage.file === 'string' && !props.sticker.tts.mouthImage.file.startsWith(presetPath) && mouthFiles.includes(props.sticker.tts.mouthImage.file)) {
                        // Set Mouth Image
                        const selectedMouth = mouthImages.items.findIndex((img) => img.file === mouthConfig.config.currentMouth.file)
                        if (selectedMouth >= 0) {
                            mouthItem.value = selectedMouth;
                            mouthConfig.config.currentMouth = mouthImages.items[selectedMouth];
                        }
                    }


                    else if (typeof props.sticker.tts.mouthImage === 'string' && props.sticker.tts.mouthImage.startsWith(customPath) && !mouthFiles.includes(props.sticker.tts.mouthImage)) {
                        // Set Mouth Image
                        // const item = {
                        //     name: 'Custom Mouth',
                        //     file: props.sticker.tts.mouthImage
                        // }

                        // mouthImages.items.push(item)

                        const selectedMouth = mouthImages.items.findIndex((img) => img.file === mouthConfig.config.currentMouth.file)
                        if (selectedMouth >= 0) {
                            mouthItem.value = selectedMouth;
                        }

                        mouthItem.value = mouthImages.items.length - 1;
                        mouthConfig.config.currentMouth = mouthImages.items.at(-1);
                        isMouthUploaded.value = true
                    } 
                    else if (typeof props.sticker.tts.mouthImage === 'object' && typeof props.sticker.tts.mouthImage.file === 'string' && props.sticker.tts.mouthImage.file.startsWith(customPath) && !mouthFiles.includes(props.sticker.tts.mouthImage.file)) {
                        // Set Mouth Image
                        const selectedMouth = mouthImages.items.findIndex((img) => img.file === mouthConfig.config.currentMouth.file)

                        if (selectedMouth >= 0) {
                            mouthItem.value = selectedMouth;
                        }

                        if (selectedMouth >= 0) {
                            mouthItem.value = selectedMouth;
                            isMouthUploaded.value = true
                        }
                        
                    }

                    else if (typeof props.sticker.tts.mouthImage === 'object' && typeof props.sticker.tts.mouthImage.file === 'object') {
                        const selectedMouth = mouthImages.items.findIndex((img) => img.file === mouthConfig.config.currentMouth.file)

                        if (selectedMouth >= 0) {
                            mouthItem.value = selectedMouth;
                        }
                    }
                }


                // if (props.sticker.tts.closedMouthBlob && !props.sticker.tts.closedMouthBlob.startsWith(customPath)) {
                //     mouthConfig.config.closedMouthBlob = props.sticker.tts.closedMouthBlob
                // }

                // else if (props.sticker.tts.closedMouthImage && props.sticker.tts.closedMouthImage.startsWith(customPath)) {
                //     closedMouth.value = tts.closedMouthImage
                // }
                
            },

            checkCustomAsset() {
                // Check is Custom Mouth Exists in the Array
                const isCustomMouthExists = mouthImages.items.find(mouth => typeof mouth.file === 'object' || (typeof mouth.file === 'string' && mouth.file.includes(customPath)))
                
                if (isCustomMouthExists) {
                    isMouthUploaded.value = true
                }

                // if (props.customAnimatedMouth) {
                //     mouthImages.items.push(props.customAnimatedMouth)
                //     isMouthUploaded.value = true
                // }

                // if (props.customClosedMouth) {
                //     mouthConfig.config.closedMouthBlob = props.customClosedMouth.file
                //     isClosedMouthUploaded.value = true
                // }

                // Check Closed Mouth Uploaded
                if (props.sticker.id) {
                    if (props.sticker.tts.closedMouthBlob && typeof props.sticker.tts.closedMouthBlob === 'string' && props.sticker.tts.closedMouthBlob.startsWith(base64)) {
                        isClosedMouthUploaded.value = true
                        mouthConfig.config.closedMouthBlob = props.sticker.tts.closedMouthBlob
                    }
                    else if (props.sticker.tts.closedMouthImage && typeof props.sticker.tts.closedMouthImage === 'string' && typeof props.sticker.tts.mouthImage === 'string' && props.sticker.tts.mouthImage.startsWith(customPath)) {
                        isClosedMouthUploaded.value = true
                        mouthConfig.config.closedMouthBlob = props.sticker.tts.closedMouthImage
                    }
                    else if (props.sticker.tts.closedMouthImage && typeof props.sticker.tts.closedMouthImage === 'string' && typeof props.sticker.tts.mouthImage === 'object' && typeof props.sticker.tts.mouthImage.file === 'string' && props.sticker.tts.mouthImage.file.startsWith(customPath)) {
                        isClosedMouthUploaded.value = true
                        mouthConfig.config.closedMouthBlob = props.sticker.tts.closedMouthImage
                    }

                    // if (props.sticker.tts.closedMouthImage && props.sticker.tts.closedMouthImage.startsWith(customPath) && typeof props.sticker.tts.mouthImage.file === 'string' && props.sticker.tts.mouthImage.file.startsWith(customPath)) {
                    //     isClosedMouthUploaded.value = true
                    //     mouthConfig.config.closedMouthBlob = props.sticker.tts.closedMouthImage
                    // }

                    // if (props.sticker.tts.closedMouthBlob && !props.sticker.tts.closedMouthImage.startsWith(customPath)) {
                    //     isClosedMouthUploaded.value = true
                    //     mouthConfig.config.closedMouthBlob = props.sticker.tts.closedMouthBlob
                    // }
                } else {
                    if (props.sticker.tts.closedMouthBlob && typeof props.sticker.tts.closedMouthImage === 'string' && !props.sticker.tts.closedMouthImage.startsWith(customPath)) {
                        isClosedMouthUploaded.value = true
                        mouthConfig.config.closedMouthBlob = props.sticker.tts.closedMouthBlob
                    }
                }
            },

            slideMouth(direction) {
                const elem = document.querySelector(`.${direction}-mouth`)
                elem.click()

                actions.selectMouth(direction)
            },
            selectMouth(direction) {
                const total = mouthImages.items.length;
                const currentIndex = mouthImages.items.findIndex((image, index) => image.name === mouthConfig.config.currentMouth.name)

                if (direction === 'right') {
                    if (currentIndex === total - 1) {
                        mouthConfig.config.currentMouth = mouthImages.items[0];
                    } else {
                        mouthConfig.config.currentMouth = mouthImages.items[currentIndex + 1];
                    }
                }

                if (direction === 'left') {
                    if (currentIndex === 0) {
                        mouthConfig.config.currentMouth = { ...mouthImages.items[total - 1] };
                    } else {
                        mouthConfig.config.currentMouth = { ...mouthImages.items[currentIndex - 1] };
                    }
                }

                // Remove Custom Closed Mouth if there
                // mouthConfig.config.closedMouthBlob = null
                // isClosedMouthUploaded.value = false
            },
            setCurrentMouth(mouth) {
                mouthConfig.config.currentMouth = { ...mouth }
            },
            uploadImage(type) {
                if (type === 'mouth') {
                    mouthInput.value.click();
                }
                if (type === 'closedMouth') {
                    closedMouthInput.value.click();
                } 
            },
            async onMouthPick(e) {
                const file = e.target.files[0]
                if (file) {
                    const item = {
                        name: file.name.split('.')[0],
                        file: file
                    }

                    if (isMouthUploaded.value) {
                        mouthImages.items[mouthImages.items.length - 1] = { ...item };
                    } else {
                        mouthConfig.config.mouthBlob = await fileToBase64(file);
                        mouthImages.items.push(item);
                    }
                    actions.setCurrentMouth(item)
                    mouthItem.value = mouthImages.items.length - 1;

                    ctx.emit('onChangeCustomMouth', {
                        type: 'mouth',
                        add: true,
                        animated: {
                            file: file,
                            name: item.name
                        }
                    })

                    isMouthUploaded.value = true;

                    document.getElementById('animatedInput').value = ''
                }
            },
            removeMouth() {
                mouthImages.items = JSON.parse(JSON.stringify(mouthImages.items.slice(0, -1)));
                isMouthUploaded.value = false;

                // Set first element by default
                mouthConfig.config.currentMouth = mouthImages.items[0]
                mouthItem.value = 0;

                actions.removeClosedMouth();

                ctx.emit('onChangeCustomMouth', {
                    type: 'mouth',
                    remove: true,
                    animated: null,
                    closed: null
                })
            },
            async onClosedMouthPick(e) {
                const file = e.target.files[0]
                if (file) {
                    const item = {
                        name: file.name.split('.')[0],
                        file: file
                    }

                    mouthConfig.config.closedMouthBlob = await fileToBase64(file);
                    isClosedMouthUploaded.value = true;

                    ctx.emit('onChangeCustomMouth', {
                        type: 'mouth',
                        add: true,
                        closed: {
                            file: file,
                            name: item.name
                        }
                    })

                    document.getElementById('closedInput').value = ''
                }
            },
            removeClosedMouth() {
                isClosedMouthUploaded.value = false;
                mouthConfig.config.closedMouthBlob = null;

                ctx.emit('onChangeCustomMouth', {
                    type: 'mouth',
                    remove: true,
                    closed: null
                })
            },
            getImage(type, image, slider = undefined) {
                if (props.sticker.id) {
                    if (type === "mouth" && image && typeof image.file === 'string' && mouthFiles.includes(image.file)) {
                        return TTS_ASSETS_BASE + '/' + type + '/animated/' + mouthConfig.config.currentMouth.file;
                    } else if (type === "mouth" && image && typeof image.file === 'string' && !mouthFiles.includes(image.file)) {
                        return getHostedAssetPath() + '/' + image.file;
                    } else if (image && typeof image.file === 'object') {
                        return URL.createObjectURL(image.file)
                    }
                } else {
                    if (type === "mouth" && image && typeof image.file === 'string' && mouthFiles.includes(image.file)) {
                        return TTS_ASSETS_BASE + '/' + type + '/animated/' + image.file;
                    }
                    else if (type === "mouth" && image && typeof image.file === 'string' && !mouthFiles.includes(image.file)) {
                        return image.file
                    }
                    else if (image && typeof image.file === 'object') {
                        return URL.createObjectURL(image.file)
                    }
                }


                return null
            },
        }

        return {
            mouthImages,
            mouthInput,
            closedMouthInput,
            isMouthUploaded,
            isClosedMouthUploaded,
            mouthItem,
            mouthConfig,

            actions,

            icons: {
                uploadIcon: mdiCloudUploadOutline,
                arrowLeft: mdiChevronLeft,
                arrowRight: mdiChevronRight,
                upload: mdiUpload,
                trashIcon: mdiDelete
            },
        }
    }
}
</script>

<style scoped lang="scss">

.eye-area {
    position: absolute;
}

.controls {

    .eye-control,
    .mouth-control {
        width: 480px;
        min-height: 200px;
        background-color: var(--v-lightPurple-base);
        border: 2px solid var(--v-boldPurple-base);
        border-radius: 8px;
        margin-top: 5px;
        position: relative;

        .eye-images,
        .mouth-images {
            width: 65%;
        }

        .eye-uploader,
        .mouth-uploader {
            width: 35%;
        }

        .enable-switch {
            left: 10px;
            bottom: 5px;
        }

        .flip-switch {
            right: 37%;
            bottom: 5px;

            @media screen and (max-width: 995px) {
                right: 37%;
            }

            @media screen and (max-width: 599px) {
                right: 10px;
            }
        }
    }
}

.image-name {
    width: 80%;
    margin: 0 auto;
    overflow-wrap: anywhere;
    -webkit-overflow-wrap: anywhere;
    -o-overflow-wrap: anywhere;
    -moz-overflow-wrap: anywhere;
}

.current-eye,
.current-mouth {
    width: 180px;
    height: 90px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    margin-bottom: 3px;
}

.slide-btn {
    margin: 0 10px;
}

@media screen and (max-width: 995px) {

    .customizer,
    .sticker-customizer {
        width: 100%;
    }

    .controls {
        width: 100%;
        .eye-control,
        .mouth-control {
            width: 100%;
            min-height: 325px;
        }
    }
}

@media screen and (max-width: 600px) {
    .controls {
        .eye-control {
            min-height: 325px;
        }
        .mouth-control {
            min-height: 365px;
        }

        // .flip-switch {
        //     right: 10px;
        //     bottom: 5px;
        // }
    }

    .current-eye,
    .current-mouth {
        width: 120px;
        height: 90px;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center center;
        margin-bottom: 3px;
    }
}

@media screen and (min-width: 996px) and (max-width: 1263px) {
    .controls {
        margin-left: 10px;
    }
}

</style>