<template>
	<v-card>
		<v-snackbar v-model="isFileError" color="error" :timeout="2500" top>
			{{ fileErrorMessage }}
		</v-snackbar>
		<v-card-text class="pt-0 px-0">
			<div class="step-section">
				<h4 class="text-center textColor--text fs--20 fw--400">
					Configure the settings of your talking sticker
				</h4>

				<div class="d-flex flex-wrap flex-lg-nowrap mt-4">
					<div class="customizer">
						<div class="sticker-customizer">
							<h4 class="text-center textColor--text fs--18 fw--400 mb-4 mt-1">Sticker Preview</h4>

							<div
								class="placement my-3"
								id="gifImage"
								disabled="true"
								:style="{ backgroundImage: `url(${actions.getBaseImage()}` }">
								<!-- Eye Image  -->

								<div
									v-if="sticker.tts.isEyeEnabled && sticker.tts.eyeImage"
									:style="{
										position: 'absolute',
										width: '100%',
										height: '100%',
									}">
									<div
										:style="{
											position: 'absolute',
											backgroundImage: `url(${actions.getImage('eyes', sticker.tts.eyeImage)})`,
											backgroundSize: 'contain',
											backgroundPosition: 'center center',
											zIndex: 4,
											transform: sticker.tts.isEyeFlipped
												? `rotate(${sticker.tts.position.eyeAngle}deg) scaleX(-1)`
												: `rotate(${sticker.tts.position.eyeAngle}deg) scaleX(1)`,
											left: `${sticker.tts.bounds.eyeX}px`,
											top: `${sticker.tts.bounds.eyeY}px`,
											width: `${sticker.tts.bounds.eyeW}px`,
											height: `${sticker.tts.bounds.eyeH}px`,
										}"></div>
								</div>

								<!-- Mouth Image -->
								<div
									v-if="
										sticker.tts.isMouthEnabled && (sticker.tts.mouthImage || sticker.tts.mouthBlob)
									"
									style="position: absolute; width: 100%; height: 100%">
									<!-- Animated Mouth -->

									<div
										v-show="preview && audioFrequency > 0"
										:style="{
											position: 'absolute',
											backgroundImage: `url(${actions.getImage(
												'mouth',
												sticker.tts.mouthImage,
											)})`,
											backgroundSize: 'contain',
											backgroundPosition: 'center center',
											zIndex: 4,
											transform: sticker.tts.isMouthFlipped
												? `rotate(${sticker.tts.position.mouthAngle}deg) scaleX(-1)`
												: `rotate(${sticker.tts.position.mouthAngle}deg) scaleX(1)`,
											left: `${sticker.tts.bounds.mouthX}px`,
											top: `${sticker.tts.bounds.mouthY}px`,
											width: `${sticker.tts.bounds.mouthW}px`,
											height: `${sticker.tts.bounds.mouthH}px`,
										}"></div>

									<!-- Closed Mouth -->

									<div
										v-show="
											(!isMouthHidden && !preview) ||
											(!isMouthHidden && preview && audioFrequency <= 0)
										"
										:style="{
											position: 'absolute',
											backgroundImage: `url(${actions.getClosedMouth(sticker.tts.mouthImage)})`,
											backgroundSize: 'contain',
											backgroundPosition: 'center center',
											zIndex: 4,
											transform: sticker.tts.isMouthFlipped
												? `rotate(${sticker.tts.position.mouthAngle}deg) scaleX(-1)`
												: `rotate(${sticker.tts.position.mouthAngle}deg) scaleX(1)`,
											left: `${sticker.tts.bounds.mouthX}px`,
											top: `${sticker.tts.bounds.mouthY}px`,
											width: `${sticker.tts.bounds.mouthW}px`,
											height: `${sticker.tts.bounds.mouthH}px`,
										}"></div>
								</div>
							</div>
							<div class="preview-tts text-center mt-2 mb-3">
								<v-btn
									depressed
									color="secondary"
									class="mt-1"
									:disabled="alertPlaying || preview"
									@click="actions.playAudio">
									<v-icon>{{ icons.volume }}</v-icon>
									Preview TTS
								</v-btn>
							</div>
						</div>
					</div>
					<div class="controls ml-md-4 mt-1 py-2 px-4">
						<span class="fs--16 text-md fw--400">Voice</span>

						<!-- Not bonus slot for shared TTS voice. -->
						<template v-if="!sticker.tts.isBonusTtsShareSlot">
							<v-radio-group v-model="setting.voiceType" hide-details>
								<v-radio value="ai" color="pink" label="A.I Voice" class="mb-0" hide-details></v-radio>

								<div class="d-flex flex-wrap align-center mt-2 mb-2 ml-7">
									<v-autocomplete
										hide-details
										v-model="setting.voiceId"
										item-text="name"
										item-value="id"
										:items="aiVoices"
										:disabled="setting.voiceType !== VoiceType.AI"
										dense
										flat
										solo
										background-color="secondary"
										label="Voice"
										@change="actions.setStickerSku()">
										<template v-slot:selection="{ item }">
											<img :src="actions.getAiIcon(item)" />
											<span class="fs--14 white--text fw--500 ml-2"> {{ item.name }}</span>
										</template>
										<template v-slot:item="{ item }">
											<img :src="actions.getAiIcon(item)" />
											<span class="fs--14 white--text fw--500 ml-2">{{ item.name }}</span>
										</template>
									</v-autocomplete>
								</div>

								<v-radio value="standard" color="pink" label="Standard Voice:"></v-radio>

								<div class="d-flex flex-wrap align-center mb-3 ml-7">
									<v-autocomplete
										hide-details
										v-model="setting.language"
										item-text="name"
										item-value="code"
										:items="languageList"
										:disabled="setting.voiceType === VoiceType.AI"
										dense
										flat
										solo
										background-color="secondary"
										label="Select Language"
										style="max-width: 45%">
									</v-autocomplete>

									<v-autocomplete
										hide-details
										v-model="setting.voiceId"
										item-text="name"
										item-value="id"
										:items="standardVoices"
										:disabled="setting.voiceType === VoiceType.AI"
										dense
										flat
										solo
										background-color="secondary"
										label="Voice"
										@change="actions.setStickerSku()"
										class="ml-2"
										style="max-width: 45%" />
								</div>
							</v-radio-group>
						</template>

						<!-- Bonus slot for shared TTS voice. -->
						<template v-else>
							<div class="d-flex align-center mt-3">
								<!-- Set Icon Dynamically based on the Voice -->
								<img src="@/assets/icons/AIShared.png" class="mr-1" />
								<h4 class="fs--16 fw--500 text-center mb-3 mt-2">{{ bonusSharedVoice.name }}</h4>
							</div>
							<div class="mt-2 mb-3 d-flex align-center">
								<div class="bg-white circle pa-1 mr-5">
									<v-avatar size="30">
										<img
											:src="bonusProfilePic"
											@error="$event.target.src = `${actions.defaultProfilePic()}`" />
									</v-avatar>
								</div>
								<div class="full-width">
									<MusicPlayer
										v-if="!isInDataRangeChange"
										:audio="bonusAudioUrl"
										:id="'shared-voice-preview-' + new Date().getTime()" />
								</div>
							</div>
						</template>

						<div class="alert-tts mt-3">
							<span class="fs--16 text-md fw--400">TTS Alert Sound</span> <br />
							<span class="fs--12 offWhite--text">Play a custom audio alert before the TTS message</span>

							<div class="mt-3">
								<v-btn v-if="!alertFile" color="pink" depressed @click="actions.pickFile"
									>Upload Audio</v-btn
								>

								<template v-if="alertFile">
									<div class="d-flex align-center">
										<img src="@/assets/icons/check-circle.png" width="25" alt="Uploaded" />
										<v-btn
											class="mx-2"
											small
											icon
											:disabled="alertPlaying"
											@click="actions.playAlert(true)">
											<v-icon color="white" size="30">{{ icons.play }}</v-icon>
										</v-btn>

										<v-btn
											depressed
											small
											class="px-1"
											min-width="25"
											min-height="25"
											color="secondary"
											:disabled="alertPlaying"
											@click="actions.removeFile()">
											<v-icon color="white">{{ icons.trashIcon }}</v-icon>
										</v-btn>
									</div>
								</template>

								<input
									type="file"
									accept="audio/mpeg,audio/wav,audio/x-wav"
									class="d-none"
									@change="actions.onAudioPick"
									ref="fileInput" />
							</div>
						</div>

						<div class="closed-mouth d-flex align-center flex-wrap mt-3">
							<span class="fs--16 text-md fw--400 mr-3">Hide Closed Mouth Asset</span>
							<v-switch v-model="isMouthHidden" hide-details color="pink" class="ma-0"></v-switch>
						</div>
						<span class="fs--12 offWhite--text"
							>Enable this option if the closed mouth is already visible on your base sticker.</span
						>
					</div>
				</div>
			</div>
		</v-card-text>

		<div class="footer-section d-flex flex-wrap justify-center px-3 px-sm-0">
			<v-btn class="mt-3 my-sm-6 px-12 mr-2" depressed @click="actions.moveBack" outlined color="pink"
				>Back
			</v-btn>
			<v-btn class="mt-3 mb-3 mb-sm-0 my-sm-6 px-12" depressed @click="actions.moveNext" color="pink">Next</v-btn>
		</div>
	</v-card>
</template>

<script>
import { computed, ref, reactive, onMounted, onBeforeUnmount, watch } from '@vue/composition-api';
import store from '@/store';
import { createObjectUrlFromBlob } from '@/utils/sticker.js';
import { getEndpointHost, getCustomStickerAssetPath, getHostedAssetPath } from '@/utils/config';
import { VoiceType } from '@/utils/constants';
import { getUserId } from '@/utils/auth';
import { mdiCloudUploadOutline, mdiVolumeLow, mdiUpload, mdiDelete, mdiPlayCircle, mdiVolumeMute } from '@mdi/js';
import BitsDropdown from '@/views/shared/BitsDropdown.vue';
import MusicPlayer from '@/views/streamer/tts-voice-lab/MusicPlayer.vue';
import { getMessage } from '@/utils/messages';
import { getAudioPreviewUrl, elSupportedLangsNonEnglish, formatSupportedLangs } from '@/utils/tts-utils';
import { TTS_ASSETS_BASE, LANGUAGES, mouthPresets, eyePresets } from '@/utils/utils';

export default {
	components: {
		MusicPlayer,
		BitsDropdown,
	},
	props: {
		sticker: {
			type: Object,
			required: true,
		},
		stickerSlot: {
			type: Number,
		},
	},
	emits: ['onMove', 'alertAdded'],
	setup(props, ctx) {
		// https://extension-dev.streamstickers.com:8081/tts-co-host/431026547-0/ttsch
		const browserSourceLink = `https://${getEndpointHost()}/tts-co-host/${getUserId()}-${
			props.stickerSlot + 1
		}/ttsch`;
		const copied = ref(false);

		const alertFile = ref(null);
		const fileInput = ref(null);
		const alertPlaying = ref(false);

		const mouthFiles = mouthPresets.map(img => img.file);

		let audioPlayer = null;
		const audioFrequency = ref(0);

		const showTextError = ref(false);

		const languageList = ref(LANGUAGES);

		const supportedVoicesString = computed(() => {
			return formatSupportedLangs(elSupportedLangsNonEnglish());
		});

		const setting = reactive({
			voiceType:
				props.sticker.ttsVoice && props.sticker.ttsVoice.audioSource === 'AMAZON'
					? VoiceType.STANDARD
					: VoiceType.AI,
			voiceId: null,
			language: 'en',
			text: getMessage('tts-sticker-preview-copy'),
			volume: 50,
			stickiness: 50,
			stability: 30,
			bits: null,
			isAccentEnhancement: false,
			subOnly: false,
			isClosedMouthEnabled: true,
		});

		// A slider value is being changed, so do not recalc things like the audio preview URL.
		const isInDataRangeChange = ref(false);

		// When voice type changes, set to first voice.
		watch(
			() => setting.voiceType,
			() => {
				setting.voiceId = voices.value[0].id;
				actions.setStickerSku();
			},
		);

		// When language changes, set to first voice.
		watch(
			() => setting.language,
			() => {
				setting.voiceId = voices.value[0].id;
				actions.setStickerSku();
			},
		);

		// Compute voice list for AI or Standard.
		const voices = computed(() => {
			const voiceList = store.state.app.ttsVoices.filter(v => {
				if (setting.voiceType === VoiceType.AI) {
					return v.voiceType === VoiceType.AI;
				} else {
					return v.voiceType === VoiceType.STANDARD && v.languageCode.startsWith(setting.language + '-');
				}
			});

			return voiceList;
		});

		// Specific values for UI list items.
		const aiVoices = computed(() => {
			if (setting.voiceType === VoiceType.AI) {
				return voices.value;
			} else {
				return [];
			}
		});

		// Specific values for UI list items.
		const standardVoices = computed(() => {
			if (setting.voiceType === VoiceType.STANDARD) {
				return voices.value;
			} else {
				return [];
			}
		});

		const isMouthHidden = ref(false);

		onMounted(() => {
			const { volume, stickiness, sku } = { ...props.sticker };
			const data = { ...props.sticker.tts };

			setting.language = data.language || 'en';
			setting.volume = data.volume ? data.volume : props.sticker.id && volume ? volume : 70;
			setting.stickiness = data.stickiness ? data.stickiness : props.sticker.id && stickiness ? stickiness : 70;
			setting.stability = data.stability ? data.stability : 30;
			setting.bits = data.sku ? data.sku : props.sticker.id && sku ? sku : null;
			setting.subOnly = data.subOnly;
			isMouthHidden.value = !data.isClosedMouthEnabled;

			// setting.voiceType = props.sticker.ttsVoice && props.sticker.ttsVoice.audioSource === 'AMAZON' ? VoiceType.STANDARD : VoiceType.AI;

			if (data.voiceId) {
				setting.voiceId = data.voiceId;
			} else {
				setting.voiceId = voices.value[0].id;
			}

			setting.isAccentEnhancement = data.isAccentEnhancement;

			alertFile.value = data.alertSound;
		});

		onBeforeUnmount(() => {
			if (audioPlayer) {
				audioPlayer.pause();
				audioPlayer = null;
			}
		});

		const eyeImg = ref(null);

		const preview = ref(false);

		const isFileError = ref(false);
		const fileErrorMessage = ref(null);
		const imageInput = ref(null);

		const imageSource = computed(() => {
			if (props.sticker.stickerBlob) {
				return createObjectUrlFromBlob(props.sticker.stickerBlob);
			} else {
				return '';
			}
		});

		// For a bonus slot for voice shares, retrieve the voice from the store.
		const bonusSharedVoice = computed(() => {
			if (props.sticker.tts.isBonusTtsShareSlot && props.sticker.tts.voiceId) {
				return store.state.app.ttsVoices.find(v => v.id === props.sticker.tts.voiceId);
			}
			return null;
		});

		// Audio preview URL for a shared voice.
		const bonusAudioUrl = computed(() => {
			if (bonusSharedVoice.value) {
				return getAudioPreviewUrl(bonusSharedVoice.value.id, 'sticker-voice-preview');
			}
			return null;
		});

		// Profile pic of the streamer who shared the voice.
		const bonusProfilePic = computed(() => {
			if (bonusSharedVoice.value && bonusSharedVoice.value.sharedFrom) {
				return bonusSharedVoice.value.sharedFrom.profilePic;
			}
			return null;
		});

		const actions = {
			getAiIcon(item) {
				const name = item.name;
				if (name.includes('[A.I. Personal]')) {
					return require('@/assets/icons/AIPersonal.png');
				} else if (name.includes('[A.I. Shared]')) {
					return require('@/assets/icons/AIShared.png');
				} else {
					return require('@/assets/icons/AIGlobal.png');
				}
			},
			getClosedMouth(sticker) {
				// If CUstom Custom closed mouth is present and animated mouth is selected from the previous step than Show Preset mouth.
				if (
					props.sticker.tts.closedMouthBlob &&
					props.sticker.tts.closedMouthBlob &&
					typeof props.sticker.tts.closedMouthBlob === 'string' &&
					props.sticker.tts.closedMouthBlob.includes('custom_stickers') &&
					!mouthFiles.includes(props.sticker.tts.mouthImage.file)
				) {
					return getHostedAssetPath() + '/' + props.sticker.tts.closedMouthBlob;
				} else if (
					props.sticker.tts.closedMouthBlob &&
					props.sticker.tts.closedMouthBlob &&
					typeof props.sticker.tts.closedMouthBlob === 'string' &&
					mouthFiles.includes(props.sticker.tts.mouthImage.file) &&
					!props.sticker.tts.closedMouthBlob.includes('custom_stickers')
				) {
					// return props.sticker.tts.closedMouthBlob;
					return TTS_ASSETS_BASE + '/mouth' + '/closed/' + props.sticker.tts.mouthImage.file;
				} else if (
					props.sticker.tts.closedMouthBlob &&
					props.sticker.tts.closedMouthBlob &&
					typeof props.sticker.tts.closedMouthBlob === 'string' &&
					!props.sticker.tts.closedMouthBlob.includes('custom_stickers')
				) {
					return props.sticker.tts.closedMouthBlob;
				} else {
					if (typeof sticker.file === 'string' && sticker.file.includes('.')) {
						const image = sticker.file.split('.')[0];
						const ext = sticker.file.split('.')[1] === 'png' ? 'png' : 'gif';

						return TTS_ASSETS_BASE + '/mouth' + '/closed/' + image + '.' + ext;
					} else {
						return null;
					}
				}
			},
			getImage(type, image) {
				if (type === 'mouth' && image && typeof image.file === 'string') {
					if (image.file.includes('custom_stickers')) {
						return getHostedAssetPath() + '/' + image.file;
					}
					return TTS_ASSETS_BASE + '/' + type + '/animated/' + image.file;
				}
				if (type === 'mouth' && image && typeof image.file === 'object' && props.sticker.tts.mouthBlob) {
					return props.sticker.tts.mouthBlob;
				}

				if (type === 'eyes' && image && typeof image.file === 'string') {
					if (image.file.includes('custom_stickers')) {
						return getHostedAssetPath() + '/' + image.file;
					}
					return TTS_ASSETS_BASE + '/' + type + '/' + image.file;
				}

				if (type === 'eyes' && image && typeof image.file === 'object' && props.sticker.tts.eyeBlob) {
					return props.sticker.tts.eyeBlob;
				}

				return null;
			},
			getBaseImage() {
				if (props.sticker.stickerBlob) {
					return props.sticker.stickerBlob;
				} else if (props.sticker.tts.baseImage) {
					const baseImg = props.sticker.tts.baseImage.split('tts/')[1];
					const path = `${getCustomStickerAssetPath(getUserId())}`;
					return `${path}/tts/${baseImg}`;
				}

				return null;
			},

			setStickerSku() {
				const voice = store.state.app.ttsVoices.find(v => v.id === setting.voiceId);
				if (voice) {
					const bits = setting.bits;
					if (bits && typeof bits === 'string' && bits.includes('ticket_')) {
						const value = Number(bits.split('_')[1]);
						if (value < voice.minBits) {
							setting.bits = 'ticket_' + voice.minBits;
						}
					} else if (!bits || (bits && typeof bits === 'string' && bits === 'free')) {
						setting.bits = 'ticket_' + voice.minBits;
					}

					setting.voiceService = voice.audioSource;
					setting.ownership = voice.ownership;

					// Set Min Bits for CREATE Sticker
					if (!props.sticker.id) {
						if (voice.audioSource === 'AMAZON' && voice.minBits < 100) {
							setting.bits = 'ticket_100';
						}
						if (voice.audioSource !== 'AMAZON' && voice.minBits < 200) {
							setting.bits = 'ticket_200';
						}
					}
				}
			},

			removeFile() {
				alertFile.value = null;
				ctx.emit('alertAdded', null);
			},

			pickFile() {
				fileInput.value.click();
			},

			onAudioPick: function (e) {
				const file = e.target.files[0];
				if (file) {
					alertFile.value = file;
					ctx.emit('alertAdded', file);
				} else {
					alertFile.value = null;
					ctx.emit('alertAdded', null);
				}
				// onImagePicked(e, props.sticker, null, function (error) {
				// 	fileErrorMessage.value = error;
				// 	isFileError.value = true;
				// });
			},

			playAudio() {
				showTextError.value = false;
				if (!setting.text) {
					showTextError.value = true;
					return;
				}

				if (alertFile.value) {
					actions.playAlert();
				} else {
					actions.playTextAudio();
				}
			},

			playAlert(alertOnly = false) {
				const audioElement = document.createElement('audio');
				let url = null;
				if (typeof alertFile.value === 'object') {
					url = URL.createObjectURL(alertFile.value);
				}
				if (typeof alertFile.value === 'string') {
					url = getHostedAssetPath() + '/' + alertFile.value;
				}

				audioElement.src = url;

				alertPlaying.value = true;
				preview.value = true;

				audioElement.play();

				audioElement.addEventListener('ended', () => {
					if (!alertOnly) {
						actions.playTextAudio();
					} else {
						alertPlaying.value = false;
						preview.value = false;
					}
				});
			},

			playTextAudio() {
				// create an AudioContext object
				const audioCtx = new AudioContext();

				// load the audio file into a buffer
				const audioFile = getAudioPreviewUrl(setting.voiceId, 'sticker-voice-preview');

				const audioElement = document.createElement('audio');
				audioElement.id = 'stickerAudio';

				audioElement.src = audioFile;
				audioElement.crossOrigin = 'anonymous';

				audioPlayer = audioElement;

				const source = audioCtx.createMediaElementSource(audioElement);

				const analyser = audioCtx.createAnalyser();
				analyser.fftSize = 2048;

				source.connect(analyser);

				analyser.connect(audioCtx.destination);

				const frequencyData = new Uint8Array(analyser.frequencyBinCount);

				audioElement.volume = Number(setting.volume) / 100;

				preview.value = true;

				audioElement.play();

				let animationId = null;

				function renderFrame() {
					if (audioElement.ended) {
						cancelAnimationFrame(animationId);
						audioPlayer = null;
						audioFrequency.value = 0;
						return;
					}

					animationId = requestAnimationFrame(renderFrame);
					analyser.getByteFrequencyData(frequencyData);
					// process frequency data
					const sum = frequencyData.reduce((a, b) => a + b, 0);
					const avg = sum / frequencyData.length;

					audioFrequency.value = avg;
				}

				renderFrame();

				audioElement.addEventListener('ended', () => {
					audioFrequency.value = 0;
					preview.value = false;
					alertPlaying.value = false;
					audioPlayer = null;
					cancelAnimationFrame(animationId);
				});
			},

			moveNext: function () {
				const data = {
					language: setting.language,
					bits: setting.bits,
					volume: setting.volume,
					stickiness: setting.stickiness,
					stability: setting.stability,
					isClosedMouthEnabled: !isMouthHidden.value,
					text: setting.text,
					voiceService: setting.voiceService,
					voiceId: setting.voiceId,
					voiceType: setting.voiceType,
					ownership: setting.ownership,
					isAccentEnhancement: setting.isAccentEnhancement,
				};

				ctx.emit('onConfigureSettings', data);
				// ctx.emit('onMove', 4);
			},
			moveBack: function () {
				ctx.emit('onMove', 2);
			},

			// A slider has started to change. We check this so we don't recalc the audio preview URL during a change.
			onRangeDataStart: function () {
				isInDataRangeChange.value = true;
			},
			onRangeDataEnd: function () {
				isInDataRangeChange.value = false;
			},

			defaultProfilePic: function () {
				return require('@/assets/images/misc/profile.png');
			},
		};

		return {
			languageList,
			supportedVoicesString,
			browserSourceLink,
			copied,
			showTextError,

			VoiceType,
			aiVoices,
			standardVoices,

			audioFrequency,
			setting,
			preview,
			eyeImg,
			isMouthHidden,
			// baseStyle,

			actions,
			imageSource,
			imageInput,
			isFileError,
			fileErrorMessage,

			alertFile,
			fileInput,
			alertPlaying,

			bonusSharedVoice,
			bonusAudioUrl,
			bonusProfilePic,
			isInDataRangeChange,

			icons: {
				volume: mdiVolumeLow,
				uploadIcon: mdiCloudUploadOutline,
				upload: mdiUpload,
				play: mdiPlayCircle,
				trashIcon: mdiDelete,
				mute: mdiVolumeMute,
			},
		};
	},

	methods: {},
};
</script>

<style lang="scss" scoped>
.sticker-customizer {
	background-color: var(--v-lightPurple-base);
	border: 2px solid var(--v-boldPurple-base);
	border-radius: 8px;
	width: 300px;
	margin-top: 5px;
}

#canvas,
.placement,
#placement {
	position: relative;
	height: 300px;
	width: 250px;
	max-width: 100%;
	/* background: url('../../../../assets/tts/base/base-1.png'); */
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center center;
	border-radius: 4px;
	margin: 0 auto;
	backface-visibility: hidden;
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	-ms-backface-visibility: hidden;
	background-attachment: fixed;
	pointer-events: none;
	transform: translate3d(0, 0, 0);
	transition: background-image 1s ease-in-out;
}

.controls {
	width: 480px;
	background-color: var(--v-lightPurple-base);
	border: 2px solid var(--v-boldPurple-base);
	border-radius: 8px;
}

.drr::before,
.drr.active::before {
	border: none !important;
	outline: none !important;
}

.draggable,
.vdr {
	border: none !important;
}

.current-eye,
.current-mouth {
	width: 170px;
	height: 100px;
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center center;
}

.uploader {
	width: 100%;
}

@media screen and (max-width: 995px) {
	.customizer,
	.sticker-customizer {
		width: 100%;
	}

	.controls {
		width: 100%;

		.eye-control,
		.mouth-control {
			width: 100%;
			min-height: 325px;
		}
	}
}

@media screen and (min-width: 996px) and (max-width: 1263px) {
	.controls {
		margin-left: 10px;
	}
}
</style>
