<template>
	<v-card min-height="457" height="100%" class="card-border">
		<v-card-text v-if="sticker">
			<!-- Locked Sticker -->
			<template v-if="!sticker.isSlotConfigured && !sticker.isSlotUnlocked">
				<v-row justify="center" align="center" class="sticker-header mb-0 mb-lg-3">
					<template v-if="sticker.isTwitterSlot">
						<img class="twitter-icon" src="@/assets/images/Twitter.png" alt="Twitter" />
					</template>
					<template v-if="sticker.isDiscordSlot">
						<img class="discord-icon" src="@/assets/images/discord-icon.png" alt="Twitter" />
					</template>
					<v-col cols="9" class="text-center mb-3">
						<h4 class="fs--18 fw--300">{{ sticker.name }}</h4>
					</v-col>
				</v-row>
				<div class="unlock-area d-flex align-center justify-center">
					<v-btn
						@click="actions.onLockedClick(sticker)"
						fab
						color="lightpurple"
						x-large
						width="110"
						height="110">
						<v-icon size="34" color="blue">
							{{ icons.lock }}
						</v-icon>
					</v-btn>
				</div>
				<v-btn @click="actions.onLockedClick(sticker)" depressed color="" class="py-7 text-normal lock-btn">
					<div v-if="isBitsEnabled">
						<span class="grey--text">Unlocks at</span>
						<br />
						<div class="d-flex align-center justify-center">
							<img src="@/assets/images/bits.png" />
							<span class="grey--text pl-2">{{ Number(sticker.slotUnlocksAt).toLocaleString() }}</span>
						</div>
					</div>
					<div v-else>
						<span v-if="!sticker.isTwitterSlot && !sticker.isDiscordSlot" class="grey--text">
							Requires Twitch Affiliate level
						</span>
						<span v-if="sticker.isTwitterSlot" class="offWhite--text">Follow us on Twitter</span>
						<span v-if="sticker.isDiscordSlot" class="offWhite--text">Join our Discord Community</span>
					</div>
				</v-btn>
			</template>
		</v-card-text>

		<GenericPopup v-if="errorDialog.show" :modal="errorDialog.show" @close="errorDialog.show = false">
			<template #primaryHeader>{{ errorDialog.primaryHeader }}</template>
			<template>
				<p class="fs--13 fw--400 fieldFore--text">
					{{ errorDialog.copy }}
				</p>
			</template>
		</GenericPopup>
	</v-card>
</template>

<script>
import { reactive } from '@vue/composition-api';
import { StickerType } from '@/utils/constants';
import { isBitsEnabled } from '@/utils/auth';
import GenericPopup from '@/components/GenericPopup';
import { mdiLock } from '@mdi/js';

export default {
	props: {
		sticker: {
			type: Object,
			default: null,
		},
		stickerType: {
			type: String,
			required: true,
		},
		stickerSlot: {
			type: Number,
		},
		isExtensionOnline: {
			type: Boolean,
			default: false,
		},
	},
	components: {
		GenericPopup,
	},
	emits: ['onNoBitsTryUpload', 'onOpenTwitterFollow', 'onOpenDiscordFollow'],
	setup(props, ctx) {
		const errorDialog = reactive({
			show: false,
			primaryHeader: 'Error',
			copy: 'An error occurred.',
		});

		const actions = {
			onLockedClick: function (sticker) {
				if (sticker.isTwitterSlot) {
					ctx.emit('onOpenTwitterFollow', true);
					return;
				}
				if (sticker.isDiscordSlot) {
					ctx.emit('onOpenDiscordFollow', true);
					return;
				} else if (!isBitsEnabled.value) {
					ctx.emit('onNoBitsTryUpload');
				}
			},
		};

		return {
			errorDialog,
			isBitsEnabled,
			actions,
			isCustom: props.stickerType == StickerType.CUSTOM,
			isReward: props.stickerType == StickerType.REWARD,
			isGlobal: props.stickerType == StickerType.GLOBAL,
			icons: {
				lock: mdiLock,
			},
		};
	},
};
</script>

<style lang="scss" scoped>
.card-border {
	border: 1px solid transparent;
}

.card-border:hover {
	border: 1px solid #4a93d9;
}

.upload-area {
	height: 315px;
}

.upload-btn,
.lock-btn {
	position: absolute;
	bottom: 20px;
	left: 5%;
	width: 90%;
}

.unlock-area {
	height: 298px;
}

.sticker-header {
	position: relative;
	height: 70px;
	z-index: 0;
}

.global-sticker-header {
	position: relative;
	height: 60px;
	z-index: 0;
}

.sticker-image {
	position: relative;
	z-index: 0;
}

.disabled-image {
	position: absolute;
	width: 100%;
	max-width: 350px;
	height: 120px;
	top: 0;
	left: 0;
	background: var(--v-cardBg-base) !important;
	opacity: 0.8;
	z-index: 9;
}

.name-edit-icon {
	position: absolute;
	right: 45%;
	top: 0px;
	transition: 0.3s ease-in-out;
}

.edit-icon {
	position: absolute;
	top: -10px;
	right: 20%;
	z-index: 2;
	transition: 0.2s ease-in-out;
}

.sticker-header .drag-icon {
	position: absolute;
	top: 8px;
	right: 0px;
}

.bits-btn {
	position: relative;
}

.bits-dropdown {
	height: 200px;
	overflow-y: scroll;
}

.twitter-icon,
.discord-icon {
	position: absolute;
	left: 5px;
	top: 20px;
}

@media screen and (min-width: 1264px) and (max-width: 1340px) {
	.text-xs {
		font-size: 11px !important;
	}
	.text-md {
		font-size: 13px !important;
	}
}
</style>
