<template>
	<v-card>
		<v-card-text class="pt-0">
			<div class="step-section">
				<h4 class="text-center textColor--text fs--17 fw--500">Ready to use!</h4>

				<!-- <div v-if="!sticker || !sticker.image || !sticker.sound" class="upload-area text-center mt-4">
                    <h2>Image or audio not found.</h2>
                </div> -->
				<div v-if="sticker && sticker.stickerBlob" class="upload-area text-center mt-4">
					<h4 class="fs--34 fw--500 textColor--text mb-5">{{ sticker.name }}</h4>
					<div class="mb-5 text-center">
						<img :src="imageSource" width="150" alt="Sticker Image" />
					</div>

					<v-btn
						v-if="sticker.audioBlob"
						class="mb-4"
						@click="actions.toggleAudio"
						color="secondary"
						:width="$vuetify.breakpoint.xsOnly ? 'auto' : '220'"
						min-height="48"
						depressed>
						<v-icon size="24" class="mr-1">{{ icons.volumeIcon }}</v-icon>
						{{ isPlaying ? 'Stop' : 'Preview' }} Audio
					</v-btn>
				</div>
				<p class="fs--12 mt-5 mb-5 fw--500 shinyPurple--text text-center">
					If you are happy with your sticker, click the submit button below.
				</p>
			</div>
		</v-card-text>
		<div class="footer-section d-flex flex-wrap justify-center px-3 px-sm-0">
			<v-btn class="mt-3 my-sm-6 px-12 mr-2" depressed @click="actions.moveBack" outlined color="pink"
				>Back</v-btn
			>
			<v-btn class="mt-3 mb-3 mb-sm-0 my-sm-6 px-12" depressed @click="actions.submit" color="pink">Submit</v-btn>
		</div>
	</v-card>
</template>

<script>
import { computed } from '@vue/composition-api';
import { createObjectUrlFromBlob } from '../../../../utils/sticker.js';
import { mdiVolumeHigh } from '@mdi/js';
export default {
	props: {
		sticker: {
			type: Object,
			default: null,
		},
		isPlaying: {
			type: Boolean,
			default: false,
		},
	},
	emits: ['onSubmit', 'onMove', 'onToggleAudio'],
	setup(props, ctx) {
		const imageSource = computed(() => {
			if (props.sticker.stickerBlob) {
				return createObjectUrlFromBlob(props.sticker.stickerBlob);
			} else {
				return '';
			}
		});

		const actions = {
			submit: function () {
				ctx.emit('onSubmit');
			},
			moveBack: function () {
				ctx.emit('onMove', 3);
			},
			toggleAudio: function () {
				ctx.emit('onToggleAudio');
			},
		};

		return {
			actions,
			imageSource,
			icons: {
				volumeIcon: mdiVolumeHigh,
			},
		};
	},
};
</script>
