const premades = [
	{
		id: '5b28ab36-d735-4c9f-aab5-70ca33a8aeb3',
		uuid: '5b28ab36-d735-4c9f-aab5-70ca33a8aeb3',
		key: 'e12e6e49-7529-4175-864c-b43c0ca83c1f',
		sortOrder: 5,
		stickerType: 'TTS',
		enabled: true,
		isReward: false,
		isBanned: false,
		subOnly: false,
		sku: 'ticket_300',
		stickiness: 43,
		volume: 80,
		owner: '431026547',
		name: 'Test 1',
		size: '0',
		isCustom: true,
		validated: false,
		staticView: '/na',
		iconView: '/na',
		sound: '/na',
		timestamp: null,
		isSlotUnlocked: null,
		slotUnlocksAt: 0,
		stickerBlob: null,
		audioBlob: null,
		rewardSlotThreshold: null,
		tts: {
			displayMode: 'STICKER',
			stickerSlot: 0,
			baseImage: 'custom_stickers/431026547/tts/1694089744745.png',
			eyeImage: '0.0.1/tts/eyes/1.gif',
			eyeBlob: null,
			mouthImage: '0.0.1/tts/mouth/animated/1.gif',
			mouthBlob: null,
			closedMouthImage: '0.0.1/tts/mouth/closed/1.gif',
			closedMouthBlob: null,
			alertSound: null,
			alertSoundBlob: null,
			alertDurationInSeconds: 0,
			isEyeEnabled: true,
			isEyeFlipped: false,
			isMouthEnabled: true,
			isMouthFlipped: false,
			isClosedMouthEnabled: true,
			language: 'en',
			voiceId:
				'7b0a202022696422203a20224a79536d634177547a4f5142363662756a69684a222c0a202022617564696f536f7572636522203a2022454c4556454e5f4c414253220a7d',
			isAccentEnhancement: false,
			stability: 50,
			stickiness: 43,
			volume: 80,
			sku: 'ticket_300',
			subOnly: false,
			position: {
				eyeX: 120,
				eyeY: 95,
				mouthX: 120,
				mouthY: 215,
				eyeW: 100,
				eyeH: 50,
				mouthW: 70,
				mouthH: 50,
				eyeAngle: 0,
				mouthAngle: 0,
			},
			bounds: {
				eyeX: 70,
				eyeY: 70,
				mouthX: 85,
				mouthY: 190,
				eyeW: 100,
				eyeH: 50,
				mouthW: 70,
				mouthH: 50,
				eyeAngle: 0,
				mouthAngle: 0,
			},
		},
		ttsVoice: {
			id: '7b0a202022696422203a20224a79536d634177547a4f5142363662756a69684a222c0a202022617564696f536f7572636522203a2022454c4556454e5f4c414253220a7d',
			gender: null,
			languageCode: 'custom_ai-EL',
			languageName: 'English',
			name: 'Ninu',
			audioSource: 'ELEVEN_LABS',
			ownership: 'SHARED_TO',
			modelType: 'eleven_english_v2',
			minBits: 100,
			shares: [],
			sharedFrom: {
				id: '162742316',
				displayName: 'Ninu_Chan',
				login: 'ninu_chan',
				profilePic:
					'https://static-cdn.jtvnw.net/jtv_user_pictures/a490bfa9-8c49-410f-9ddd-bcedd7723286-profile_image-300x300.png',
				name: 'Ninu_Chan',
			},
		},
		isSlotConfigured: true,
	},
	{
		id: 'ae770b80-f117-4fc1-84af-5a7b62df53a9',
		uuid: 'ae770b80-f117-4fc1-84af-5a7b62df53a9',
		key: 'c74b4198-63ee-4a87-b6d0-f091e18d8b8d',
		sortOrder: 6,
		stickerType: 'TTS',
		enabled: true,
		isReward: false,
		isBanned: false,
		subOnly: false,
		sku: 'ticket_500',
		stickiness: 43,
		volume: 80,
		owner: '431026547',
		name: 'Test 2',
		size: '0',
		isCustom: true,
		validated: false,
		staticView: '/na',
		iconView: '/na',
		sound: '/na',
		timestamp: null,
		isSlotUnlocked: null,
		slotUnlocksAt: 0,
		stickerBlob: null,
		audioBlob: null,
		rewardSlotThreshold: null,
		tts: {
			displayMode: 'STICKER',
			stickerSlot: 1,
			baseImage: 'custom_stickers/431026547/tts/1694090018209.png',
			eyeImage: '0.0.1/tts/eyes/2.gif',
			eyeBlob: null,
			mouthImage: '0.0.1/tts/mouth/animated/1.gif',
			mouthBlob: null,
			closedMouthImage: '0.0.1/tts/mouth/closed/1.gif',
			closedMouthBlob: null,
			alertSound: null,
			alertSoundBlob: null,
			alertDurationInSeconds: 0,
			isEyeEnabled: true,
			isEyeFlipped: false,
			isMouthEnabled: true,
			isMouthFlipped: false,
			isClosedMouthEnabled: true,
			language: 'en',
			voiceId:
				'7b0a202022696422203a2022704e496e7a366f62706744514763466d614a6742222c0a202022617564696f536f7572636522203a2022454c4556454e5f4c414253220a7d',
			isAccentEnhancement: false,
			stability: 50,
			stickiness: 43,
			volume: 80,
			sku: 'ticket_500',
			subOnly: false,
			position: {
				eyeX: 120,
				eyeY: 95,
				mouthX: 120,
				mouthY: 215,
				eyeW: 100,
				eyeH: 50,
				mouthW: 70,
				mouthH: 50,
				eyeAngle: 0,
				mouthAngle: 0,
			},
			bounds: {
				eyeX: 70,
				eyeY: 70,
				mouthX: 85,
				mouthY: 190,
				eyeW: 100,
				eyeH: 50,
				mouthW: 70,
				mouthH: 50,
				eyeAngle: 0,
				mouthAngle: 0,
			},
		},
		ttsVoice: {
			id: '7b0a202022696422203a2022704e496e7a366f62706744514763466d614a6742222c0a202022617564696f536f7572636522203a2022454c4556454e5f4c414253220a7d',
			gender: null,
			languageCode: 'global_ai-EL',
			languageName: 'English',
			name: 'Adam',
			audioSource: 'ELEVEN_LABS',
			ownership: 'GLOBAL',
			modelType: null,
			minBits: 100,
			shares: [],
			sharedFrom: null,
		},
		isSlotConfigured: true,
	},
];

export default premades;
