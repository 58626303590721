<template>
	<div class="sticker-tips">
		<!-- Custom and global stickers. -->
		<template v-if="isCustom || isGlobal">
			<v-card class="mt-3">
				<v-card-text class="py-0">
					<div class="d-flex flex-wrap align-center justify-space-between">
						<div class="tip-slider">
							<v-carousel class="mt-2 mt-md-1 mt-lg-0" height="auto" :show-arrows="false" hide-delimiters>
								<v-carousel-item
									v-if="!isGlobal && !isGlobalTTS"
									style="margin-top: 20px; margin-bottom: 20px">
									<span>
										Tip: You can reorder stickers by dragging tiles with the
										<v-icon size="26" color="grey">
											{{ icons.drag }}
										</v-icon>
										icon.
									</span>
								</v-carousel-item>
								<!-- <v-carousel-item>
								<span
									>Tip: You can render stickers by dragging tiles with the
									<v-icon size="26" color="grey">
										{{ icons.drag }}
									</v-icon>
									icon.</span
								>
							</v-carousel-item> -->
							</v-carousel>
						</div>
						<div class="d-flex align-center justify-end" v-if="!isReward">
							<span class="fs--16 fw--500 pr-2">Only Show Enabled Stickers</span>
							<v-switch v-model="hideDisabled" color="pink" @change="actions.updateStatus()"></v-switch>
						</div>
					</div>
				</v-card-text>
			</v-card>
		</template>
		<!-- Custom TTS. -->
		<template v-if="isTTS">
			<v-card class="mt-3">
				<v-card-text class="py-0">
					<v-row no-gutters align-md="center">
						<v-col cols="12" sm="12" md="9" xl="10" class="pr-sm-2">
							<div class="d-flex flex-wrap align-center justify-space-between">
								<div class="tip-slider" style="width: 100%">
									<v-carousel
										v-model="slideItem"
										cycle
										interval="10000"
										class="mt-0 mt-sm-0 mt-lg-0"
										:height="$vuetify.breakpoint.xsOnly ? 95 : 65"
										:show-arrows="true"
										hide-delimiters>
										<v-carousel-item
											class="slide-item"
											reverse-transition="fade-transition"
											transition="fade-transition">
											<div class="item-content">
												<span>
													Tip: Your main
													<span
														@click="actions.moveTo('/settings?tab=setup')"
														class="pink--text text-underline cursor-pointer">
														Browser Media Source
													</span>
													must also be active when using TTS Stickers (even for Co-Host
													Stickers).
												</span>
											</div>
										</v-carousel-item>
										<v-carousel-item
											class="slide-item"
											reverse-transition="fade-transition"
											transition="fade-transition">
											<div class="item-content">
												<span>
													Tip: You can edit your TTS Moderation preferences in the TTS
													<span
														@click="actions.moveTo('/settings?tab=tts')"
														class="pink--text text-underline cursor-pointer">
														Settings
													</span>
													section.
												</span>
											</div>
										</v-carousel-item>
										<v-carousel-item
											class="slide-item"
											reverse-transition="fade-transition"
											transition="fade-transition">
											<div class="item-content">
												<!-- Tip: Adding a custom Audio Alert to your TTS stickers can help viewers know when  -->
												<span>
													Tip: Adding a custom Audio Alert to your TTS stickers can help
													viewers know when a TTS message is about to begin.
												</span>
											</div>
										</v-carousel-item>
										<v-carousel-item
											class="slide-item"
											reverse-transition="fade-transition"
											transition="fade-transition">
											<div class="item-content">
												<span>
													Tip: Need help setting up your TTS Stickers? Join our
													<a
														class="pink--text text-underline"
														href=" https://discord.com/invite/2ePbGrc"
														target="_blank">
														Discord
													</a>
													for assistance.
												</span>
											</div>
										</v-carousel-item>
									</v-carousel>
								</div>
							</div>
						</v-col>
						<v-col
							cols="12"
							sm="4"
							offset-sm="8"
							md="3"
							offset-md="0"
							xl="2"
							class="text-right pt-4 pt-md-0 pb-4 pb-md-0 pl-sm-2">
							<v-btn
								color="pink"
								class="px-sm-2"
								:block="$vuetify.breakpoint.smAndUp"
								depressed
								@click="actions.moveTo('/settings?tab=tts')">
								View TTS Settings
							</v-btn>
						</v-col>
					</v-row>
				</v-card-text>
			</v-card>
		</template>
		<!-- Global TTS. -->
		<template v-if="isGlobalTTS">
			<v-card class="mt-3">
				<v-card-text class="py-0">
					<v-row no-gutters align-md="center">
						<v-col cols="12" sm="12" md="9" xl="10" class="pr-sm-2">
							<div class="d-flex flex-wrap align-center justify-space-between">
								<div class="tip-slider" style="width: 100%">
									<v-carousel
										v-model="slideItem"
										cycle
										interval="10000"
										class="mt-0 mt-sm-0 mt-lg-0"
										:height="$vuetify.breakpoint.xsOnly ? 95 : 65"
										:show-arrows="true"
										hide-delimiters>
										<v-carousel-item
											class="slide-item"
											reverse-transition="fade-transition"
											transition="fade-transition">
											<div class="item-content">
												<span>
													Tip: You can edit your TTS Moderation preferences in the TTS
													<span
														@click="actions.moveTo('/settings?tab=tts')"
														class="pink--text text-underline cursor-pointer">
														Settings
													</span>
													section.
												</span>
											</div>
										</v-carousel-item>
										<v-carousel-item
											class="slide-item"
											reverse-transition="fade-transition"
											transition="fade-transition">
											<div class="item-content">
												<span>
													Tip: You can adjust the on-screen size of your Global TTS stickers
													in the TTS
													<span
														@click="actions.moveTo('/settings?tab=tts')"
														class="pink--text text-underline cursor-pointer">
														Settings
													</span>
													section.
												</span>
											</div>
										</v-carousel-item>
									</v-carousel>
								</div>
							</div>
						</v-col>
						<v-col
							cols="12"
							sm="4"
							offset-sm="8"
							md="3"
							offset-md="0"
							xl="2"
							class="text-right pt-4 pt-md-0 pb-4 pb-md-0 pl-sm-2">
							<v-btn
								color="pink"
								class="px-sm-2"
								:block="$vuetify.breakpoint.smAndUp"
								depressed
								@click="actions.moveTo('/settings?tab=tts')">
								View TTS Settings
							</v-btn>
						</v-col>
					</v-row>
				</v-card-text>
			</v-card>
		</template>
	</div>
</template>

<script>
import { ref } from '@vue/composition-api';
import { StickerType } from '../../../utils/constants.js';
import { mdiDrag } from '@mdi/js';
import router from '@/router';

export default {
	props: {
		stickerType: {
			type: String,
			required: true,
		},
	},

	setup(props, ctx) {
		const hideDisabled = ref(false);
		const slideItem = ref(0);

		const actions = {
			moveTo(to) {
				router.push(to);
			},
			updateStatus() {
				ctx.emit('updateStatus', hideDisabled.value);
			},
		};

		return {
			hideDisabled,

			slideItem,
			actions,

			StickerType,
			isCustom: props.stickerType == StickerType.CUSTOM,
			isReward: props.stickerType == StickerType.REWARD,
			isGlobal: props.stickerType == StickerType.GLOBAL,
			isTTS: props.stickerType == StickerType.TTS,
			isGlobalTTS: props.stickerType == StickerType.GLOBAL_AI_TTS,

			icons: {
				drag: mdiDrag,
			},
		};
	},
};
</script>

<style scoped>
/* .tip-slider {
    width: 90% !important;
    margin: 0 auto;
} */
.slide-item {
	width: 100% !important;
	min-height: 50px !important;
	padding: 0px 50px !important;
}

.item-content {
	min-height: 65px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	justify-items: center;
}

.v-window__prev,
.v-window__next {
	margin: 0 !important;
	display: flex;
	flex-direction: column;
	justify-content: center;
	justify-items: center;
}

@media screen and (max-width: 600px) {
	.slide-item {
		min-height: 75px !important;
	}
	.item-content {
		min-height: 100px;
	}
}
</style>
