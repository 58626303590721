<template>
	<v-card>
		<v-card-text class="pt-0">
			<div class="step-section">
				<h4 class="text-center textColor--text fs--17 fw--500">Add Sticker Name and Search Terms:</h4>

				<div class="upload-area text-center mt-4">
					<v-row no-gutters>
						<v-col cols="12" md="4" class="pl-md-2">
							<div
								class="placement my-3"
								:style="{ 'background-image': `url(${actions.getBaseImage(sticker)})` }">
								<!-- Eye Image -->
		
								<div
									v-if="sticker.tts.isEyeEnabled && sticker.tts.eyeImage"
									style="position: absolute; width: 100%; height: 100%">
									<div
										:style="{
											position: 'absolute',
											backgroundImage: `url(${actions.getImage('eyes', sticker.tts.eyeImage)})`,
											backgroundSize: 'contain',
											backgroundPosition: 'center center',
											zIndex: 4,
											transform: sticker.tts.isEyeFlipped
												? `rotate(${sticker.tts.position.eyeAngle}deg) scaleX(-1)`
												: `rotate(${sticker.tts.position.eyeAngle}deg) scaleX(1)`,
											left: `${sticker.tts.bounds.eyeX}px`,
											top: `${sticker.tts.bounds.eyeY}px`,
											width: `${sticker.tts.bounds.eyeW}px`,
											height: `${sticker.tts.bounds.eyeH}px`,
										}"></div>
								</div>
		
								<!-- <drr v-if="sticker.isEye && sticker.eyeImage" :parent="true" :hasActiveContent="false" :x="sticker.position.eyeX" :y="sticker.position.eyeY" :w="sticker.position.eyeW" :h="sticker.position.eyeH" :angle="sticker.position.eyeAngle" :draggable="false" :resizable="false" :rotatable="false"
										:aspect-ratio="true">
										<v-img width="100%" height="100%" :src="actions.getImage('eyes', sticker.eyeImage)"
											alt="Eyes Image" contain></v-img>
									</drr> -->
		
								<!-- Mouth Image -->
		
								<div
									v-if="sticker.tts.isMouthEnabled && sticker.tts.mouthImage"
									:style="{
										position: 'absolute',
										backgroundImage: `url(${actions.getImage('mouth', sticker.tts.mouthImage)})`,
										backgroundSize: 'contain',
										backgroundPosition: 'center center',
										zIndex: 4,
										transform: sticker.tts.isMouthFlipped
											? `rotate(${sticker.tts.position.mouthAngle}deg) scaleX(-1)`
											: `rotate(${sticker.tts.position.mouthAngle}deg) scaleX(1)`,
										left: `${sticker.tts.bounds.mouthX}px`,
										top: `${sticker.tts.bounds.mouthY}px`,
										width: `${sticker.tts.bounds.mouthW}px`,
										height: `${sticker.tts.bounds.mouthH}px`,
									}"></div>
		
								<!-- <drr v-if="sticker.isMouth && sticker.mouthImage" :hasActiveContent="false" :parent="true" :x="sticker.position.mouthX" :y="sticker.position.mouthY" :w="sticker.position.mouthW" :h="sticker.position.mouthH" :angle="sticker.position.mouthAngle" :draggable="false" :resizable="false" :rotatable="false"
										:aspect-ratio="true">
										<v-img width="100%" height="100%" :src="actions.getImage('mouth', sticker.mouthImage)"
											alt="Eyes Image" contain></v-img>
									</drr> -->
								<!-- <vue-draggable-resizable v-if="sticker.isEye && sticker.eyeImage" :parent="true"
										:x="sticker.position.eyeX" :y="sticker.position.eyeY" :w="sticker.position.eyeW"
										:h="sticker.position.eyeH" :draggable="false" :resizable="false"
										:lock-aspect-ratio="true">
										<v-img width="100%" height="100%" :src="actions.getImage('eyes', sticker.eyeImage)"
											alt="Eyes Image" contain></v-img>
									</vue-draggable-resizable>
		
									<vue-draggable-resizable v-if="sticker.isMouth && sticker.mouthImage" :parent="true"
										:x="sticker.position.mouthX" :y="sticker.position.mouthY" :w="sticker.position.mouthW"
										:h="sticker.position.mouthH" :draggable="false" :resizable="false"
										:lock-aspect-ratio="true">
										<v-img width="100%" height="100%" :src="actions.getImage('mouth', sticker.mouthImage)"
											alt="Mouth Image" contain></v-img>
									</vue-draggable-resizable> -->
							</div>
						</v-col>
						<v-col cols="12" md="8" class="px-2 text-left">
							<span class="white--text fs--16 fw--500">Sticker Name</span>
							<v-text-field
								v-model="sticker.name"
								background-color="purple"
								maxlength="25"
								placeholder="Enter Sticker Name"
								class="mt-2 mb-3"
								:class="error && 'name-error mb-0'"
								@input="error = false"
								solo
								dense
								hide-details
							></v-text-field>

							<p v-if="error" class="red--text">Sticker name is required.</p>

							<span class="white--text fs--16 fw--500">Sticker Search Terms</span> <br>
							<span class="fs--12 fw--500 fw--500 shinyPurple--text">Help your users find this sticker with descriptive terms (optional)</span>
							
							<v-row class="mb-0 mt-1">
								<v-col cols="12" sm="8" md="8" class="py-1">
									<v-text-field
										v-model="tag"
										background-color="purple"
										maxlength="15"
										placeholder="Enter Search Term"
										class="mt-2"
										solo
										dense
										hide-details
										@keyup.enter="actions.addTag"
									></v-text-field>
								</v-col>
								<v-col cols="12" sm="4" md="4" class="py-1">
									<v-btn color="pink" class="mt-0 mt-md-2" block depressed @click="actions.addTag">
									<v-icon class="mr-1" size="20">{{ icons.plus }}</v-icon> Add</v-btn>
								</v-col>
							</v-row>

							<template v-if="tagList.length">
								<div class="purple mt-3 pt-3 pb-2 pl-3 pr-1">
									<div class="tags-section">
										<v-chip v-for="(item, index) in tagList" :key="index" color="info" small class="mr-1 mb-2 pl-3 pr-2 py-3">
											<span class="fs--13 fw--400">{{ item }}</span>
											<v-icon size="18" class="ml-2" color="black" @click="actions.removeTag(index)">{{ icons.close }}</v-icon>
										</v-chip>
									</div>
								</div>
							</template>
							<!-- <div class="sticker-name-input mt-7">
								<input
									type="text"
									placeholder="Sticker Name"
									:class="error && 'name-error'"
									v-model="sticker.name"
									@input="actions.setName" />
								<p v-if="error" class="red--text mb-0">Sticker name is required.</p>
							</div> -->
						</v-col>
					</v-row>
					
				</div>
				<p class="fs--12 mt-5 mb-5 fw--500 shinyPurple--text text-center">
					Please be sure your sticker adheres to our
					<a href="https://www.streamstickers.com/terms-of-service" target="_blank">terms of service</a> or we
					will be forced to remove it.
				</p>
			</div>
		</v-card-text>
		<div class="footer-section d-flex flex-wrap justify-center px-3 px-sm-0">
			<v-btn
				class="mt-3 my-sm-6 px-12 mr-2"
				depressed
				@click="actions.moveBack"
				:disabled="saveLoading"
				outlined
				color="pink"
				>Back
			</v-btn>
			<v-btn class="mt-3 my-sm-6 px-12" depressed :loading="saveLoading" @click="actions.submit" color="pink"
				>Submit</v-btn
			>
		</div>
	</v-card>
</template>

<script>
import { computed, ref, reactive, onMounted } from '@vue/composition-api';
import { createObjectUrlFromBlob } from '@/utils/sticker.js';
import { getCustomStickerAssetPath, getHostedAssetPath } from '@/utils/config';
import { getUserId } from '@/utils/auth';
import { mdiVolumeHigh, mdiPlusCircle, mdiCloseCircle } from '@mdi/js';
import { TTS_ASSETS_BASE } from '@/utils/utils';
export default {
	props: {
		sticker: {
			type: Object,
			default: null,
		},
		isPlaying: {
			type: Boolean,
			default: false,
		},
		saveLoading: {
			type: Boolean,
			default: false,
		},
	},
	emits: ['onMove', 'onSearchTerm', 'onSubmit'],
	setup(props, ctx) {
		const error = ref(false);

		const tag = ref('');
		const tagList = reactive([]);

		const imageSource = computed(() => {
			if (props.sticker.stickerBlob) {
				return createObjectUrlFromBlob(props.sticker.stickerBlob);
			} else {
				return '';
			}
		});

		onMounted(() => {
			tagList.length = 0;
			if (props.sticker.searchTerms && props.sticker.searchTerms.length) {
				props.sticker.searchTerms.forEach((item) => {
					tagList.push(item)
				})
			}
		});

		const actions = {
			getImage(type, image) {
				if (type === 'mouth' && image && typeof image.file === 'string') {
					if (image.file.includes('custom_stickers')) {
						return getHostedAssetPath() + '/' + image.file;
					}
					return TTS_ASSETS_BASE + '/' + type + '/animated/' + image.file;
				}
				if (type === 'mouth' && image && typeof image.file === 'object' && props.sticker.tts.mouthBlob) {
					return props.sticker.tts.mouthBlob;
				}

				if (type === 'eyes' && image && typeof image.file === 'string') {
					if (image.file.includes('custom_stickers')) {
						return getHostedAssetPath() + '/' + image.file;
					}
					return TTS_ASSETS_BASE + '/' + type + '/' + image.file;
				}

				if (type === 'eyes' && image && typeof image.file === 'object' && props.sticker.tts.eyeBlob) {
					return props.sticker.tts.eyeBlob;
				}

				return null;
			},
			getBaseImage() {
				if (props.sticker && props.sticker.stickerBlob) {
					return props.sticker.stickerBlob;
					// return URL.createObjectURL(props.sticker.baseImage)
				} else if (props.sticker.tts.baseImage) {
					const baseImg = props.sticker.tts.baseImage.split('tts/')[1];
					const path = `${getCustomStickerAssetPath(getUserId())}`;
					return `${path}/tts/${baseImg}`;
				}

				return null;
			},
			addTag() {
				if (tag.value) {
					tagList.push(tag.value);
					tag.value = '';
					ctx.emit('onSearchTerm', tagList)
				}
			},
			removeTag(index) {
				tagList.splice(index, 1);
				ctx.emit('onSearchTerm', tagList)
			},
			submit: function () {
				error.value = false;
				if (!props.sticker.name) {
					error.value = true;
					return;
				}
				ctx.emit('onSubmit');
			},
			moveBack: function () {
				ctx.emit('onMove', props.sticker.tts.isBonusTtsShareSlot ? 3 : 4);
			},
			setName: function (e) {
				const value = e.target.value;
				if (value && value.length > 0) {
					error.value = false;
				}
			},
			toggleAudio: function () {
				ctx.emit('onToggleAudio');
			},
		};

		return {
			tag,
			tagList,
			actions,
			imageSource,
			error,
			icons: {
				volumeIcon: mdiVolumeHigh,
				plus: mdiPlusCircle,
				close: mdiCloseCircle
			},
		};
	},
};
</script>

<style lang="scss" scoped>
.placement {
	position: relative;
	height: 300px;
	width: 250px;
	max-width: 100%;
	background: url('../../../../assets/tts/base/base-1.png');
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center center;
	border-radius: 4px;
	margin: auto;
}

.draggable,
.vdr {
	border: none !important;
}

.sticker-name-input input {
	width: 90%;
	height: 69px;
	margin: auto;
	outline: none;
	background: #2b195b;
	color: white;
	font-size: 27px;
	font-weight: 500;
	text-align: center;
	padding: 0 20px;
	border-radius: 5px;
}

.drr::before,
.drr.active::before {
	border: none !important;
	outline: none !important;
}

.name-error {
	border: 2px solid #4a93d9;
}

.tags-section {
	height: 80px;
	overflow-y: auto;
}
</style>
