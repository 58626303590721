<template>
    <v-card>
        <v-snackbar v-model="isFileError" color="error" :timeout="2500" top>
            {{ fileErrorMessage }}
        </v-snackbar>
        <v-card-text class="pt-0 px-0 configure-animation">
            <div class="step-section">
                <h4 class="text-center textColor--text fs--20 fw--400">Customize Eyes & Mouth Style</h4>

                <div class="d-flex justify-center justify-sm-start flex-wrap mt-4">
                    <StickerPreview
                        :sticker="sticker"
                        :currentEye="eyeConfig.config.currentEye"
                        :isEye="eyeConfig.config.isEye"
                        :isEyeFlipped="eyeConfig.config.isEyeFlipped"
                        :currentMouth="mouthConfig.config.currentMouth"
                        :isMouth="mouthConfig.config.isMouth"
                        :isMouthFlipped="mouthConfig.config.isMouthFlipped"
                        @onChangeDimensions="actions.setDimensions"
                    />
                    <div class="controls ml-lg-3">
                        <EyeStyle
                            :sticker="sticker"
                            @onEyeConfig="actions.setEyeConfig" />

                        <MouthStyle
                        :sticker="sticker"
                        :customAnimatedMouth="customAnimatedMouth"
                        :customClosedMouth="customClosedMouth"
                        @onChangeCustomMouth="actions.setCustomMouth"
                        @onMouthConfig="actions.setMouthConfig"
                        />
                    </div>
                </div>
            </div>
        </v-card-text>

        <div class="footer-section d-flex flex-wrap justify-center px-3 px-sm-0">
            <v-btn class="mt-3 my-sm-6 px-12 mr-2" depressed @click="actions.moveBack" outlined color="pink">Back
            </v-btn>
            <v-btn class="mt-3 mb-3 mb-sm-0 my-sm-6 px-12" depressed @click="actions.moveNext" color="pink">Next</v-btn>
        </div>
    </v-card>
</template>

<script>
import { ref, reactive } from '@vue/composition-api';
import { mdiCloudUploadOutline, mdiChevronLeft, mdiChevronRight, mdiUpload, mdiDelete } from '@mdi/js';

import StickerPreview from './CreateAnimation/StickerPreview'
import EyeStyle from './CreateAnimation/EyeStyle'
import MouthStyle from './CreateAnimation/MouthStyle'
import { fileToBase64 } from '@/utils/sticker.js';
// import 'vue-draggable-resizable/dist/VueDraggableResizable.css'

export default {
    components: {
        StickerPreview,
        EyeStyle,
        MouthStyle
    },
    props: {
        sticker: {
            type: Object,
            required: true,
        },
        customAnimatedMouth: {
            type: Object | String,
            default: null,
        },
        customClosedMouth: {
            type: Object | String,
            default: null,
        },
        from: {
            type: Number,
            required: false,
        },
    },
    emits: ['onMove', 'onCustomMouth', 'onConfigureAnimation'],
    setup(props, ctx) {
        const position = ref(null)

        const eyeConfig = reactive({
            config: {
                isEye: true,
                isEyeFlipped: false,
                currentEye: null,
                eyeBlob: null
            }
        })

        const mouthConfig = reactive({
            config: {
                isMouth: true,
                isMouthFlipped: false,
                currentMouth: null,
                mouthBlob: null,
                closedMouthBlob: null,
            }
        })

        const isFileError = ref(false);
        const fileErrorMessage = ref(null);

        const actions = {
            setDimensions(data) {
                if (data) {
                    position.value = { ...data.position }
                }
            },
            setEyeConfig(data) {
                if (data) {
                    eyeConfig.config = { ...data }
                }
                
            },
            setMouthConfig(data) {
                if (data) {
                    mouthConfig.config = { ...data }
                }
            },
            setCustomMouth(data) {
                if (data) {
                    ctx.emit('onCustomMouth', data)
                }
            },
            
            moveNext: async function () {
                let eyePos, mouthPos;

                const eyeddr = document.getElementsByClassName('eye-drag')[0];
                if (eyeddr) {
                    const styles = window.getComputedStyle(eyeddr);
                    const eyeLeftPos = styles.getPropertyValue('left');
                    const eyeTopPos = styles.getPropertyValue('top');

                    eyePos = {
                        x: Number(eyeLeftPos.split('px')[0]),
                        y: Number(eyeTopPos.split('px')[0])
                    }
                }
                const mouthddr = document.getElementsByClassName('mouth-drag')[0];
                if (mouthddr) {
                    const styles = window.getComputedStyle(mouthddr);
                    const mouthLeftPos = styles.getPropertyValue('left');
                    const mouthTopPos = styles.getPropertyValue('top');

                    mouthPos = {
                        x: Number(mouthLeftPos.split('px')[0]),
                        y: Number(mouthTopPos.split('px')[0])
                    }
                }

                let closed = mouthConfig.config.closedMouthBlob

                // If Closed Mouth Blob is a File then parse it to base64
                if (mouthConfig.config.closedMouthBlob && typeof mouthConfig.config.closedMouthBlob === 'object') {
                    closed = await fileToBase64(mouthConfig.config.closedMouthBlob)
                }


                const data = {
                    isEye: eyeConfig.config.isEye,
                    eyeImage: eyeConfig.config.currentEye || null,
                    eyeBlob: eyeConfig.config.eyeBlob || null,
                    isEyeFlipped: eyeConfig.config.isEyeFlipped || false,

                    isMouth: mouthConfig.config.isMouth,
                    mouthImage: mouthConfig.config.currentMouth || null,
                    mouthBlob: mouthConfig.config.mouthBlob || null,
                    closedMouthBlob: closed || null,
                    isMouthFlipped: mouthConfig.config.isMouthFlipped || false,

                    position: {
                        ...position.value,
                    },
                    bounds: {
                        ...position.value,
                        eyeX: eyePos && eyePos.x || 0,
                        eyeY: eyePos && eyePos.y || 0,
                        mouthX: mouthPos && mouthPos.x || 0,
                        mouthY: mouthPos && mouthPos.y || 0,
                    }
                }
                // console.log(data)
                ctx.emit('onConfigureAnimation', data);
            },

            moveBack: function () {
                ctx.emit('onMove', 1);
            },
        };

        return {
            eyeConfig,
            mouthConfig,
            
            actions,
            isFileError,
            fileErrorMessage,

            icons: {
                uploadIcon: mdiCloudUploadOutline,
                arrowLeft: mdiChevronLeft,
                arrowRight: mdiChevronRight,
                upload: mdiUpload,
                trashIcon: mdiDelete
            },
        };
    },

    methods: {},
};
</script>

<style scoped lang="scss">

.eye-area {
    position: absolute;
}

.controls {

    .eye-control,
    .mouth-control {
        width: 480px;
        min-height: 200px;
        background-color: var(--v-lightPurple-base);
        border: 2px solid var(--v-boldPurple-base);
        border-radius: 8px;
        margin-top: 5px;
        position: relative;

        .eye-images,
        .mouth-images {
            width: 65%;
        }

        .eye-uploader,
        .mouth-uploader {
            width: 35%;
        }

        .enable-switch {
            left: 10px;
            bottom: 5px;
        }

        .flip-switch {
            right: 37%;
            bottom: 5px;

            @media screen and (max-width: 995px) {
                right: 37%;
            }

            @media screen and (max-width: 599px) {
                right: 10px;
            }
        }
    }
}

.image-name {
    width: 80%;
    margin: 0 auto;
    overflow-wrap: anywhere;
    -webkit-overflow-wrap: anywhere;
    -o-overflow-wrap: anywhere;
    -moz-overflow-wrap: anywhere;
}

.draggable {
    border: none !important;
}

.draggable.active {
    border: 2px dotted var(--v-purple-base) !important;
}

.current-eye,
.current-mouth {
    width: 180px;
    height: 90px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    margin-bottom: 3px;
}

.slide-btn {
    margin: 0 10px;
}

@media screen and (max-width: 995px) {

    .customizer,
    .sticker-customizer {
        width: 100%;
    }

    .controls {
        width: 100%;
        .eye-control,
        .mouth-control {
            width: 100%;
            min-height: 325px;
        }
    }
}

@media screen and (max-width: 600px) {
    .controls {
        .eye-control {
            min-height: 325px;
        }
        .mouth-control {
            min-height: 365px;
        }

        // .flip-switch {
        //     right: 10px;
        //     bottom: 5px;
        // }
    }

    .current-eye,
    .current-mouth {
        width: 120px;
        height: 90px;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center center;
        margin-bottom: 3px;
    }
}

@media screen and (min-width: 996px) and (max-width: 1263px) {
    .controls {
        margin-left: 10px;
    }
}

</style>
