<template>
	<v-dialog max-width="800" v-model="uploader" persistent>
		<v-card class="sticker-uploader" max-height="90vh">
			<v-card-text class="pa-0">
				<div class="upload-header">
					<h3 class="text-capitalize text-center d-none d-sm-block fs--22 fw--500">
						{{ nameFromStickerType(stickerType) }} Sticker Wizard
					</h3>

					<h3 class="text-capitalize text-center d-block d-sm-none fs--18 fw--500">
						{{ nameFromStickerType(stickerType) }} Sticker Wizard
					</h3>

					<v-icon class="close-icon" @click="actions.closeWizard">{{ icons.closeIcon }}</v-icon>
				</div>
				<v-divider class="purple"></v-divider>
				<v-card class="sticker-upload-stepper">
					<v-stepper class="px-0" flat v-model="step">
						<v-stepper-header class="px-0">
							<v-stepper-step :step="1">
								<Step :step="step" :current="1" title="Upload" subtitle="image" />
							</v-stepper-step>

							<v-divider :class="actions.addLineClass(1)"></v-divider>

							<v-stepper-step :step="2">
								<Step :step="step" :current="2" title="Add" subtitle="sound" />
							</v-stepper-step>

							<v-divider :class="actions.addLineClass(2)"></v-divider>

							<v-stepper-step :step="3">
								<Step :step="step" :current="3" title="Add" subtitle="info" />
							</v-stepper-step>

							<v-divider :class="actions.addLineClass(3)"></v-divider>

							<v-stepper-step :step="4">
								<Step :step="step" :current="4" title="Check" subtitle="& confirm" />
							</v-stepper-step>
						</v-stepper-header>
						<v-divider class="purple"></v-divider>
						<v-stepper-items>
							<v-stepper-content class="px-0 pb-0 upload-content" :step="1">
								<UploadImage :sticker="sticker" @onMove="actions.changeStep" />
							</v-stepper-content>
							<v-stepper-content class="px-0 pb-0 upload-content" :step="2">
								<AddSound
									:sticker="sticker"
									:isPlaying="isPlaying"
									@onMove="actions.changeStep"
									@onToggleAudio="actions.toggleAudio" />
							</v-stepper-content>
							<v-stepper-content class="px-0 pb-0 upload-content" :step="3">
								<AddName
									:sticker="sticker"
									:isPlaying="isPlaying"
									v-on:onSearchTerm="actions.setSearchTerms"
									v-on:onMove="actions.changeStep"
									v-on:onToggleAudio="actions.toggleAudio" />
							</v-stepper-content>
							<v-stepper-content class="px-0 pb-0 upload-content" :step="4">
								<Confirm
									:sticker="sticker"
									:isPlaying="isPlaying"
									:isLoading="isUploading"
									v-on:onMove="actions.changeStep"
									v-on:onSubmit="actions.saveChanges"
									v-on:onToggleAudio="actions.toggleAudio" />
							</v-stepper-content>
						</v-stepper-items>
					</v-stepper>
				</v-card>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
import axios from '@axios';
import { reactive, ref } from '@vue/composition-api';
import { nameFromStickerType } from '../../../../utils/constants';
import { createObjectUrlFromBlob } from '../../../../utils/sticker.js';
import { mdiClose } from '@mdi/js';
import Step from './Step';
import UploadImage from './UploadImage';
import AddSound from './AddSound';
import AddName from './AddName';
import Confirm from './Confirm';
import { saveEvent } from '@/utils/gtag';

export default {
	components: {
		Step,
		UploadImage,
		AddSound,
		AddName,
		Confirm,
	},
	props: {
		uploader: {
			type: Boolean,
			default: false,
		},
		stickerType: {
			type: String,
			required: true,
		},
	},
	emits: ['onUploaded', 'onClose'],

	setup(props, ctx) {
		const step = ref(1);
		const isUploading = ref(false);
		const isPlaying = ref(false);
		const sticker = reactive({
			name: null,
			stickerBlob: null,
			audioBlob: null,
			searchTerms: [],
		});

		let player = null;

		const actions = {
			addLineClass: function (tab) {
				if (step.value === tab) {
					return 'step-dash purpleDivider';
				}
				if (step.value > tab) {
					return 'step-dash pink';
				}
				if (step.value < tab) {
					return 'step-dash purpleDivider';
				}
			},

			changeStep: function (value) {
				step.value = value;
			},

			onTypeName: function (name) {
				sticker.name = name;
			},

			setSearchTerms: function (terms) {
				sticker.searchTerms = [...terms];
			},

			toggleAudio: function () {
				if (!isPlaying.value) {
					if (sticker.audioBlob) {
						player = new Audio(createObjectUrlFromBlob(sticker.audioBlob));
						player.play();

						player.addEventListener('ended', () => {
							isPlaying.value = false;
							player = null;
						});

						isPlaying.value = true;
					}
				} else if (player && isPlaying) {
					player.pause();
					isPlaying.value = false;
				}
			},

			saveChanges: function () {
				isUploading.value = true;
				axios
					.post(`/custom-sticker/${props.stickerType}`, sticker)
					.then(() => {
						ctx.emit('onUploaded', true);

						saveEvent({
							event: 'sticker_upload_complete',
							params: {
								event_category: 'engagement',
								event_label: 'User Completes the Sticker Upload Process (Sticker Saved)',
								item_category: props.stickerType,
							},
						});
					})
					.catch(error => console.log(error))
					.finally(() => (isUploading.value = false));
			},

			closeWizard: function () {
				if (isPlaying.value && player) {
					player.pause();
					player = null;
					isPlaying.value = false;
				}

				ctx.emit('onClose');
			},
		};

		return {
			isUploading,
			actions,
			nameFromStickerType,
			sticker,
			step,
			isPlaying,
			icons: {
				closeIcon: mdiClose,
			},
		};
	},

	methods: {},
};
</script>

<style lang="scss" scoped>
.sticker-upload-stepper {
	max-height: 100px;
	overflow-y: auto;

	@media screen and (min-height: 300px) {
		max-height: 200px;
	}

	@media screen and (min-height: 466px) {
		max-height: 350px;
	}

	@media screen and (min-height: 600px) {
		max-height: 480px;
	}

	@media screen and (min-height: 730px) {
		max-height: 630px;
	}

	@media screen and (min-height: 800px) {
		max-height: 90%;
	}
}
</style>
