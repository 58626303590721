<template>
    <div class="discord-follow mt-3">
        <div class="d-flex align-center flex-wrap">
            <img src="@/assets/images/discord-icon.png" alt="Discord">
            <strong class="text-uppercase white--text fs--13 pl-2">BONUS: </strong>
            <span class="white--text fs--12 pl-2">Unlock Two additional custom sticker slots instantly by joining our
                Discord Community!</span>
            <span class="text-underline cursor-pointer white--text pl-1 fs--12">Click here to learn more!</span>
        </div>

        <v-dialog v-model="popup" max-width="800" persistent>
            <v-card>

                <div class="popup-header">
                    <h3 class="text-capitalize text-center d-none d-sm-block fs--22 fw--500">
                        Unlock Bonus Sticker Slots
                    </h3>

                    <h3 class="text-capitalize text-center d-block d-sm-none fs--18 fw--500">
                        Unlock Bonus Sticker Slots
                    </h3>

                    <v-icon class="close-icon" @click="$emit('close')">{{ icons.closeIcon }}</v-icon>
                </div>
                <v-card-text>
                    <div class="content-section mt-10 mb-5">
                        <v-row>
                            <v-col cols="12" md="6" lg="5" xl="4">
                                <div class="follow-image text-center">
                                    <img src="@/assets/images/Bot-Discord.png" alt="Discord Bot">
                                </div>
                            </v-col>
                            <v-col cols="12" md="6" lg="7" xl="8">
                                <div class="text-center">
                                    <p class="white--text fs--18">Need More Sticker Slots?</p>

                                    <div class="fieldFore--text fs--14">Join our Discord community and we'll instantly unlock 2 more custom sticker slots!</div>

                                    <v-btn class="mt-8" min-height="80" color="pink" depressed>
                                        <img src="@/assets/images/join-discord.png" height="70%" alt="Join Discord" />
                                    </v-btn>

                                </div>
                            </v-col>
                        </v-row>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { ref } from '@vue/composition-api';
import { mdiClose, mdiTwitter } from '@mdi/js';
export default {
    props: {
        popup: {
            type: Boolean,
            default: false
        }
    },
    emits: ['close'],
    setup() {
        return {
            icons: {
                closeIcon: mdiClose,
                twitter: mdiTwitter
            },
        }
    }
}
</script>

<style scoped>
.discord-follow {
    width: 100%;
    background: -webkit-linear-gradient(to right, #CE56FC 25%, #1B6CA3, #006091);
    background: -o-gradient(to right, #CE56FC 25%, #1B6CA3, #006091);
    background: -ms-gradient(to right, #CE56FC 25%, #1B6CA3, #006091);
    background: -moz-gradient(to right, #CE56FC 25%, #1B6CA3, #006091);
    background: linear-gradient(to right, #CE56FC 25%, #1B6CA3, #006091);
    padding: 8px 10px;
    border-radius: 5px;
}

.popup-header {
    position: relative;
    padding: 12px 0;
    background: url('../../../assets/images/backgrounds/sticker-upload-header.png');
    background-repeat: no-repeat;
    background-size: fill;
}

.close-icon {
    position: absolute;
    top: 15px;
    right: 15px;
}

.content-section {
    width: 90%;
    margin: auto;
}
</style>