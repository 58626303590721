/**
 * Provides access to internationalized messages return by the API.
 */
import store from '@/store';

const getMessage = name => {
	const msg = store.state.app.messages.find(m => m.name == name);
	return msg ? msg.value : '';
};

export { getMessage };
