<template>
    <div class="twitter-follow mt-3">
        <div class="d-flex align-center flex-wrap">
            <img src="@/assets/images/Twitter.png" alt="Twitter">
            <strong class="text-uppercase white--text fs--13 pl-2">BONUS: </strong>
            <span class="white--text fs--12 pl-2">Unlock Two additional custom sticker slots instantly by following us
                on Twitter!</span>
            <span class="text-underline cursor-pointer white--text pl-1 fs--12">Click here to learn more!</span>
        </div>

        <v-dialog v-model="popup" max-width="800" persistent>
            <v-card>

                <div class="popup-header">
                    <h3 class="text-capitalize text-center d-none d-sm-block fs--22 fw--500">
                        Unlock Bonus Sticker Slots
                    </h3>

                    <h3 class="text-capitalize text-center d-block d-sm-none fs--18 fw--500">
                        Unlock Bonus Sticker Slots
                    </h3>

                    <v-icon class="close-icon" @click="$emit('close')">{{ icons.closeIcon }}</v-icon>
                </div>
                <v-card-text>
                    <div class="content-section mt-10 mb-5">
                        <v-row>
                            <v-col cols="12" md="6" lg="5" xl="4">
                                <div class="follow-image text-center">
                                    <img src="@/assets/images/Bot-Twitter.png" alt="Twitter Bot">
                                </div>
                            </v-col>
                            <v-col cols="12" md="6" lg="7" xl="8">
                                <div class="text-center">
                                    <p class="white--text fs--18">Need More Sticker Slots?</p>

                                    <span class="fieldFore--text fs--14">Drop us a follow on Twitter and we'll instantly
                                        unlock 2 more custom sticker slots!</span>

                                    <div class="d-flex align-center justify-center my-4">
                                        <v-btn color="#F8F8F8" class="px-3" small depressed>
                                            <v-icon color="#5CB8EE">{{ icons.twitter }}</v-icon>
                                            <span
                                                class="black--text text-capitalize fs--14 d-block d-md-none d-lg-block pl-2">Follow</span>
                                            <span
                                                class="black--text text-capitalize fs--12 d-none d-md-block d-lg-none pl-2">Follow</span>
                                        </v-btn>

                                        <span class="fieldFore--text fs--16 px-3">or</span>

                                        <v-btn color="#F8F8F8" class="px-3" small depressed>
                                            <v-icon color="#5CB8EE">{{ icons.twitter }}</v-icon>
                                            <span
                                                class="black--text text-capitalize fs--14 d-block d-md-none d-lg-block pl-2">Connect
                                                your twitter account</span>
                                            <span
                                                class="black--text text-capitalize fs--12 d-none d-md-block d-lg-none pl-2">Connect
                                                your twitter account</span>
                                        </v-btn>
                                    </div>

                                    <div class="mt-5">
                                        <p class="white--text fs--16 mb-2">Already Followed?</p>

                                        <span class="fieldFore--text text-capitalize fs--14">Enter your username</span>
                                    </div>

                                    <v-row class="mt-2">
                                        <v-col cols="7" sm="8" md="7" lg="8" xl="9">
                                            <v-text-field background-color="purple" color="fieldFore" dense hide-details
                                                solo placeholder="@MyTwitterName"></v-text-field>
                                        </v-col>
                                        <v-col cols="5" sm="4" md="4" lg="3" xl="2">
                                            <v-btn color="pink" depressed block>Continue</v-btn>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { ref } from '@vue/composition-api';
import { mdiClose, mdiTwitter } from '@mdi/js';
export default {
    props: {
        popup: {
            type: Boolean,
            default: false
        }
    },
    emits: ['close'],
    setup() {
        return {
            icons: {
                closeIcon: mdiClose,
                twitter: mdiTwitter
            },
        }
    }
}
</script>

<style scoped>
.twitter-follow {
    width: 100%;
    background: -webkit-linear-gradient(to right, #137CE8, #9952F1, #AD36F2);
    background: -o-gradient(to right, #137CE8, #9952F1, #AD36F2);
    background: -ms-gradient(to right, #137CE8, #9952F1, #AD36F2);
    background: -moz-gradient(to right, #137CE8, #9952F1, #AD36F2);
    background: linear-gradient(to right, #137CE8, #9952F1, #AD36F2);
    padding: 8px 10px;
    border-radius: 5px;
}

.popup-header {
    position: relative;
    padding: 12px 0;
    background: url('../../../assets/images/backgrounds/sticker-upload-header.png');
    background-repeat: no-repeat;
    background-size: fill;
}

.close-icon {
    position: absolute;
    top: 15px;
    right: 15px;
}

.content-section {
    width: 90%;
    margin: auto;
}
</style>