<template>
    <div class="eye-control d-flex flex-column flex-sm-row align-center py-3">
        <div class="eye-images px-2 d-flex flex-column align-center">
            <h4 class="fs--16 fw--400 text-center">Eye Style</h4>
            <div class="d-flex align-center">
                <v-btn color="secondary" class="px-0 slide-btn" small depressed
                    @click="actions.slideEye('left')">
                    <v-icon>{{ icons.arrowLeft }}</v-icon>
                </v-btn>
                <v-card color="lightPurple" flat height="100">
                    <v-carousel v-model="eyeItem" hide-delimiters>
                        <v-carousel-item v-for="(item, i) in eyeImages.items" :key="i"
                            reverse-transition="fade-transition" transition="fade-transition">
                            <template #default>
                                <div id="currentEye" class="current-eye cursor-pointer"
                                    :style="{ 'background-image': `url(${actions.getImage('eyes', item, 'slider')})` }"
                                    @click="actions.setCurrentEye(item)" :class="eyeConfig.config.isEyeFlipped ? 'flipped' : 'regular'">
                                </div>
                                <div class="text-center image-name fieldFore--text">{{
                                    item.name.substr(0, 12) }}{{ item.name.length > 12 && '...' || '' }}
                                </div>
                            </template>
                        </v-carousel-item>

                        <template v-slot:prev="{ on, attrs }">
                            <v-btn class="arrow-btn left-eye d-none" icon v-bind="attrs" v-on="on">
                                <v-icon>{{ icons.arrowLeft }}</v-icon>
                            </v-btn>
                        </template>

                        <template v-slot:next="{ on, attrs }">
                            <v-btn class="arrow-btn right-eye d-none" icon v-bind="attrs" v-on="on">
                                <v-icon>{{ icons.arrowRight }}</v-icon>
                            </v-btn>
                        </template>
                    </v-carousel>
                </v-card>
                <v-btn color="secondary" class="px-0 slide-btn" small depressed
                    @click="actions.slideEye('right')">
                    <v-icon>{{ icons.arrowRight }}</v-icon>
                </v-btn>
            </div>
            <div class="mt-3 enable-switch position-absolute">
                <v-switch v-model="eyeConfig.config.isEye" color="pink" label="Enable" hide-details></v-switch>
            </div>

            <div class="mt-3 flip-switch position-absolute">
                <v-switch v-model="eyeConfig.config.isEyeFlipped" color="pink" label="Flip" hide-details></v-switch>
            </div>
        </div>
        <v-divider class="boldPurple mt-4 mt-sm-0" :vertical="$vuetify.breakpoint.smAndUp"></v-divider>
        <div class="eye-uploader d-flex flex-column align-center justify-center">
            <span class="text-center fs--14">Add Custom <br> Eyes</span>

            <div v-if="!isEyeUploaded" class="mt-3 text-center">
                <v-btn color="primary" depressed small @click="actions.uploadImage">
                    Upload
                </v-btn>
            </div>

            <template v-if="isEyeUploaded">
                <div class="d-flex align-center mt-3">
                    <img src="@/assets/icons/check-circle.png" alt="Uploaded">
                    <v-btn depressed small class="px-1 ml-3" min-width="35" min-height="35"
                        color="secondary" @click="actions.removeEye()">
                        <v-icon color="white">{{ icons.trashIcon }}</v-icon>
                    </v-btn>
                </div>
            </template>
            <input type="file" accept="image/x-png,image/gif" class="d-none" @change="actions.onEyePick"
                ref="eyeInput" />
        </div>
    </div>
</template>

<script>
import { computed, ref, reactive, onMounted, watch } from '@vue/composition-api';
import { TTS_ASSETS_BASE, eyePresets } from '@/utils/utils';
import { getHostedAssetPath } from '@/utils/config.js';
import { fileToBase64 } from '@/utils/sticker.js';
import { mdiCloudUploadOutline, mdiChevronLeft, mdiChevronRight, mdiUpload, mdiDelete } from '@mdi/js';

export default {
    props: {
        sticker: {
            type: Object,
            required: true,
        },
    },
    emits: ['onEyeConfig'],
    setup(props, ctx) {

        const presetPath = '0.0.1/tts/'
        const customPath = 'custom_stickers/'

        const eyeImages = reactive({
            items: [...eyePresets]
        });

        const eyeFiles = eyePresets.map(img => img.file)

        const eyeItem = ref(0);

        const eyeConfig = reactive({
            config: {
                isEye: true,
                isEyeFlipped: false,
                currentEye: eyeImages.items[0],
                eyeBlob: null
            }
        })

        onMounted(() => {
            actions.setEyeConfig();
        })

        watch(eyeConfig.config, (val) => {
            ctx.emit('onEyeConfig', val)
        }, { immediate: true, deep: true })

        const isEyeUploaded = ref(false);

        const eyeInput = ref(null);

        const actions = {
            setEyeConfig() {
                const tts = { ...props.sticker.tts }

                eyeConfig.config.isEye = tts.isEyeEnabled
                eyeConfig.config.isEyeFlipped = tts.isEyeFlipped
                eyeConfig.config.currentEye = tts.eyeImage
                eyeConfig.config.eyeBlob = tts.eyeBlob

                /*
                    1. If Create Sticker then follow eyeBlob
                    2. If Edit Sticker then follow eyeImage and check if it starts with custom_stickers then it is a custom eye else its a preset
                */

                actions.setEyeImage();
                actions.checkCustomAsset();
            },
            setEyeImage() {
                // For Create Sticker
                if (!props.sticker.id) {
                    const currentEye = eyeConfig.config.currentEye;

                    if(!currentEye) {
                        eyeConfig.config.currentEye = eyeImages.items[0];
                    } else {
                        // Set the selected eye from the slider
                        const selectedEye = eyeImages.items.findIndex((img) => img.file === eyeConfig.config.currentEye.file)
                        if (selectedEye >= 0) {
                            eyeItem.value = selectedEye;
                        } else {
                            eyeImages.items.push(eyeConfig.config.currentEye);
                            eyeItem.value = eyeImages.items.length - 1;
                        }
                    }
                } else {
                    if (typeof props.sticker.tts.eyeImage === 'string' && props.sticker.tts.eyeImage.startsWith(presetPath)) {
                        // Set Eye Image from default
                        const name = props.sticker.tts.eyeImage.split('/').at(-1);

                        const selectedEye = eyeImages.items.findIndex((img) => img.file === name)
                        if (selectedEye >= 0) {
                            eyeItem.value = selectedEye;
                            eyeConfig.config.currentEye = eyeImages.items[selectedEye];
                        }
                        
                    } else if (typeof props.sticker.tts.eyeImage === 'object' && typeof props.sticker.tts.eyeImage.file === 'string' && !props.sticker.tts.eyeImage.file.startsWith(presetPath) && eyeFiles.includes(props.sticker.tts.eyeImage.file)) {
                        // Set Eye Image
                        const selectedEye = eyeImages.items.findIndex((img) => img.file === eyeConfig.config.currentEye.file)
                        if (selectedEye >= 0) {
                            eyeItem.value = selectedEye;
                            eyeConfig.config.currentEye = eyeImages.items[selectedEye];
                        }
                    } else if (typeof props.sticker.tts.eyeImage === 'string' && props.sticker.tts.eyeImage.startsWith(customPath) && !eyeFiles.includes(props.sticker.tts.eyeImage)) {
                        // Set Eye Image
                        const item = {
                            name: 'Custom Eye',
                            file: props.sticker.tts.eyeImage
                        }

                        eyeImages.items.push(item)

                        eyeItem.value = eyeImages.items.length - 1;
                        eyeConfig.config.currentEye = eyeImages.items.at(-1);
                        isEyeUploaded.value = true
                    } else if ((typeof props.sticker.tts.eyeImage === 'object' && typeof props.sticker.tts.eyeImage.file === 'string') && (props.sticker.tts.eyeImage.file.includes(customPath) && !eyeFiles.includes(props.sticker.tts.eyeImage.file))) {
                        // Set Eye Image
                        const item = {
                            name: 'Custom Eye',
                            file: props.sticker.tts.eyeImage.file
                        }

                        eyeImages.items.push(item)

                        eyeItem.value = eyeImages.items.length - 1;
                        eyeConfig.config.currentEye = eyeImages.items.at(-1);
                        isEyeUploaded.value = true
                    } else if (typeof props.sticker.tts.eyeImage === 'object' && typeof props.sticker.tts.eyeImage.file === 'object') {
                        const selectedEye = eyeImages.items.findIndex((img) => img.file === eyeConfig.config.currentEye.file)
                        if (selectedEye >= 0) {
                            eyeItem.value = selectedEye;
                        } else {
                            eyeImages.items.push(eyeConfig.config.currentEye);
                            eyeItem.value = eyeImages.items.length - 1;
                        }
                    }
                }
            },
            checkCustomAsset() {
                // Check is Custom Eye Exists in the Array
                const isCustomEyeExists = eyeImages.items.find(eye => typeof eye.file === 'object' || (typeof eye.file === 'string' && eye.file.includes(customPath)))
                
                if (isCustomEyeExists) {
                    isEyeUploaded.value = true
                }
            },
            uploadImage() {
                eyeInput.value.click();
            },
            async onEyePick(e) {
                const file = e.target.files[0]
                if (file) {
                    const item = {
                        name: file.name.split('.')[0],
                        file: file
                    }
                    if (isEyeUploaded.value) {
                        eyeImages.items[eyeImages.items.length - 1] = { ...item };
                    } else {
                        eyeConfig.config.eyeBlob = await fileToBase64(file)
                        eyeImages.items.push(item);
                        // ctx.emit('onCustomAsset', {
                        //     type: 'eyes',
                        //     action: 'push',
                        //     data: item
                        // })
                    }

                    actions.setCurrentEye(item);
                    eyeItem.value = eyeImages.items.length - 1;

                    isEyeUploaded.value = true;
                    
                }
            },
            removeEye() {
                // ctx.emit('onCustomAsset', {
                //     type: 'eyes',
                //     action: 'pop'
                // })
                eyeImages.items = JSON.parse(JSON.stringify(eyeImages.items.slice(0, -1)));
                eyeConfig.config.eyeBlob = null;
                isEyeUploaded.value = false;

                // Set first element by default
                eyeConfig.config.currentEye = eyeImages.items[0]
                eyeItem.value = 0;
            },
            slideEye(direction) {
                const elem = document.querySelector(`.${direction}-eye`)
                elem.click()

                actions.selectEye(direction);
            },

            selectEye(direction) {
                const total = eyeImages.items.length;
                const currentIndex = eyeImages.items.findIndex((image, index) => image.name === eyeConfig.config.currentEye.name)

                if (direction === 'right') {
                    if (currentIndex === total - 1) {
                        eyeConfig.config.currentEye = eyeImages.items[0];
                    } else {
                        eyeConfig.config.currentEye = eyeImages.items[currentIndex + 1];
                    }
                }

                if (direction === 'left') {
                    if (currentIndex === 0) {
                        eyeConfig.config.currentEye = { ...eyeImages.items[total - 1] };
                    } else {
                        eyeConfig.config.currentEye = { ...eyeImages.items[currentIndex - 1] };
                    }
                }
            },
            setCurrentEye(eye) {
                eyeConfig.config.currentEye = { ...eye }
            },
            getImage(type, image, slider = undefined) {
                if (typeof image.file === 'string') {
                    if (image.file.includes(customPath)) {
                        return getHostedAssetPath() + '/' + image.file;
                    } else {
                        return TTS_ASSETS_BASE + '/' + type + '/' + image.file;
                    }
                    // if (image.file.includes(presetPath)) {
                    //     return TTS_ASSETS_BASE + '/' + type + '/' + image.file;
                    // }
                    // if (image.file.includes(presetPath)) {
                    //     return TTS_ASSETS_BASE + '/' + type + '/' + image.file;
                    // }
                }

                if (image && typeof image.file === 'object') {
                    return URL.createObjectURL(image.file)
                }

                // if (props.sticker.id) {
                //     if (type === "eyes" && image && typeof image.file === 'string' && image.file.includes(presetPath)) {
                //         return TTS_ASSETS_BASE + '/' + type + '/' + eyeConfig.config.currentEye.file;
                //     } else if (type === "eyes" && image && typeof image.file === 'string' && image.file.includes(customPath)) {
                //         return getHostedAssetPath() + '/' + image.file;
                //     } else if (image && typeof image.file === 'object') {
                //         return URL.createObjectURL(image.file)
                //     }
                // } else {
                //     if (type === "eyes" && image && typeof image.file === 'string') {
                //         return TTS_ASSETS_BASE + '/' + type + '/' + image.file;
                //     }
                //     else if (image && typeof image.file === 'object') {
                //         return URL.createObjectURL(image.file)
                //     }
                // }

                return null
            },
        }

        return {
            eyeImages,
            eyeInput,
            isEyeUploaded,
            eyeItem,
            eyeConfig,

            actions,

            icons: {
                uploadIcon: mdiCloudUploadOutline,
                arrowLeft: mdiChevronLeft,
                arrowRight: mdiChevronRight,
                upload: mdiUpload,
                trashIcon: mdiDelete
            },
        }
    }
}
</script>

<style scoped lang="scss">

.eye-area {
    position: absolute;
}

.controls {

    .eye-control,
    .mouth-control {
        width: 480px;
        min-height: 200px;
        background-color: var(--v-lightPurple-base);
        border: 2px solid var(--v-boldPurple-base);
        border-radius: 8px;
        margin-top: 5px;
        position: relative;

        .eye-images,
        .mouth-images {
            width: 65%;
        }

        .eye-uploader,
        .mouth-uploader {
            width: 35%;
        }

        .enable-switch {
            left: 10px;
            bottom: 5px;
        }

        .flip-switch {
            right: 37%;
            bottom: 5px;

            @media screen and (max-width: 995px) {
                right: 37%;
            }

            @media screen and (max-width: 599px) {
                right: 10px;
            }
        }
    }
}

.image-name {
    width: 80%;
    margin: 0 auto;
    overflow-wrap: anywhere;
    -webkit-overflow-wrap: anywhere;
    -o-overflow-wrap: anywhere;
    -moz-overflow-wrap: anywhere;
}

.current-eye,
.current-mouth {
    width: 180px;
    height: 90px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    margin-bottom: 3px;
}

.slide-btn {
    margin: 0 10px;
}

@media screen and (max-width: 995px) {

    .customizer,
    .sticker-customizer {
        width: 100%;
    }

    .controls {
        width: 100%;
        .eye-control,
        .mouth-control {
            width: 100%;
            min-height: 325px;
        }
    }
}

@media screen and (max-width: 600px) {
    .controls {
        .eye-control {
            min-height: 325px;
        }
        .mouth-control {
            min-height: 365px;
        }

        // .flip-switch {
        //     right: 10px;
        //     bottom: 5px;
        // }
    }

    .current-eye,
    .current-mouth {
        width: 120px;
        height: 90px;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center center;
        margin-bottom: 3px;
    }
}

@media screen and (min-width: 996px) and (max-width: 1263px) {
    .controls {
        margin-left: 10px;
    }
}

</style>