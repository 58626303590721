<template>
	<v-dialog max-width="500" v-model="complete" persistent>
		<v-card>
			<v-card-text class="pa-0">
				<div class="upload-header">
					<h3 class="text-capitalize text-center d-none d-sm-block fs--22 fw--500">
						{{ type }} Sticker Wizard
					</h3>

					<h3 class="text-capitalize text-center d-block d-sm-none fs--18 fw--500">
						{{ type }} Sticker Wizard
					</h3>

					<v-icon class="close-icon" @click="actions.closeModal">{{ icons.closeIcon }}</v-icon>
				</div>
				<v-divider class="purple mt-12"></v-divider>
				<div class="text-center mt-5">
					<h4 class="textColor--text text-center fs--20 fw--400 mb-5">Your Sticker saved!</h4>
					<v-btn @click="actions.closeModal" color="pink" depressed min-height="39" class="mb-10" width="210"
						>OK</v-btn
					>
				</div>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
import { mdiClose } from '@mdi/js';
export default {
	props: {
		type: {
			type: String,
			default: false,
		},
		complete: {
			type: Boolean,
			default: false,
		},
		uploaded: {
			type: Boolean,
			default: false,
		},
	},
	emits: ['close'],
	setup(props, ctx) {
		const actions = {
			closeModal: function () {
				ctx.emit('close');
			},
		};

		return {
			actions,
			icons: {
				closeIcon: mdiClose,
			},
		};
	},
};
</script>

<style lang="scss" scoped>
.upload-header {
	position: relative;
	padding: 12px 0;
	background: url('../../../assets/images/backgrounds/sticker-upload-header.png');
	background-repeat: no-repeat;
	background-size: fill;
}

.close-icon {
	position: absolute;
	top: 15px;
	right: 15px;
}
</style>
