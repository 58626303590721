<template>
    <div class="sticker-customizer text-center">
                            <h4 class="text-center textColor--text fs--18 fw--400 mb-4 mt-1">Sticker Preview</h4>
                            <div id="stickerArea" class="placement my-3"
                                :style="{ 'background-image': `url(${actions.getBaseImage(sticker)})` }"
                                v-click-outside="isActive = false" @click="isActive = true">

                                <drr id="eyeddr" class="eye-drag" :parent="true" :x="position.eyeX" :y="position.eyeY" :w="position.eyeW"
                                    :h="position.eyeH" :angle="position.eyeAngle" :draggable="true" :resizable="true"
                                    :aspectRatio="true" @drag="actions.setEyeDimensions"
                                    :outerBound="{ w: 300, h: 300, x: 125, y: 150 }"
                                    @resize="actions.setEyeDimensions"
                                    @rotate="actions.setEyeDimensions">

                                    <div v-if="isEye && currentEye" id="eyeImage" :class="isEyeFlipped ? 'flipped' : 'regular'"
                                        style="position: absolute; width: 100%; height: 100%"
                                        :style="{ backgroundImage: `url(${actions.getImage('eyes', currentEye, null)})`, backgroundSize: 'contain', backgroundPosition: 'center center' }">
                                    </div>
                                </drr>

                                <drr id="mouthddr" class="mouth-drag" :parent="true" :x="position.mouthX" :y="position.mouthY"
                                    :w="position.mouthW" :h="position.mouthH" :angle="position.mouthAngle" :draggable="true"
                                    :outerBound="{ w: 300, h: 300, x: 125, y: 150 }"
                                    :resizable="true" :aspectRatio="true" @drag="actions.setMouthDimensions"
                                    @resize="actions.setMouthDimensions" @rotate="actions.setMouthDimensions">

                                    <div v-if="isMouth && currentMouth" id="mouthImage" :class="isMouthFlipped ? 'flipped' : 'regular'"
                                        style="position: absolute; width: 100%; height: 100%"
                                        :style="{ backgroundImage: `url(${actions.getImage('mouth', currentMouth, null)})`, backgroundSize: 'contain', backgroundPosition: 'center center' }">
                                    </div>
                                </drr>
                            </div>

                            <span class="text-center fieldFore--text fs--14 fw--500">Drag, Rotate and Resize elements to
                                customize your sticker.</span>
                        </div>
</template>

<script>
import { computed, ref, reactive, onMounted, watch } from '@vue/composition-api';
import { getCustomStickerAssetPath, getHostedAssetPath } from '@/utils/config.js';
import { getUserId } from '@/utils/auth';
import { TTS_ASSETS_BASE } from '@/utils/utils';

export default {
    props: {
        sticker: {
            type: Object,
            required: true,
        },
        isEye: {
            type: Boolean,
            default: true
        },
        isMouth: {
            type: Boolean,
            default: true
        },
        isEyeFlipped: {
            type: Boolean,
            default: false
        },
        isMouthFlipped: {
            type: Boolean,
            default: false
        },
        currentEye: {
            type: Object | String,
            default: null
        },
        currentMouth: {
            type: Object | String,
            default: null
        }
    },
    emits: ['onChangeDimensions'],
    setup(props, ctx) {
        const isActive = ref(false);
        const eyeImage = ref(null);
        const mouthImage = ref(null);
        const position = ref({
            eyeX: 120,
            eyeY: 95,
            mouthX: 120,
            mouthY: 215,
            eyeW: 150,
            eyeH: 100,
            mouthW: 70,
            mouthH: 110,
            eyeAngle: 0,
            mouthAngle: 0
        })

        onMounted(() => {
            const tts = { ...props.sticker.tts }

            position.value.eyeX = tts.position.eyeX
            position.value.eyeY = tts.position.eyeY
            position.value.eyeW = tts.position.eyeW
            position.value.eyeH = tts.position.eyeH
            position.value.eyeAngle = tts.position.eyeAngle
            position.value.mouthX = tts.position.mouthX
            position.value.mouthY = tts.position.mouthY
            position.value.mouthW = tts.position.mouthW
            position.value.mouthH = tts.position.mouthH
            position.value.mouthAngle = tts.position.mouthAngle

            actions.passDimensions();
        })

        const actions = {
            getBaseImage(sticker) {
                if (sticker.stickerBlob) {
                    return sticker.stickerBlob
                }
                else if (sticker.tts.baseImage) {
                    const baseImg = props.sticker.tts.baseImage.split('tts/')[1]
                    const path = `${getCustomStickerAssetPath(getUserId())}`
                    return `${path}/tts/${baseImg}`
                }
            },
            getImage(type, image, slider = undefined) {
                if (props.sticker.id) {
                    if (type === "eyes" && image && typeof image.file === 'string' && image.name !== 'Custom Eye') {
                        return TTS_ASSETS_BASE + '/' + type + '/' + props.currentEye.file;
                    } else if (type === "eyes" && image && typeof image.file === 'string' && image.name === 'Custom Eye') {
                        return getHostedAssetPath() + '/' + image.file;
                    }


                    if (type === "mouth" && image && typeof image.file === 'string' && image.name !== 'Custom Mouth') {
                        return TTS_ASSETS_BASE + '/' + type + '/animated/' + props.currentMouth.file;
                    } else if (type === "mouth" && image && typeof image.file === 'string' && image.name === 'Custom Mouth') {
                        return getHostedAssetPath() + '/' + image.file;
                    }

                    else if (image && typeof image.file === 'object') {
                        return URL.createObjectURL(image.file)
                    }
                } else {
                    if (type === "mouth" && image && typeof image.file === 'string') {
                        return TTS_ASSETS_BASE + '/' + type + '/animated/' + image.file;
                    }
                    if (type === "eyes" && image && typeof image.file === 'string') {
                        return TTS_ASSETS_BASE + '/' + type + '/' + image.file;
                    }
                    else if (image && typeof image.file === 'object') {
                        return URL.createObjectURL(image.file)
                    }
                }

                return null
            },
            setEyeDimensions(dimension) {
                position.value.eyeX = dimension.x
                position.value.eyeY = dimension.y
                position.value.eyeW = dimension.w
                position.value.eyeH = dimension.h
                position.value.eyeAngle = dimension.angle

                actions.passDimensions();
            },
            setMouthDimensions(dimension) {
                position.value.mouthX = dimension.x
                position.value.mouthY = dimension.y
                position.value.mouthW = dimension.w
                position.value.mouthH = dimension.h
                position.value.mouthAngle = dimension.angle

                actions.passDimensions();
            },

            passDimensions() {
                ctx.emit('onChangeDimensions', {
                    position: position.value
                })
            }
        }
        return {
            isActive,
            position,
            actions
        }
    }
}
</script>

<style scoped lang="scss">
.placement {
    position: relative;
    height: 300px;
    width: 250px;
    margin: 0 auto;
    /* background: url('../../../../assets/tts/base/base-1.png'); */

    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    transform: translate3d(0, 0, 0);
    transition: background-image 1s ease-in-out;
}
.sticker-customizer {
    background-color: var(--v-lightPurple-base);
    border: 2px solid var(--v-boldPurple-base);
    border-radius: 8px;
    width: 300px;
    margin-top: 5px;
}

#eyeddr:hover,
#mouthddr:hover {
    cursor: all-scroll !important;
}

.draggable {
    border: none !important;
}

.draggable.active {
    border: 2px dotted var(--v-purple-base) !important;
}
</style>
