<template>
	<v-card>
		<v-card-text class="pt-0">
			<div class="step-section">
				<h4 class="text-center textColor--text fs--17 fw--500">Add Sticker Name and Search Terms:</h4>

				<!-- <div v-if="!sticker || !sticker.image || !sticker.sound" class="upload-area text-center mt-4">
                    <h2>Image or audio not found.</h2>
                </div> -->
				<div v-if="sticker && sticker.stickerBlob" class="upload-area text-center mt-4">
					<v-row class="px-3" align-md="center">
						<v-col cols="12" md="4" class="text-center">
							<img :src="imageSource" width="150" alt="Sticker Image" />
						</v-col>
						<v-col cols="12" md="8" class="text-left">
							<span class="white--text fs--16 fw--500">Sticker Name</span>
							<v-text-field
								v-model="sticker.name"
								background-color="purple"
								maxlength="25"
								placeholder="Enter Sticker Name"
								class="mt-2 mb-3"
								:class="error && 'name-error mb-0'"
								@input="error = false"
								solo
								dense
								hide-details
							></v-text-field>

							<p v-if="error" class="red--text">Sticker name is required.</p>

							<span class="white--text fs--16 fw--500">Sticker Search Terms</span> <br>
							<span class="fs--12 fw--500 fw--500 shinyPurple--text">Help your users find this sticker with descriptive terms (optional)</span>
							
							<v-row class="mb-0 mt-1">
								<v-col cols="12" sm="8" md="8" class="py-1">
									<v-text-field
										v-model="tag"
										background-color="purple"
										maxlength="15"
										placeholder="Enter Search Term"
										class="mt-2"
										solo
										dense
										hide-details
										@keyup.enter="actions.addTag"
									></v-text-field>
								</v-col>
								<v-col cols="12" sm="4" md="4" class="py-1">
									<v-btn color="pink" class="mt-0 mt-md-2" block depressed @click="actions.addTag">
									<v-icon class="mr-1" size="20">{{ icons.plus }}</v-icon> Add</v-btn>
								</v-col>
							</v-row>

							<template v-if="tagList.length">
								<div class="purple mt-3 pt-3 pb-2 pl-3 pr-1">
									<div class="tags-section">
										<v-chip v-for="(item, index) in tagList" :key="index" color="info" small class="mr-1 mb-2 pl-3 pr-2 py-3">
											<span class="fs--13 fw--400">{{ item }}</span>
											<v-icon size="18" class="ml-2" color="black" @click="actions.removeTag(index)">{{ icons.close }}</v-icon>
										</v-chip>
									</div>
								</div>
							</template>
						</v-col>
					</v-row>
					<!-- <div class="mb-5 text-center">
						<img :src="imageSource" width="150" alt="Sticker Image" />
					</div>

					<v-btn
						v-if="sticker.audioBlob"
						@click="actions.toggleAudio"
						color="secondary"
						:width="$vuetify.breakpoint.xsOnly ? 'auto' : '220'"
						min-height="41"
						depressed>
						<v-icon size="24" class="mr-1">{{ icons.volumeIcon }}</v-icon>
						{{ isPlaying ? 'Stop' : 'Preview' }} Audio
					</v-btn>

					<div class="sticker-name-input mt-7">
						<input
							type="text"
							placeholder="Sticker Name"
							:class="error && 'name-error'"
							maxlength="25"
							v-model="sticker.name"
							@input="actions.setName" />
						<p v-if="error" class="red--text mb-0">Sticker name is required.</p>
					</div>

					<div class="tags-input d-flex flex-column mt-3">
						<input
							v-model="tag"
							type="text"
							placeholder="Enter Tag"
							@keyup.enter="actions.addTag"
						/>

						<div v-if="tagList.length" class="tag-list mt-2">
							<v-chip v-for="(item, index) in tagList" color="pink" class="mr-1 mb-1">
								<span class="fs--13 fw--400">{{ item }}</span>
								<v-icon size="18" class="ml-2" color="black" @click="actions.removeTag(index)">{{ icons.close }}</v-icon>
							</v-chip>
						</div>
					</div> -->
				</div>
				<p class="fs--12 mt-5 mb-5 fw--500 shinyPurple--text text-center">
					Please be sure your sticker adheres to our
					<a href="https://www.streamstickers.com/terms-of-service" target="_blank">terms of service</a> or we
					will be forced to remove it.
				</p>
			</div>
		</v-card-text>
		<div class="footer-section d-flex flex-wrap justify-center px-3 px-sm-0">
			<v-btn class="mt-3 my-sm-6 px-12 mr-2" depressed @click="actions.moveBack" outlined color="pink"
				>Back</v-btn
			>
			<v-btn class="mt-3 my-sm-6 px-12" depressed @click="actions.moveNext" color="pink">Next</v-btn>
		</div>
	</v-card>
</template>

<script>
import { computed, ref, reactive } from '@vue/composition-api';
import { createObjectUrlFromBlob } from '../../../../utils/sticker.js';
import { mdiVolumeHigh, mdiPlusCircle, mdiCloseCircle } from '@mdi/js';
export default {
	props: {
		sticker: {
			type: Object,
			default: null,
		},
		isPlaying: {
			type: Boolean,
			default: false,
		},
	},
	emits: ['onMove', 'onSearchTerm', 'onToggleAudio'],
	setup(props, ctx) {
		const error = ref(false);
		const tag = ref('');
		const tagList = reactive([]);

		const imageSource = computed(() => {
			if (props.sticker.stickerBlob) {
				return createObjectUrlFromBlob(props.sticker.stickerBlob);
			} else {
				return '';
			}
		});

		const actions = {
			addTag() {
				if (tag.value) {
					tagList.push(tag.value);
					tag.value = '';
					ctx.emit('onSearchTerm', tagList)
				}
			},
			removeTag(index) {
				tagList.splice(index, 1);
				ctx.emit('onSearchTerm', tagList)
			},
			moveNext: function () {
				error.value = false;
				if (!props.sticker.name) {
					error.value = true;
					return;
				}
				ctx.emit('onMove', 4);
			},
			moveBack: function () {
				ctx.emit('onMove', 2);
			},
			setName: function (e) {
				const value = e.target.value;
				if (value && value.length > 0) {
					error.value = false;
				}
			},
			toggleAudio: function () {
				ctx.emit('onToggleAudio');
			},
		};

		return {
			tag,
			tagList,
			actions,
			imageSource,
			error,
			icons: {
				volumeIcon: mdiVolumeHigh,
				plus: mdiPlusCircle,
				close: mdiCloseCircle
			},
		};
	},
};
</script>

<style lang="scss" scoped>
.sticker-name-input input {
	width: 90%;
	height: 69px;
	margin: auto;
	outline: none;
	background: #2b195b;
	color: white;
	font-size: 27px;
	font-weight: 500;
	text-align: center;
	padding: 0 20px;
	border-radius: 5px;
}

.tags-section {
	height: 80px;
	overflow-y: auto;
}

/* .tags-input input {
	height: 40px;
} */

.name-error {
	border: 2px solid #4a93d9;
}
</style>
