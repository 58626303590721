<template>
	<v-card height="100%" class="character-bg">
		<v-card-text>
			<v-row no-gutters>
				<template v-if="stickerType !== StickerType.TTS">
					<v-col cols="12" :lg="stickerType == StickerType.CUSTOM ? 8 : StickerType.TTS ? 5 : 6">
						<h3 v-if="stickerType !== StickerType.GLOBAL_AI_TTS" class="fs--23 fw--500">How it works</h3>

						<!-- How it works content -->
						<slot name="text" />

						<template v-if="stickerType == StickerType.CUSTOM">
							<h3 class="fs--18 fw--500">Unlock Slots</h3>
							<div class="d-flex justify-space-between mt-2 mb-1">
								<span class="info--text">{{ data.lastUnlockedSlot }}</span>
								<span class="info--text">{{ data.intPercentReached + '%' }}</span>
								<span class="textColor--text">{{
									data.nextLockedSlot > 0 ? data.nextLockedSlot : ''
								}}</span>
							</div>
							<v-progress-linear
								:value="data.intPercentReached"
								background-color="purple"
								color="info"
								rounded
								disabled
								height="18"></v-progress-linear>
							<div class="d-flex justify-space-between align-center mt-1">
								<div>
									<span class="info--text">Current</span>
								</div>
								<div class="d-flex align-center">
									<img width="14" class="mr-1" :src="getBitIcon(data.currentStreamerBits)" />
									<span class="fs--13 fw--500 fieldFore--text"
										>{{ data.currentStreamerBits.toLocaleString() }}
										<span class="px-1 fs--13 fw--500 fieldFore--text">/</span>
									</span>
									<img width="14" class="mr-1" :src="getBitIcon(data.nextSlotBits)" />
									<span class="fs--13 fw--500 fieldFore--text">{{
										data.nextSlotBits.toLocaleString()
									}}</span>
								</div>
								<div>
									<span class="white--text">Next</span>
								</div>
							</div>
						</template>
					</v-col>
					<v-col
						cols="12"
						:lg="stickerType == StickerType.CUSTOM ? 4 : StickerType.TTS ? 7 : 8"
						class="d-none d-lg-block">
						<img
							v-if="stickerType == StickerType.CUSTOM"
							class="custom-character"
							src="@/assets/images/mascot/Character.png"
							alt="Character" />

						<img
							v-if="stickerType == StickerType.REWARD"
							class="reward-character"
							src="@/assets/images/mascot/RewardMascott.png"
							alt="Reward Mascott" />

						<img
							v-if="stickerType == StickerType.GLOBAL"
							class="global-character"
							src="@/assets/images/mascot/GlobalMascott.png"
							alt="Global Mascott" />
					</v-col>
				</template>

				<template v-if="stickerType === StickerType.TTS">
					<TTSDemo class="demo" />
				</template>
				<template v-if="stickerType === StickerType.GLOBAL_AI_TTS">
					<GlobalTTSDemo />
				</template>
			</v-row>
		</v-card-text>
	</v-card>
</template>

<script>
import { StickerType } from '../../../utils/constants';
import { getBitIcon } from '../../../utils/utils';
import TTSDemo from './TTSDemo';
import GlobalTTSDemo from './GlobalTTSDemo';

export default {
	components: {
		TTSDemo,
		GlobalTTSDemo,
	},
	props: {
		stickerType: {
			type: String,
			required: true,
		},
		data: {
			type: Object,
			required: false,
		},
	},
	setup() {
		return {
			StickerType,
			getBitIcon,
		};
	},
};
</script>

<style scoped>
.character-bg {
	position: relative;
	z-index: 2;
}

.character-bg img.custom-character {
	position: absolute;
	bottom: -18px;
	right: -19px;
	z-index: 1;
}

.character-bg img.reward-character {
	position: absolute;
	bottom: -38px;
	right: -12px;
	z-index: 1;
}

.character-bg img.global-character {
	position: absolute;
	bottom: -38px;
	right: -14px;
	z-index: 1;
}

@media screen and (min-width: 1264px) and (max-width: 1426px) {
	img.custom-character {
		width: 45%;
		right: -15px !important;
	}

	img.reward-character {
		width: 55%;
		height: 110%;
	}

	img.global-character {
		width: 55%;
		height: 110%;
	}
}

@media screen and (max-width: 1366px) {
	.tts-demo {
		/* display: none; */
	}
}
</style>
