<template>
	<v-dialog max-width="950" v-model="uploader" persistent>
		<v-snackbar v-model="isFileError" color="error" :timeout="4500" top>
			{{ fileErrorMessage }}
		</v-snackbar>
		<v-card class="sticker-uploader" max-height="98vh">
			<v-card-text class="pa-0">
				<div class="upload-header">
					<h3 class="text-capitalize text-center d-none d-sm-block fs--22 fw--500">
						<!-- {{ nameFromStickerType(stickerType) }} Sticker Wizard -->
						TTS Sticker Wizard
					</h3>

					<h3 class="text-capitalize text-center d-block d-sm-none fs--18 fw--500">
						<!-- {{ nameFromStickerType(stickerType) }} Sticker Wizard -->
						TTS Sticker Wizard
					</h3>

					<v-icon class="close-icon" @click="actions.closeWizard">{{ icons.closeIcon }}</v-icon>
				</div>
				<v-divider class="purple"></v-divider>
				<v-card class="sticker-upload-stepper tts-stepper" min-height="100">
					<v-stepper class="px-0" flat v-model="step">
						<v-stepper-header class="px-0">
							<template v-for="(item, index) in stepItems" :step="index + 1">
								<v-stepper-step :step="index + 1">
									<Step
										:step="step"
										:current="index + 1"
										:title="item.title"
										:subtitle="item.subtitle" />
								</v-stepper-step>

								<v-divider
									v-if="index < stepItems.length - 1"
									:class="actions.addLineClass(index + 1)"></v-divider>
							</template>

							<!-- <v-stepper-step :step="1">
								<Step :step="step" :current="1" title="Upload" subtitle="image" />
							</v-stepper-step>

							<v-divider :class="actions.addLineClass(1)"></v-divider>

							<v-stepper-step :step="2">
								<Step :step="step" :current="2" title="Configure" subtitle="animation" />
							</v-stepper-step>

							<v-divider :class="actions.addLineClass(2)"></v-divider>

							<v-stepper-step :step="3">
								<Step :step="step" :current="3" title="Configure" subtitle="settings" />
							</v-stepper-step>

							<v-divider :class="actions.addLineClass(3)"></v-divider>

							<v-stepper-step :step="4">
								<Step :step="step" :current="4" title="Display" subtitle="mode" />
							</v-stepper-step>

							<v-divider :class="actions.addLineClass(4)"></v-divider>

							<v-stepper-step :step="5">
								<Step :step="step" :current="5" title="Add name" subtitle="& confirm" />
							</v-stepper-step> -->
						</v-stepper-header>
						<v-divider class="purple"></v-divider>
						<v-stepper-items class="sticker-steps">
							<!-- <template v-for="(item, index) in stepItems" :step="index + 1">
								<v-stepper-content class="px-0 pb-0 upload-content" :step="index + 1">
									<UploadImage :sticker="sticker.data" :edit="edit" @baseAdded="actions.setStickerBase"
									@onMove="actions.changeStep" />

									<ConfigureAnimation v-if="step === 2" :from="previous" :sticker="sticker.data" :edit="edit"
									:eyeAssets="eyeImages.items" :mouthAssets="mouthImages.items" :customEye="customEye"
									:customAnimatedMouth="customAnimatedMouth" :customClosedMouth="customClosedMouth"
									@onCustomMouth="actions.setCustomAsset" @onMove="actions.changeStep"
									@onConfigureAnimation="actions.configureAnimation" />

									<ConfigureSettings v-if="step === 3" :sticker="sticker.data" :stickerSlot="stickerSlot"
									:edit="edit" @alertAdded="actions.onAlertAdded"
									@onConfigureSettings="actions.configureSettings" v-on:onMove="actions.changeStep" />

									<SelectMode v-if="step === index + 1 && !isBonusTtsShareSlot" :sticker="sticker.data" :stickerSlot="stickerSlot"
									:edit="edit" @onConfigureMode="actions.configureMode"
									v-on:onMove="actions.changeStep" />

									<AddName v-if="step === index + 1" :sticker="sticker.data" :edit="edit" :saveLoading="saveLoading"
									v-on:onMove="actions.changeStep" v-on:onSubmit="actions.saveChanges" />
								</v-stepper-content>
							</template> -->

							<v-stepper-content class="px-0 pb-0 upload-content" :step="1">
								<UploadImage
									:sticker="sticker.data"
									:edit="edit"
									@baseAdded="actions.setStickerBase"
									@onMove="actions.changeStep"
									@onPreMadeSelection="actions.onPreMadeSelection" />
							</v-stepper-content>
							<v-stepper-content class="px-0 pb-0 upload-content" :step="2">
								<ConfigureAnimation
									v-if="step === 2"
									:from="previous"
									:sticker="sticker.data"
									:edit="edit"
									:eyeAssets="eyeImages.items"
									:mouthAssets="mouthImages.items"
									:customEye="customEye"
									:customAnimatedMouth="customAnimatedMouth"
									:customClosedMouth="customClosedMouth"
									@onCustomMouth="actions.setCustomAsset"
									@onMove="actions.changeStep"
									@onConfigureAnimation="actions.configureAnimation" />
							</v-stepper-content>
							<v-stepper-content class="px-0 pb-0 upload-content" :step="3">
								<ConfigureSettings
									v-if="step === 3"
									:sticker="sticker.data"
									:stickerSlot="stickerSlot"
									:edit="edit"
									@alertAdded="actions.onAlertAdded"
									@onConfigureSettings="actions.configureSettings"
									v-on:onMove="actions.changeStep" />
							</v-stepper-content>
							<v-stepper-content v-if="!isBonusTtsShareSlot" class="px-0 pb-0 upload-content" :step="4">
								<SelectMode
									v-if="step === 4"
									:sticker="sticker.data"
									:stickerSlot="stickerSlot"
									:edit="edit"
									@onConfigureMode="actions.configureMode"
									v-on:onMove="actions.changeStep" />
							</v-stepper-content>
							<v-stepper-content class="px-0 pb-0 upload-content" :step="isBonusTtsShareSlot ? 4 : 5">
								<AddName
									v-if="step === isBonusTtsShareSlot ? 4 : 5"
									:sticker="sticker.data"
									:edit="edit"
									:saveLoading="saveLoading"
									v-on:onSearchTerm="actions.setSearchTerms"
									v-on:onMove="actions.changeStep"
									v-on:onSubmit="actions.saveChanges" />
							</v-stepper-content>
						</v-stepper-items>
					</v-stepper>
				</v-card>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
import axios from '@axios';
import { reactive, ref, onMounted } from '@vue/composition-api';
import { VoiceType, nameFromStickerType } from '../../../../utils/constants';
import { createObjectUrlFromBlob, fileToBase64 } from '../../../../utils/sticker.js';
import { eyePresets, mouthPresets } from '@/utils/utils';
import _cloneDeep from 'lodash/cloneDeep';
import { mdiClose } from '@mdi/js';
import Step from './Step';
import UploadImage from './UploadImage';
import ConfigureAnimation from './ConfigureAnimation';
import ConfigureSettings from './ConfigureSettings';
import SelectMode from './SelectMode';
import AddName from './AddName';
import Confirm from './Confirm';
import { saveEvent } from '@/utils/gtag';

export default {
	components: {
		Step,
		UploadImage,
		ConfigureAnimation,
		ConfigureSettings,
		SelectMode,
		AddName,
		Confirm,
	},
	props: {
		uploader: {
			type: Boolean,
			default: false,
		},
		stickerType: {
			type: String,
			required: true,
		},
		edit: {
			type: Boolean,
			default: false,
		},
		isBonusTtsShareSlot: {
			type: Boolean,
			default: false,
		},
		bonusTtsShareVoiceId: {
			type: String,
		},
		stickerToEdit: {
			type: Object,
			default: false,
		},
		stickerSlot: {
			type: Number,
		},
	},
	emits: ['onUploaded', 'onClose'],
	setup(props, ctx) {
		const step = ref(1);
		const isPlaying = ref(false);
		const saveLoading = ref(false);

		const preMadeSticker = ref(null);

		let stepItems = ref([
			{
				id: 1,
				title: 'Upload',
				subtitle: 'image',
				key: 'upload-image',
			},
			{
				id: 2,
				title: 'Configure',
				subtitle: 'animation',
				key: 'configure-animation',
			},
			{
				id: 3,
				title: 'Configure',
				subtitle: 'settings',
				key: 'configure-settings',
			},
			{
				id: 4,
				title: 'Display',
				subtitle: 'mode',
				key: 'display-mode',
			},
			{
				id: 5,
				title: 'Add name',
				subtitle: '& confirm',
				key: 'add-name',
			},
		]);

		let eyeImages = reactive({
			items: [...eyePresets],
		});

		let mouthImages = reactive({
			items: [...mouthPresets],
		});

		const customEye = ref(null);

		const customAnimatedMouth = ref(null);
		const customClosedMouth = ref(null);

		const presetPath = '0.0.1/tts/';
		const customPath = 'custom_stickers/';

		const eyeFiles = eyePresets.map(img => img.file);
		const mouthFiles = mouthPresets.map(img => img.file);

		const isFileError = ref(false);
		const fileErrorMessage = ref('');

		const sticker = reactive({
			data: {
				name: null,
				stickerBlob: null,
				audioBlob: null,
				searchTerms: [],
				tts: {
					stickerSlot: props.stickerSlot,
					displayMode: 'STICKER',
					eyeImage: null,
					eyeBlob: null,
					mouthImage: null,
					mouthBlob: null,
					closedMouthBlob: null,
					isEyeEnabled: true,
					isEyeFlipped: false,
					isMouthEnabled: true,
					isMouthFlipped: false,
					isClosedMouthEnabled: true,

					voiceService: '',
					voiceType: VoiceType.AI,
					voiceId: null,
					ownership: '',

					isAccentEnhancement: false,
					volume: 70,
					stickiness: 70,
					stability: 30,

					isBonusTtsShareSlot: false,

					sku: null,
					subOnly: false,
					text: null,
					bits: 0,
					language: 'en',
					alertSound: null,
					alertSoundBlob: null,
					position: {
						eyeX: 120,
						eyeY: 95,
						mouthX: 120,
						mouthY: 215,
						eyeW: 100,
						eyeH: 50,
						mouthW: 70,
						mouthH: 50,
						eyeAngle: 0,
						mouthAngle: 0,
					},
					bounds: {},
				},
			},
		});

		onMounted(() => {
			actions.prepareSticker();
		});

		let previous = ref(1);

		let player = null;

		const actions = {
			addLineClass: function (tab) {
				if (step.value === tab) {
					return 'step-dash purpleDivider';
				}
				if (step.value > tab) {
					return 'step-dash pink';
				}
				if (step.value < tab) {
					return 'step-dash purpleDivider';
				}
			},

			prepareSticker() {
				if (props.stickerToEdit && props.edit) {
					sticker.data = JSON.parse(JSON.stringify(props.stickerToEdit));
				}

				if (preMadeSticker.value) {
					sticker.data = JSON.parse(JSON.stringify(preMadeSticker.value));
				}

				if ((props.edit && props.stickerToEdit) || preMadeSticker.value) {
					if (
						sticker.data.tts.closedMouthImage &&
						typeof sticker.data.tts.closedMouthImage === 'string' &&
						sticker.data.tts.closedMouthImage.startsWith(customPath)
					) {
						customClosedMouth.value = {
							file: sticker.data.tts.closedMouthImage,
							name: 'Closed',
						};
					}

					if (
						sticker.data.tts.mouthImage &&
						typeof sticker.data.tts.mouthImage === 'string' &&
						sticker.data.tts.mouthImage.startsWith(customPath)
					) {
						customAnimatedMouth.value = {
							file: sticker.data.tts.mouthImage,
							name: 'Custom Mouth',
						};
					}
				} else {
					actions.initStickerData();
				}

				if (props.isBonusTtsShareSlot) {
					sticker.data.tts.isBonusTtsShareSlot = true;
					stepItems.value = stepItems.value.filter(item => item.key !== 'display-mode');
				}

				if (props.bonusTtsShareVoiceId) {
					sticker.data.tts.voiceId = props.bonusTtsShareVoiceId;
				}
			},

			initStickerData() {
				sticker.data = {
					name: null,
					stickerBlob: null,
					audioBlob: null,
					searchTerms: [],
					tts: {
						stickerSlot: props.stickerSlot,
						displayMode: 'STICKER',
						eyeImage: null,
						eyeBlob: null,
						mouthImage: null,
						mouthBlob: null,
						closedMouthBlob: null,
						isEyeEnabled: true,
						isEyeFlipped: false,
						isMouthEnabled: true,
						isMouthFlipped: false,
						isClosedMouthEnabled: true,

						voiceService: '',
						voiceType: VoiceType.AI,
						voiceId: null,
						ownership: '',

						isAccentEnhancement: false,
						volume: 70,
						stickiness: 70,
						stability: 30,

						isBonusTtsShareSlot: false,

						sku: null,
						subOnly: false,
						text: null,
						bits: 0,
						language: 'en',
						alertSound: null,
						alertSoundBlob: null,
						position: {
							eyeX: 120,
							eyeY: 95,
							mouthX: 120,
							mouthY: 215,
							eyeW: 100,
							eyeH: 50,
							mouthW: 70,
							mouthH: 50,
							eyeAngle: 0,
							mouthAngle: 0,
						},
						bounds: {},
					},
				};
			},

			setStickerBase(image) {
				sticker.data.stickerBlob = image;
			},

			onPreMadeSelection(sticker) {
				preMadeSticker.value = sticker;
				actions.prepareSticker();

				preMadeSticker.value = null;
				actions.changeStep(2);
			},

			setCustomAsset(payload) {
				if (payload.type === 'eyes') {
					if (payload.action === 'push') {
						eyeImages.items.push(payload.data);
					}
					if (payload.action === 'pop') {
						eyeImages.items = JSON.parse(JSON.stringify(eyeImages.items.slice(0, -1)));
					}
				}

				if (payload.type === 'mouth') {
					if (payload.add) {
						if (payload.animated) {
							customAnimatedMouth.value = payload.animated;
						}
						if (payload.closed) {
							customClosedMouth.value = payload.closed;
						}
					}
					if (payload.remove) {
						// if (payload.animated) {
						// 	customAnimatedMouth.value = payload.animated
						// }
						// if (payload.closed) {
						// 	customClosedMouth.value = payload.closed
						// }

						const isAnimatedKey = payload['animated'];
						const isClosedKey = payload['closed'];

						if (isAnimatedKey === undefined && isClosedKey !== undefined) {
							customClosedMouth.value = null;
						}

						if (isAnimatedKey !== undefined && isClosedKey === undefined) {
							customAnimatedMouth.value = null;
						}

						if (isAnimatedKey !== undefined && isClosedKey !== undefined) {
							customAnimatedMouth.value = null;
							customClosedMouth.value = null;
						}
					}
				}
			},

			configureAnimation(data) {
				sticker.data.tts.isEyeEnabled = data.isEye;
				sticker.data.tts.isMouthEnabled = data.isMouth;
				sticker.data.tts.eyeImage = data.eyeImage;
				sticker.data.tts.eyeBlob = data.eyeBlob;
				sticker.data.tts.mouthImage = data.mouthImage;
				sticker.data.tts.mouthBlob = data.mouthBlob;
				sticker.data.tts.closedMouthBlob = data.closedMouthBlob;
				sticker.data.tts.isEyeFlipped = data.isEyeFlipped;
				sticker.data.tts.isMouthFlipped = data.isMouthFlipped;
				sticker.data.tts.position = { ...data.position };
				sticker.data.tts.bounds = { ...data.bounds };

				if (data.customEye) {
					customEye.value = data.customEye;
				}

				previous.value = step.value;
				step.value = 3;
			},

			onAlertAdded(data) {
				sticker.data.tts.alertSound = data;
			},

			configureSettings(data) {
				sticker.data.tts.language = data.language;
				sticker.data.tts.sku = data.bits;
				sticker.data.tts.volume = data.volume;
				sticker.data.tts.stickiness = data.stickiness;
				sticker.data.tts.stability = data.stability;
				sticker.data.tts.isClosedMouthEnabled = data.isClosedMouthEnabled;
				sticker.data.tts.text = data.text;
				sticker.data.tts.voiceService = data.voiceService;
				sticker.data.tts.voiceId = data.voiceId;
				sticker.data.tts.voiceType = data.voiceType;
				sticker.data.tts.ownership = data.ownership;
				sticker.data.tts.isAccentEnhancement = data.isAccentEnhancement;

				previous.value = step.value;
				step.value = 4;
			},

			configureMode(data) {
				sticker.data.tts.displayMode = data.displayMode;
			},

			changeStep: function (value) {
				previous.value = step.value;
				step.value = value;
			},

			onTypeName: function (name) {
				sticker.data.name = name;
			},

			setSearchTerms: function (terms) {
				sticker.data.searchTerms = [...terms];
			},

			toggleAudio: function () {
				if (!isPlaying.value) {
					if (sticker.data.audioBlob) {
						player = new Audio(createObjectUrlFromBlob(sticker.data.audioBlob));
						player.play();

						player.addEventListener('ended', () => {
							isPlaying.value = false;
							player = null;
						});

						isPlaying.value = true;
					}
				} else if (player && isPlaying) {
					player.pause();
					isPlaying.value = false;
				}
			},

			saveChanges: async function () {
				isFileError.value = false;
				fileErrorMessage.value = '';

				const voiceService = sticker.data.tts.voiceService;
				const ownership = sticker.data.tts.ownership;

				delete sticker.data.tts.voiceService;
				delete sticker.data.tts.ownership;
				// ---------------------------------------
				// TODO Fixing upload payload temporarily.
				// ---------------------------------------

				// Copy duplicate sticker attributes up to main object.
				const d = sticker.data;
				d.stickiness = d.tts.stickiness;
				d.volume = d.tts.volume;
				d.sku = d.tts.sku;
				d.subOnly = d.tts.subOnly;

				// Creating a copy of sticker object to avoid overwrite.
				const payload = _cloneDeep(sticker.data);

				if (sticker.data.tts.eyeImage) {
					if (sticker.data.tts.eyeImage.file && typeof sticker.data.tts.eyeImage.file === 'string') {
						// Stock eye images just pass the file name.
						payload.tts.eyeImage = sticker.data.tts.eyeImage.file;

						// Blobs should only be set for user-defined eye images.
						payload.tts.eyeBlob = null;
					} else if (sticker.data.tts.eyeImage.file && typeof sticker.data.tts.eyeImage.file === 'object') {
						// If custom eye image then following will be null.
						payload.tts.eyeImage = null;
					}
				}

				if (sticker.data.tts.mouthImage) {
					if (sticker.data.tts.mouthImage.file && typeof sticker.data.tts.mouthImage.file === 'string') {
						// Stock mouth images just pass the file name.
						payload.tts.mouthImage = sticker.data.tts.mouthImage.file;

						// Blobs should only be set for user-defined mouth images.
						payload.tts.mouthBlob = null;
					} else if (
						sticker.data.tts.mouthImage.file &&
						typeof sticker.data.tts.mouthImage.file === 'object'
					) {
						// If custom mouth image then following will be null.
						payload.tts.mouthImage = null;
					}
				}

				// Closed Mouth Image for Edit
				if (payload.id) {
					// If closed mouth is custom and selected mouth is a preset then set closed mouth blob null
					if (!payload.tts.closedMouthBlob) {
						payload.tts.closedMouthImage = null;
					}

					if (
						payload.tts.closedMouthImage &&
						payload.tts.closedMouthImage.startsWith(customPath) &&
						payload.tts.mouthImage &&
						typeof payload.tts.mouthImage === 'string' &&
						mouthFiles.includes(payload.tts.mouthImage)
					) {
						payload.tts.closedMouthBlob = null;
						payload.tts.closedMouthImage = null;
					}
				}

				// There is no audio uploaded with the sticker.data. Audio text is defined by the viewer in the panel.
				delete payload.tts.audioBlob;

				// Reset the closed mouth blob as it may have a file path for some reason.
				if (payload.tts.closedMouthBlob && !payload.tts.closedMouthBlob.startsWith('data:')) {
					payload.tts.closedMouthBlob = null;
				}

				// Handle alert audio.
				// If Audio file is an object
				if (sticker.data.tts.alertSound && typeof sticker.data.tts.alertSound === 'object') {
					delete payload.tts.alertSound;
					payload.tts.alertSoundBlob = await fileToBase64(sticker.data.tts.alertSound);
				}

				// ---------------------------------------
				// TODO End fixing upload payload temporarily.
				// ---------------------------------------

				// Pass the copied object named as payload in order to avoid the over-write if user decides to go on previous steps it will disturb the object.

				saveLoading.value = true;

				if (payload.id) {
					// Edit Sticker
					axios
						.put(`/custom-sticker/${props.stickerType}`, payload)
						.then(() => {
							ctx.emit('onUploaded', true);
						})
						.catch(error => {
							isFileError.value = true;
							fileErrorMessage.value = error;
						})
						.finally(() => {
							saveLoading.value = false;
						});
				} else {
					// Create Sticker
					axios
						.post(`/custom-sticker/${props.stickerType}`, payload)
						.then(() => {
							ctx.emit('onUploaded', true);

							saveEvent({
								event: 'sticker_upload_complete',
								params: {
									event_category: 'engagement',
									event_label: 'User Completes the Sticker Upload Process (Sticker Saved)',
									item_category: props.stickerType,
									voice_service: voiceService,
									ai_voice_type: sticker.data.tts.voiceType === VoiceType.AI ? ownership : null,
								},
							});
						})
						.catch(error => {
							isFileError.value = true;
							fileErrorMessage.value = error;
						})
						.finally(() => {
							saveLoading.value = false;
						});
				}
			},

			closeWizard: function () {
				if (isPlaying.value && player) {
					player.pause();
					player = null;
					isPlaying.value = false;
				}

				ctx.emit('onClose');
			},
		};

		return {
			stepItems,
			preMadeSticker,

			eyeImages,
			mouthImages,
			customEye,

			customAnimatedMouth,
			customClosedMouth,

			isFileError,
			fileErrorMessage,

			previous,
			actions,
			saveLoading,
			nameFromStickerType,
			sticker,
			step,
			isPlaying,
			icons: {
				closeIcon: mdiClose,
			},
		};
	},

	methods: {},
};
</script>

<style lang="scss" scoped>
.sticker-upload-stepper {
	max-height: 100px;
	overflow-y: auto;

	@media screen and (min-height: 300px) {
		max-height: 200px;
	}

	@media screen and (min-height: 466px) {
		max-height: 350px;
	}

	@media screen and (min-height: 600px) {
		max-height: 480px;
	}

	@media screen and (min-height: 730px) {
		max-height: 630px;
	}

	@media screen and (min-height: 800px) {
		max-height: 90%;
	}
}
</style>
