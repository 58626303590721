<template>
	<v-card>
		<v-snackbar v-model="isFileError" color="error" :timeout="2500" top>
			{{ fileErrorMessage }}
		</v-snackbar>
		<v-card-text class="pt-0">
			<div class="step-section">
				<h4 class="text-center textColor--text fs--17 fw--500">Add audio for your sticker:</h4>

				<div v-if="!sticker.audioBlob" class="upload-area px-3 px-sm-0 text-center mt-4">
					<v-icon color="boldPurple" size="120">{{ icons.uploadIcon }}</v-icon>

					<div class="text-center mt-4">
						<v-btn @click="actions.pickFile" color="pink" min-height="46" class="px-15" depressed>
							Upload File
						</v-btn>
						<input
							type="file"
							accept="audio/mpeg,audio/wav,audio/x-wav"
							class="d-none"
							@change="actions.onFilePicked"
							ref="audioInput" />

						<p class="fs--13 fw--500 mb-0 mt-5 shinyPurple--text">
							Hint: if you prefer to skip this step, we will apply a default "sticker slap" sound.
						</p>
					</div>
				</div>
				<div v-else class="upload-area px-3 px-sm-0 text-center mt-4">
					<div class="d-flex align-center justify-center mb-2">
						<v-icon size="18" class="mr-1" color="boldPurple">{{ icons.checkIcon }}</v-icon>
						<small class="shinyPurple--text"> File Uploaded </small>
					</div>

					<v-btn
						@click="actions.toggleAudio"
						color="secondary"
						:width="$vuetify.breakpoint.xsOnly ? 'auto' : '220'"
						min-height="66"
						depressed>
						<v-icon size="24" class="mr-1">{{ icons.volumeIcon }}</v-icon>
						{{ isPlaying ? 'Stop' : 'Preview' }} Audio
					</v-btn>

					<div class="text-center mt-4">
						<v-btn
							@click="actions.pickFile"
							color="pink"
							outlined
							:width="$vuetify.breakpoint.xsOnly ? 'auto' : '220'"
							min-height="46"
							depressed>
							Change File
						</v-btn>
						<input
							type="file"
							accept="audio/mpeg,audio/wav,audio/x-wav"
							class="d-none"
							@change="actions.onFilePicked"
							ref="audioInput" />

						<p class="fs--13 fw--500 mb-0 mt-5 shinyPurple--text">
							Hint: if you prefer to skip this step, we will apply a default "sticker slap" sound.
						</p>
					</div>
				</div>
				<p class="fs--12 mt-5 mb-5 fw--500 shinyPurple--text text-center">
					Upload your sticker sound effect as either a WAV or MP3 file.
				</p>
				<p class="fs--12 mt-5 mb-5 fw--500 shinyPurple--text text-center">Max file size is 1.5MB.</p>
			</div>
		</v-card-text>
		<div class="footer-section d-flex flex-wrap justify-center px-3 px-sm-0">
			<v-btn class="mt-3 my-sm-6 px-12 mr-2" depressed @click="actions.moveBack" outlined color="pink"
				>Back</v-btn
			>
			<v-btn class="mt-3 my-sm-6 px-12" depressed @click="actions.moveNext" color="pink">Next</v-btn>
		</div>
	</v-card>
</template>

<script>
import { ref } from '@vue/composition-api';
import { onAudioPicked } from '../../../../utils/sticker.js';
import { mdiCloudUploadOutline, mdiVolumeHigh, mdiCheckCircle } from '@mdi/js';
export default {
	props: {
		sticker: {
			type: Object,
			required: true,
		},
		isPlaying: {
			type: Boolean,
			default: false,
		},
	},
	emits: ['onMove', 'onToggleAudio'],
	setup(props, ctx) {
		const isFileError = ref(false);
		const fileErrorMessage = ref(null);
		const audioInput = ref(null);

		const actions = {
			moveNext: function () {
				ctx.emit('onMove', 3);
			},
			moveBack: function () {
				ctx.emit('onMove', 1);
			},
			pickFile: function () {
				audioInput.value.click();
			},
			onFilePicked: function (e) {
				onAudioPicked(e, props.sticker, null, function (error) {
					fileErrorMessage.value = error;
					isFileError.value = true;
					console.error('error in handler: ' + error);
				});
			},
			toggleAudio: function () {
				ctx.emit('onToggleAudio');
			},
		};

		return {
			actions,
			audioInput,
			isFileError,
			fileErrorMessage,
			icons: {
				uploadIcon: mdiCloudUploadOutline,
				volumeIcon: mdiVolumeHigh,
				checkIcon: mdiCheckCircle,
			},
		};
	},
};
</script>
