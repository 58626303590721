<template>
	<v-card
		min-height="457"
		height="100%"
		class="card-border sticker-tts global-tts"
		:class="sticker.tts.isBonusTtsShareSlot && 'shared-slot'"
		:key="enabledKey">
		<v-card-text v-if="sticker">
			<template v-if="sticker.isSlotConfigured">
				<v-row justify="center" align="center" class="sticker-header mb-0 mb-lg-3 pt-5">
					<v-col cols="9" class="mb-3">
						<h4 class="fs--18 fw--300 text-center">
							{{ sticker.name }}
						</h4>
					</v-col>
				</v-row>
				<div class="text-center sticker-image">
					<div v-if="!sticker.enabled" :class="!sticker.enabled && 'disabled-image'"></div>
					<AssetsTTS :item="sticker" size="50" :isAnimatedMouthRequired="false" :isGlobal="true" />
				</div>
				<div class="d-flex justify-space-between align-center my-2">
					<span class="fs--14 text-md fw--500">Enable:</span>
					<v-switch v-model="stickerEnabled" class="ma-0" hide-details small color="pink"></v-switch>
				</div>
				<div
					v-if="sticker.tts.displayMode === 'STICKER'"
					class="d-flex justify-space-between align-center mb-1">
					<span class="fs--14 text-md fw--500">Custom Placement:</span>
					<v-switch
						v-model="sticker.isStickerPlacementEnabled"
						class="ma-0"
						hide-details
						small
						color="pink"></v-switch>
				</div>

				<v-row align="center" no-gutters>
					<v-col cols="5" md="5" xl="4">
						<span class="fs--14 text-md fw--500" :class="!sticker.enabled && 'disabledForm--text'">
							Stickiness:
						</span>
					</v-col>
					<v-col cols="7" md="7" xl="8" class="text-right">
						<v-slider
							v-model="stickiness"
							@start="actions.onRangeDataStart"
							@end="actions.onRangeDataEnd"
							color="pink"
							:min="10"
							:max="45"
							:disabled="!sticker.enabled"
							:thumb-color="sticker.enabled ? 'pinkItem' : 'disabledForm'"
							thumb-label
							:track-fill-color="sticker.enabled ? 'pinkItem' : 'disabledForm'"
							hide-details>
							<template #thumb-label>
								<span class="px-2">{{ stickiness }}</span>
							</template>
						</v-slider>
					</v-col>
					<v-col cols="5" md="5" xl="4">
						<span class="fs--14 text-md fw--500" :class="!sticker.enabled && 'disabledForm--text'">
							Volume:
						</span>
					</v-col>
					<v-col cols="7" md="7" xl="8" class="text-right">
						<v-slider
							v-model="sticker.volume"
							@start="actions.onRangeDataStart"
							@end="actions.onRangeDataEnd"
							color="pink"
							:min="0"
							:max="100"
							:disabled="!sticker.enabled"
							:thumb-color="sticker.enabled ? 'pinkItem' : 'disabledForm'"
							thumb-label
							:track-fill-color="sticker.enabled ? 'pinkItem' : 'disabledForm'"
							hide-details>
							<template #thumb-label>
								<span class="px-2">{{ sticker.volume }}%</span>
							</template>
						</v-slider>
					</v-col>
				</v-row>
				<v-row align="center" no-gutters class="mt-2">
					<v-col cols="4" md="4">
						<span class="fs--14 text-md fw--500" :class="!sticker.enabled && 'disabledForm--text'">
							Bits:
						</span>
					</v-col>
					<v-col cols="8" md="8" class="text-right">
						<BitsDropdown
							:sku="sticker.sku"
							:tts="true"
							:isGlobalTts="true"
							:voiceId="sticker.tts.voiceId"
							:disabled="!sticker.enabled"
							@onChange="actions.setSku"></BitsDropdown>
					</v-col>
				</v-row>
			</template>
		</v-card-text>

		<div class="card-footer">
			<v-row no-gutters justify="center" class="mt-4">
				<v-col cols="12">
					<v-btn
						@click="actions.previewSticker"
						:disabled="!isExtensionOnline || !sticker.enabled"
						depressed
						color="pink"
						block>
						<span>Preview</span>
					</v-btn>
				</v-col>
			</v-row>
		</div>

		<GenericPopup v-if="errorDialog.show" :modal="errorDialog.show" @close="errorDialog.show = false">
			<template #primaryHeader>{{ errorDialog.primaryHeader }}</template>
			<template>
				<p class="fs--13 fw--400 fieldFore--text">
					{{ errorDialog.copy }}
				</p>
			</template>
		</GenericPopup>
	</v-card>
</template>

<script>
import axios from '@axios';
import { computed, reactive, ref, onMounted, onBeforeUnmount } from '@vue/composition-api';
import AssetsTTS from '../AssetsTTS.vue';
import BitsDropdown from '@/views/shared/BitsDropdown.vue';
import GenericPopup from '@/components/GenericPopup.vue';
import MusicPlayer from '@/views/streamer/tts-voice-lab/MusicPlayer.vue';
import { isBitsEnabled } from '@/utils/auth';
import { StickerType } from '@/utils/constants.js';
import { eyePresets, mouthPresets } from '@/utils/utils.js';
import { rangeValueConverter } from '@/utils/sticker.js';

export default {
	name: 'GlobalTTS',
	props: {
		sticker: {
			type: Object,
			default: null,
		},
		stickerType: {
			type: String,
			required: true,
		},
		isExtensionOnline: {
			type: Boolean,
			default: false,
		},
	},
	components: {
		AssetsTTS,
		BitsDropdown,
		MusicPlayer,
		GenericPopup,
	},
	emits: ['onRangeDataStart', 'onRangeDataEnd', 'onViewAllTags', 'onNoBitsLockedFeature'],
	setup(props, ctx) {
		const eyeImages = reactive({ items: [] });
		const mouthImages = reactive({ items: [] });

		let audioPlayer = null;

		// A slider value is being changed, so do not recalc things like the audio preview URL.
		const isInDataRangeChange = ref(false);

		onMounted(() => {
			eyeImages.items = [...eyePresets];
			mouthImages.items = [...mouthPresets];
		});

		onBeforeUnmount(() => {
			if (audioPlayer) {
				audioPlayer.pause();
				audioPlayer = null;
			}
		});

		const errorDialog = reactive({
			show: false,
			primaryHeader: 'Error',
			copy: 'An error occurred.',
		});

		// Used to reload the card if the enabled state change is reversed.
		const enabledKey = ref(props.sticker.id + '_' + new Date().getTime());

		// Ensure that non-affiliates cannot enable Global TTS stickers.
		const stickerEnabled = computed({
			get() {
				return props.sticker.enabled;
			},
			set(newValue) {
				if (!isBitsEnabled.value && newValue) {
					ctx.emit('onNoBitsLockedFeature');
					enabledKey.value = props.sticker.id + '_' + new Date().getTime();
				} else {
					props.sticker.enabled = newValue;
				}
			},
		});

		// Calculated UI value for stickiness.
		const stickiness = computed({
			get() {
				return rangeValueConverter.toUiValue(props.sticker.stickiness, 10, 45, 100);
			},
			set(newValue) {
				props.sticker.stickiness = rangeValueConverter.toDbValue(newValue, 10, 45, 100);
			},
		});

		const actions = {
			viewAllTags() {
				ctx.emit('onViewAllTags', props.sticker);
			},
			// Called after the # of bits is selected.
			setSku: function (item) {
				props.sticker.sku = item.value;
			},
			previewSticker: function () {
				let url;
				if (props.stickerType == StickerType.GLOBAL || props.stickerType == StickerType.GLOBAL_AI_TTS) {
					url = `/global-sticker/preview/${props.sticker.uuid}`;
				} else {
					url = `/custom-sticker/preview/${props.sticker.uuid}`;
				}
				axios.post(url).catch(error => console.log(error));
			},
			// A slider has started to change. We check this to disable the watch on
			// data changes until the final change is complete to avoid many server calls.
			onRangeDataStart: function () {
				isInDataRangeChange.value = true;
				ctx.emit('onRangeDataStart', props.sticker);
			},
			onRangeDataEnd: function () {
				isInDataRangeChange.value = false;
				ctx.emit('onRangeDataEnd', props.sticker);
			},
		};

		return {
			actions,
			errorDialog,

			enabledKey,
			stickerEnabled,

			stickiness,

			isInDataRangeChange,
		};
	},
};
</script>

<style lang="scss" scoped>
.card-border {
	border: 1px solid transparent;
}

.card-border:hover {
	border: 1px solid #4a93d9;
}

.sticker-header {
	position: relative;
	height: 70px;
	z-index: 0;
}

.global-sticker-header {
	position: relative;
	height: 60px;
	z-index: 0;
}

.sticker-image {
	position: relative;
	z-index: 0;
}

.disabled-image {
	position: absolute;
	width: 100%;
	max-width: 350px;
	height: 150px;
	top: 0;
	left: 0;
	background: var(--v-cardBg-base) !important;
	opacity: 0.8;
	z-index: 9;
}

@media screen and (min-width: 1264px) and (max-width: 1340px) {
	.text-xs {
		font-size: 11px !important;
	}

	.text-md {
		font-size: 13px !important;
	}
}
</style>
