<template>
	<v-card>
		<v-snackbar v-model="isFileError" color="error" :timeout="2500" top>
			{{ fileErrorMessage }}
		</v-snackbar>
		<v-card-text class="pt-0">
			<div class="step-section">
				<h4 class="text-center textColor--text fs--17 fw--500">
					Upload your base sticker image or animated GIF.
				</h4>

				<div class="upload-area px-3 px-sm-0 text-center mt-4">
					<v-row justify="center" no-gutters>
						<v-col cols="12" class="text-center">
							<h4 class="text-center textColor--text fs--18 fw--400 mb-2">Upload Custom</h4>
							<template v-if="!imageSource">
								<v-icon color="boldPurple" size="120">{{ icons.uploadIcon }}</v-icon>
								<div class="text-center mt-4">
									<v-btn
										@click="actions.pickFile"
										color="pink"
										:width="$vuetify.breakpoint.xsOnly ? 'auto' : '220'"
										min-height="46"
										depressed>
										Select File
									</v-btn>
									<input
										type="file"
										accept="image/x-png, image/gif"
										class="d-none"
										@change="actions.onImagePick"
										ref="imageInput" />
								</div>
							</template>
							<template v-else>
								<div class="base-image" :style="{ 'background-image': `url(${imageSource})` }"></div>

								<div class="text-center mt-4">
									<v-btn
										@click="actions.pickFile"
										color="pink"
										outlined
										:width="$vuetify.breakpoint.xsOnly ? 'auto' : '220'"
										depressed>
										Change File
									</v-btn>
									<input
										type="file"
										accept="image/*"
										class="d-none"
										@change="actions.onImagePick"
										ref="imageInput" />
								</div>
							</template>

							<p class="fs--13 fw--500 mb-0 mt-5 shinyPurple--text text-center">
								Hint: Upload either a .PNG or .GIF file as the base sticker image.
							</p>
						</v-col>
						<!-- <v-col cols="12" md="1" class="d-flex flex-column align-center justify-center">
							<img class="mb-5 mt-5 mt-md-0" src="@/assets/images/-OR-.png" alt="OR" />
							<v-divider v-if="$vuetify.breakpoint.smAndDown" class="secondary"></v-divider>
							<v-divider v-else vertical class="secondary"></v-divider>
						</v-col>
						<v-col cols="12" md="5">
							<h4 class="text-center textColor--text fs--18 fw--400 mb-2">Start with Premade</h4>
							<div class="premade-selection">
								<v-carousel v-model="cItem" class="sticker-carousel pt-3" hide-delimiters>
									<v-carousel-item
										v-for="(item, i) in stickerList"
										:key="i"
										reverse-transition="fade-transition"
										transition="fade-transition">
										<template #default>
											<div class="sticker-section" @click="actions.selectSticker(item)">
												<AssetsTTS :item="item" size="40" class="cursor-pointer" />
												<div class="text-center image-name fieldFore--text mt-2">
													{{ item.name.substr(0, 12)
													}}{{ (item.name.length > 12 && '...') || '' }}
												</div>
											</div>
										</template>
									</v-carousel-item>

									<template v-slot:prev="{ on, attrs }">
										<v-btn
											class="secondary px-2 py-0"
											min-width="20"
											height="20"
											depressed
											v-bind="attrs"
											v-on="on">
											<v-icon>{{ icons.arrowLeft }}</v-icon>
										</v-btn>
									</template>

									<template v-slot:next="{ on, attrs }">
										<v-btn
											class="secondary px-2 py-0"
											min-width="20"
											height="20"
											depressed
											v-bind="attrs"
											v-on="on">
											<v-icon>{{ icons.arrowRight }}</v-icon>
										</v-btn>
									</template>
								</v-carousel>
								<v-btn
									color="pink"
									depressed
									min-height="46"
									:disabled="!selectedSticker"
									@click="actions.selectPreMade()"
									>Start with premade</v-btn
								>
								<v-icon
									v-if="selectedSticker"
									color="red"
									class="ml-2"
									size="32"
									@click="actions.selectSticker()"
									>{{ icons.delete }}</v-icon
								>
							</div>
						</v-col> -->
					</v-row>
				</div>
				<p class="fs--12 fw--500 mt-5 shinyPurple--text text-center">
					TTS stickers will render on screen at the resolution they are uploaded. For optimal visibility, we
					strongly recommend uploading assets between 350x350 and 650x650 pixels.
				</p>
				<p class="fs--12 fw--500 mb-0 shinyPurple--text text-center">
					Individual assets such as the base, eyes, mouth, etc, must be no larger than 2 MB per asset.
				</p>
			</div>
		</v-card-text>

		<div class="footer-section text-center" :class="!imageSource && 'py-9'">
			<v-btn v-if="imageSource" class="my-6 px-12" depressed @click="actions.moveNext" color="pink">Next</v-btn>
		</div>
	</v-card>
</template>

<script>
import { computed, ref, reactive } from '@vue/composition-api';
import AssetsTTS from '../AssetsTTS.vue';
import { onImagePicked, createObjectUrlFromBlob } from '../../../../utils/sticker.js';
import { mdiCloudUploadOutline, mdiChevronLeft, mdiChevronRight, mdiDelete } from '@mdi/js';
import { getCustomStickerAssetPath } from '@/utils/config.js';
import { getUserId } from '@/utils/auth';
import premades from '@/utils/tts-premades';
import store from '@/store';
import 'vue-draggable-resizable/dist/VueDraggableResizable.css';
export default {
	props: {
		sticker: {
			type: Object,
			required: true,
		},
		edit: {
			type: Boolean,
			required: false,
		},
	},
	components: {
		AssetsTTS,
	},
	emits: ['onMove', 'setStickerBase', 'onPreMadeSelection'],
	setup(props, ctx) {
		const isFileError = ref(false);
		const fileErrorMessage = ref(null);
		const imageInput = ref(null);

		const cItem = ref(0);
		const selectedSticker = ref(null);

		const stickerList = computed(() => premades);

		const imageSource = computed(() => {
			if (props.sticker.stickerBlob) {
				return props.sticker.stickerBlob;
				// return URL.createObjectURL(props.sticker.stickerBlob)
				// return createObjectUrlFromBlob(props.sticker.baseImage);
			} else if (props.edit && props.sticker.tts.baseImage && !props.sticker.stickerBlob) {
				const baseImg = props.sticker.tts.baseImage.split('tts/')[1];
				return `${actions.getImagePath()}/tts/${baseImg}`;
			} else {
				return null;
			}
		});

		// onMounted(() => {
		// 	if (props.edit) {
		// 		`${actions.getImagePath()}/${props.sticker.staticView}`;
		// 	}
		// })

		const actions = {
			selectSticker(item) {
				if (item) {
					selectedSticker.value = JSON.parse(JSON.stringify(item));
				} else {
					selectedSticker.value = null;
				}
			},
			// selectPreMade() {
			// 	if (selectedSticker.value) {
			// 		ctx.emit('onPreMadeSelection', selectedSticker.value);
			// 	}
			// },
			getImagePath: function () {
				return `${getCustomStickerAssetPath(getUserId())}`;
			},
			moveNext: function () {
				ctx.emit('onMove', 2);
			},
			pickFile: function () {
				imageInput.value.click();
			},
			onImagePick: function (e) {
				const file = e.target.files[0];
				if (file) {
					// Get base64 of picked image
					const reader = new FileReader();
					reader.readAsDataURL(file);

					reader.onload = function () {
						ctx.emit('baseAdded', reader.result);
					};
				} else {
					ctx.emit('baseAdded', null);
				}
				// onImagePicked(e, props.sticker, null, function (error) {
				// 	fileErrorMessage.value = error;
				// 	isFileError.value = true;
				// });
			},
		};

		return {
			cItem,
			stickerList,
			selectedSticker,

			actions,
			imageSource,
			imageInput,
			isFileError,
			fileErrorMessage,

			icons: {
				uploadIcon: mdiCloudUploadOutline,
				arrowRight: mdiChevronRight,
				arrowLeft: mdiChevronLeft,
				delete: mdiDelete,
			},
		};
	},

	methods: {},
};
</script>

<style scoped>
.base-image {
	width: 110px;
	height: 140px;
	margin: 0 auto;
	background-position: center center;
	background-size: contain;
	background-repeat: no-repeat;
}

.premade-selection {
	height: 250px;
}

.sticker-carousel {
	height: 185px !important;
}

.sticker-section {
	width: 62%;
	margin: auto;
}

.v-divider--vertical {
	align-self: center !important;
	min-height: 80% !important;
	max-height: 80% !important;
}
</style>
