<template>
	<div>
		<GenericPopup
			v-if="passwordDialog"
			:closeButton="false"
			:persistent="true"
			:modal="passwordDialog"
			@close="closePopup">
			<template #primaryHeader>TTS Beta Access</template>
			<template #secondaryHeader
				>Please enter your TTS Beta Password. <br />
				Want to sign up? Join our
				<a href="https://discord.gg/zHgBVEp" class="pink--text" target="_blank">Discord!</a></template
			>
			<template #headerImage><img src="@/assets/images/setting.png" width="200" alt="" /></template>
			<template>
				<v-form @submit.prevent="submitPassword" lazy-validation>
					<v-text-field
						type="password"
						outlined
						dense
						v-model="password"
						:rules="[v => !!v || 'Password is Required']"
						placeholder="Enter TTS Beta Password"></v-text-field>

					<div v-if="error" class="red--text fs--14">{{ error }}</div>

					<v-btn
						type="submit"
						color="pink"
						class="px-15 mt-3"
						:disabled="!password"
						:loading="loading"
						depressed
						large
						>Submit</v-btn
					>
				</v-form>
			</template>
		</GenericPopup>
	</div>
</template>

<script>
import { ref, computed } from '@vue/composition-api';
import axios from '@axios';
import { mdiClose } from '@mdi/js';
import GenericPopup from '@/components/GenericPopup.vue';
export default {
	components: {
		GenericPopup,
	},
	props: {
		closeButtonCopy: {
			type: String,
			default: 'Close',
		},
	},
	setup(props, { emit }) {
		const passwordDialog = ref(true);
		const password = ref('');

		const error = ref('');

		const loading = ref(false);

		const showPopup = computed({
			get() {
				return props.modal;
			},
			set(newValue) {
				if (!newValue) {
					emit('close', true);
				}
			},
		});

		const submitPassword = () => {
			loading.value = true;

			axios
				.post('/custom-sticker/tts/beta-password/' + password.value)
				.then(() => {
					emit('success');
				})
				.catch(error => {
					error.value = 'Incorrect Password';
				}).finally(() => {
					loading.value = false;
				});
		};

		const closePopup = () => {
			emit('close', true);
		};

		return {
			passwordDialog,
			password,
			loading,
			error,
			closePopup,
			submitPassword,
			showPopup,
			closeIcon: mdiClose,
		};
	},
};
</script>

<style lang="scss" scoped>
.popup-header {
	position: relative;
	padding: 12px 0;
	background: url('../../../assets/images/backgrounds/sticker-upload-header.png');
	background-repeat: no-repeat;
	background-size: fill;
}

.close-icon {
	position: absolute;
	top: 15px;
	right: 15px;
}

.content-section {
	width: 90%;
	margin: auto;
}
</style>
