<template>
	<v-card
		min-height="457"
		height="100%"
		class="card-border sticker-tts"
		:class="sticker.tts.isBonusTtsShareSlot && 'shared-slot'">
		<v-snackbar v-model="copied" :timeout="2000" top color="purple">Link Copied Successfully.</v-snackbar>
		<v-card-text v-if="sticker">
			<template v-if="sticker.isSlotConfigured">
				<!-- TTS STICKER VIEW -->
				<v-row justify="center" align="center" class="sticker-header mb-0 mb-lg-3 pt-5">
					<v-col @mouseleave="editToggles.showNameEdit = false" cols="9" class="mb-3">
						<h4
							@mouseenter="editToggles.showNameEdit = true"
							v-if="!editToggles.showNameInput"
							class="fs--18 fw--300 text-center">
							{{ sticker.name }}
						</h4>

						<v-icon
							@click="actions.showInput"
							v-show="editToggles.showNameEdit && !editToggles.showNameInput"
							size="22"
							color="pink"
							class="name-edit-icon">
							{{ icons.pencilIcon }}
						</v-icon>

						<v-text-field
							v-if="editToggles.showNameInput"
							placeholder="Edit Sticker Name"
							v-model="stickerName"
							max
							:append-icon="icons.check"
							@click:append="actions.setStickerName"
							@keyup.enter="actions.setStickerName"
							@keyup.esc="actions.cancelStickerName"
							style="margin-top: -17px"
							v-click-outside="actions.cancelStickerName"
							:rules="stickerNameRules"
							color="pink"></v-text-field>
					</v-col>
					<!-- <v-icon size="36" color="grey" class="drag-icon">
						{{ icons.drag }}
					</v-icon> -->
				</v-row>
				<!-- TTS STICKER VIEW -->
				<div class="text-center sticker-image">
					<div v-if="!sticker.enabled" :class="!sticker.enabled && 'disabled-image'"></div>

					<AssetsTTS :item="sticker" size="50" :isAnimatedMouthRequired="false" />

					<v-btn @click="actions.editSticker" x-small color="pink" class="px-4 py-1 mt-3" depressed>
						Edit
					</v-btn>

					<input
						type="file"
						accept="image/png,image/gif"
						style="display: none"
						@change="actions.onImagePick"
						ref="imageInput" />
				</div>
				<div class="d-flex justify-space-between align-center my-2">
					<span class="fs--14 text-md fw--500">Enable:</span>
					<v-switch v-model="sticker.enabled" class="ma-0" hide-details small color="pink"></v-switch>
				</div>
				<div
					v-if="sticker.tts.displayMode === 'STICKER'"
					class="d-flex justify-space-between align-center mb-1">
					<span class="fs--14 text-md fw--500">Custom Placement:</span>
					<v-switch
						v-model="sticker.isStickerPlacementEnabled"
						class="ma-0"
						hide-details
						small
						color="pink"></v-switch>
				</div>

				<!-- Not bonus share slot. -->
				<template v-if="!sticker.tts.isBonusTtsShareSlot">
					<v-radio-group v-model="stickerVoiceType" hide-details>
						<v-radio value="ai" color="pink" :disabled="!sticker.enabled" class="mb-0" hide-details>
							<template #label>
								<div class="d-flex align-center justify-space-between" style="width: 100%">
									<span
										class="white--text fs--14 fw--600"
										:class="!sticker.enabled && 'disabledForm--text'">
										A.I Voice
									</span>
								</div>
							</template>
						</v-radio>
						<v-row no-gutters align="start">
							<v-col cols="12" class="mt-1">
								<v-autocomplete
									hide-details
									v-model="sticker.tts.voiceId"
									item-text="name"
									item-value="id"
									:items="aiVoices"
									:disabled="stickerVoiceType !== VoiceType.AI || !sticker.enabled"
									dense
									flat
									solo
									background-color="secondary"
									label="Voice"
									@change="actions.onVoiceChange()"
									style="max-width: 100%">
									<template v-slot:selection="{ item }">
										<span class="fs--14 white--text fw--500">{{ item.name }}</span>
									</template>
									<template v-slot:item="{ item }">
										<img :src="actions.getAiIcon(item)" />
										<span class="fs--14 white--text fw--500 ml-2">{{ item.name }}</span>
									</template>
								</v-autocomplete>
							</v-col>
						</v-row>
						<div class="d-flex flex-wrap align-center mb-3 ml-7"></div>

						<v-radio value="standard" color="pink" :disabled="!sticker.enabled">
							<template #label>
								<span
									class="white--text fs--14 fw--600"
									:class="!sticker.enabled && 'disabledForm--text'">
									Standard Voice
								</span>
							</template>
						</v-radio>

						<v-row no-gutters class="mb-3">
							<v-col cols="6" class="pr-1">
								<v-autocomplete
									hide-details
									v-model="sticker.tts.language"
									item-text="name"
									item-value="code"
									:items="languageList"
									:disabled="stickerVoiceType === VoiceType.AI || !sticker.enabled"
									dense
									flat
									solo
									background-color="secondary"
									label="Select Language"
									style="max-width: 100%"></v-autocomplete>
							</v-col>
							<v-col cols="6" class="pl-1">
								<v-autocomplete
									hide-details
									v-model="sticker.tts.voiceId"
									item-text="name"
									item-value="id"
									:items="standardVoices"
									:disabled="stickerVoiceType === VoiceType.AI || !sticker.enabled"
									dense
									flat
									solo
									background-color="secondary"
									label="Voice"
									@change="actions.onVoiceChange()"
									class="ml-2"
									style="max-width: 100%" />
							</v-col>
						</v-row>
					</v-radio-group>
				</template>

				<!-- Bonus sticker slot for shared voice. -->
				<template v-else>
					<span class="fs--14 text-md fw--500" :class="!sticker.enabled && 'disabledForm--text'">
						A.I. Shared Voice from:
					</span>
					<div class="d-flex align-center mt-1">
						<!-- Set Icon Dynamically based on the Voice -->
						<img src="@/assets/icons/AIShared.png" class="mr-1" />
						<span
							class="fs--15 fw--500 text-center mb-3 mt-2"
							:class="!sticker.enabled && 'disabledForm--text'">
							{{ bonusSharedVoice.name }}
						</span>
					</div>
					<div class="mt-2 mb-3 d-flex align-center">
						<div class="bg-white circle pa-1 mr-5">
							<v-avatar size="30">
								<img
									:src="bonusProfilePic"
									@error="$event.target.src = `${actions.defaultProfilePic()}`" />
							</v-avatar>
						</div>
						<div class="full-width">
							<MusicPlayer
								v-if="!isInDataRangeChange"
								:audio="bonusAudioUrl"
								:id="'shared-voice-preview-' + sticker.id" />
						</div>
					</div>
				</template>

				<v-row align="center" no-gutters>
					<v-col cols="5" md="5" xl="4">
						<span class="fs--14 text-md fw--500" :class="!sticker.enabled && 'disabledForm--text'">
							Stickiness:
						</span>
					</v-col>
					<v-col cols="7" md="7" xl="8" class="text-right">
						<v-slider
							v-model="stickiness"
							@start="actions.onRangeDataStart"
							@end="actions.onRangeDataEnd"
							color="pink"
							:min="10"
							:max="45"
							:disabled="!sticker.enabled"
							:thumb-color="sticker.enabled ? 'pinkItem' : 'disabledForm'"
							thumb-label
							:track-fill-color="sticker.enabled ? 'pinkItem' : 'disabledForm'"
							hide-details>
							<template #thumb-label>
								<span class="px-2">{{ stickiness }}</span>
							</template>
						</v-slider>
					</v-col>
					<v-col cols="5" md="5" xl="4">
						<span class="fs--14 text-md fw--500" :class="!sticker.enabled && 'disabledForm--text'">
							Volume:
						</span>
					</v-col>
					<v-col cols="7" md="7" xl="8" class="text-right">
						<v-slider
							v-model="sticker.volume"
							@start="actions.onRangeDataStart"
							@end="actions.onRangeDataEnd"
							color="pink"
							:min="0"
							:max="100"
							:disabled="!sticker.enabled"
							:thumb-color="sticker.enabled ? 'pinkItem' : 'disabledForm'"
							thumb-label
							:track-fill-color="sticker.enabled ? 'pinkItem' : 'disabledForm'"
							hide-details>
							<template #thumb-label>
								<span class="px-2">{{ sticker.volume }}%</span>
							</template>
						</v-slider>
					</v-col>
				</v-row>
				<v-row no-gutters class="mt-1" align="center">
					<v-col cols="12" class="mb-3">
						<span class="fs--14 text-md fw--500" :class="!sticker.enabled && 'disabledForm--text'">
							Alert Sound:
						</span>
					</v-col>
					<template v-if="sticker.tts.alertSound || changedAlertUrl">
						<v-col cols="6" class="pr-1">
							<v-btn
								@click="actions.playAudio"
								:small="$vuetify.breakpoint.lgOnly"
								:large="$vuetify.breakpoint.xlOnly"
								color="secondary"
								:disabled="!sticker.enabled || preview || editLoading"
								depressed
								block
								class="grid-btn py-4 px-3">
								<v-icon>{{ icons.volume }}</v-icon>
								<span class="text-xs">Play</span>
							</v-btn>
						</v-col>
						<v-col cols="6" class="pl-1">
							<v-btn
								@click="actions.pickAlertFile"
								:small="$vuetify.breakpoint.lgOnly"
								depressed
								color="secondary"
								outlined
								:disabled="!sticker.enabled || editLoading"
								block
								class="grid-btn py-4 px-3">
								<v-icon>{{ icons.volume }}</v-icon>
								<span class="textColor--text text-xs">Replace</span>
							</v-btn>
						</v-col>

						<input
							type="file"
							id="replaceAlertInput"
							accept="audio/mpeg,audio/wav,audio/x-wav"
							class="d-none"
							style="display: none"
							@change="actions.onAlertPick"
							ref="alertInput" />
					</template>

					<template v-else>
						<v-col cols="12">
							<v-btn
								@click="actions.pickAlertFile"
								:small="$vuetify.breakpoint.lgOnly"
								:large="$vuetify.breakpoint.xlOnly"
								color="pink"
								:disabled="!sticker.enabled || preview || editLoading"
								depressed
								block
								class="grid-btn py-4 px-3">
								<v-icon>{{ icons.volume }}</v-icon>
								<span class="text-xs">Upload Alert</span>
							</v-btn>
						</v-col>

						<input
							type="file"
							accept="audio/mpeg,audio/wav,audio/x-wav"
							class="d-none"
							style="display: none"
							@change="actions.onAlertPick"
							ref="alertInput" />
					</template>
				</v-row>
				<v-row align="center" no-gutters class="mt-3">
					<v-col cols="4" md="4">
						<span class="fs--14 text-md fw--500" :class="!sticker.enabled && 'disabledForm--text'">
							Bits:
						</span>
					</v-col>
					<v-col cols="8" md="8" class="text-right">
						<BitsDropdown
							:sku="sticker.sku"
							:tts="true"
							:voiceId="sticker.tts.voiceId"
							:disabled="!sticker.enabled"
							@onChange="actions.setSku"></BitsDropdown>
					</v-col>
				</v-row>
				<div v-if="isBitsEnabled" class="d-flex justify-space-between align-center">
					<span class="fs--14 fw--500 mt-2">Sub-Only:</span>
					<v-switch
						v-model="sticker.subOnly"
						:disabled="!sticker.enabled"
						hide-details
						small
						color="pink"></v-switch>
				</div>
				<div v-if="!sticker.tts.isBonusTtsShareSlot" class="mt-2">
					<span class="fs--14 fw--500 mt-2">Display Mode:</span>
					<div class="d-flex justify-space-between align-center">
						<v-radio-group v-model="sticker.tts.displayMode" row class="ma-0" hide-details>
							<v-radio color="pink" label="Sticker" :value="TtsStickerDisplayMode.STICKER"></v-radio>
							<v-radio color="pink" label="Co-Host" :value="TtsStickerDisplayMode.CO_HOST"></v-radio>
						</v-radio-group>
						<v-tooltip top color="tooltipBg">
							<template v-slot:activator="{ on, attrs }">
								<div v-bind="attrs" v-on="on">
									<v-img width="40" src="@/assets/icons/info.png"></v-img>
								</div>
							</template>
							<strong class="offWhite--text">
								Selecting "Co-Host" mode will render the sticker in a separate browser source URL that
								can be shown on screen at all times.
							</strong>
						</v-tooltip>
					</div>
				</div>

				<div class="mt-2 mb-3">
					<div class="d-flex align-center justify-space-between">
						<span class="fs--14 text-md fw--500" :class="!sticker.enabled && 'disabledForm--text'">
							Search Terms ({{ sticker.searchTerms ? sticker.searchTerms.length : 0 }})
						</span>
						<v-btn color="secondary" depressed x-small @click="actions.viewAllTags">
							<v-icon size="18" class="mr-1">{{ icons.pencilIcon }}</v-icon>
							Edit
						</v-btn>
					</div>
				</div>

				<div v-if="!sticker.tts.isBonusTtsShareSlot" class="d-flex mt-2">
					<v-text-field
						v-model="browserSourceLink"
						solo
						hide-details
						dense
						flat
						background-color="purple"
						color="fieldFore"
						:class="sticker.tts.displayMode === 'STICKER' && 'blury-link'"
						disabled></v-text-field>
					<v-btn
						color="secondary"
						depressed
						:disabled="sticker.tts.displayMode === 'STICKER'"
						class="ml-1"
						@click="actions.copy()">
						Copy
					</v-btn>
				</div>
			</template>
		</v-card-text>

		<div class="card-footer">
			<v-row no-gutters justify="center" class="mt-4">
				<v-col cols="10" md="9" class="pr-1">
					<v-btn
						@click="actions.previewSticker"
						:disabled="!isExtensionOnline || !sticker.enabled || !isCoHostBrowserSourceActive"
						depressed
						color="pink"
						block>
						<span>Preview</span>
					</v-btn>
				</v-col>
				<v-col :cols="2" :md="3" class="pl-1 text-right">
					<v-btn
						@click="actions.onDeleteSticker(sticker.uuid)"
						color="secondary"
						depressed
						class="white--text px-0"
						style="min-width: 50px"
						:disabled="!sticker.enabled">
						<v-icon>{{ icons.deleteIcon }}</v-icon>
					</v-btn>
				</v-col>
			</v-row>
		</div>

		<GenericPopup v-if="errorDialog.show" :modal="errorDialog.show" @close="errorDialog.show = false">
			<template #primaryHeader>{{ errorDialog.primaryHeader }}</template>
			<template>
				<p class="fs--13 fw--400 fieldFore--text">
					{{ errorDialog.copy }}
				</p>
			</template>
		</GenericPopup>
	</v-card>
</template>

<script>
import axios from '@axios';
import store from '@/store';
import { computed, reactive, ref, onMounted, onBeforeUnmount, watch } from '@vue/composition-api';
import { StickerType, TtsStickerDisplayMode, VoiceLabOwnership, VoiceType } from '@/utils/constants';
import { getUserId, isBitsEnabled } from '@/utils/auth';
import { eyePresets, mouthPresets, LANGUAGES } from '@/utils/utils';
import { getAudioPreviewUrl, elSupportedLangsNonEnglish, formatSupportedLangs } from '@/utils/tts-utils';
import {
	getGlobalStickerAssetPath,
	getCustomStickerAssetPath,
	getEndpointHost,
	getHostedAssetPath,
} from '@/utils/config.js';
import {
	onImagePicked,
	onAudioPicked,
	onAlertPicked,
	createObjectUrlFromBlob,
	parseBits,
	rangeValueConverter,
} from '@/utils/sticker.js';
import AssetsTTS from '../AssetsTTS.vue';
import BitsDropdown from '@/views/shared/BitsDropdown.vue';
import GenericPopup from '@/components/GenericPopup';
import MusicPlayer from '@/views/streamer/tts-voice-lab/MusicPlayer.vue';
import {
	mdiChevronDown,
	mdiDelete,
	mdiDrag,
	mdiLock,
	mdiPlus,
	mdiVolumeLow,
	mdiPencil,
	mdiClose,
	mdiCheck,
	mdiVolumeMute,
} from '@mdi/js';

export default {
	props: {
		sticker: {
			type: Object,
			default: null,
		},
		stickerSlot: {
			type: Number,
		},
		stickerType: {
			type: String,
			required: true,
		},
		isExtensionOnline: {
			type: Boolean,
			default: false,
		},
		editLoading: {
			type: Boolean,
			default: false,
		},
	},
	components: {
		AssetsTTS,
		BitsDropdown,
		MusicPlayer,
		GenericPopup,
	},
	emits: [
		'onEditSticker',
		'onNoBitsTryUpload',
		'onDeleteSticker',
		'onRangeDataStart',
		'onRangeDataEnd',
		'onOpenTwitterFollow',
		'onOpenDiscordFollow',
		'onViewAllTags',
	],
	setup(props, ctx) {
		const eyeImages = reactive({ items: [] });
		const mouthImages = reactive({ items: [] });

		const preview = ref(false);
		const audioFrequency = ref(0);
		let audioPlayer = null;

		const languageList = ref(LANGUAGES);

		const supportedVoicesString = computed(() => {
			return formatSupportedLangs(elSupportedLangsNonEnglish());
		});

		// A slider value is being changed, so do not recalc things like the audio preview URL.
		const isInDataRangeChange = ref(false);

		onMounted(() => {
			eyeImages.items = [...eyePresets];
			mouthImages.items = [...mouthPresets];
		});

		onBeforeUnmount(() => {
			if (audioPlayer) {
				audioPlayer.pause();
				audioPlayer = null;
			}
		});

		const copied = ref(false);
		const browserSourceLink = `https://${getEndpointHost()}/tts-co-host/${getUserId()}-${
			props.stickerSlot + 1
		}/ttsch`;

		// For a bonus slot for voice shares, retrieve the voice from the store.
		const bonusSharedVoice = computed(() => {
			return props.sticker.ttsVoice;
		});

		// Audio preview URL for a shared voice.
		const bonusAudioUrl = computed(() => {
			// Do not recalculate if we are in the middle of a data range change.
			if (bonusSharedVoice.value) {
				return getAudioPreviewUrl(bonusSharedVoice.value.id, 'sticker-voice-preview');
			}
			return null;
		});

		// Profile pic of the streamer who shared the voice.
		const bonusProfilePic = computed(() => {
			if (bonusSharedVoice.value && props.sticker.ttsVoice.sharedFrom) {
				return props.sticker.ttsVoice.sharedFrom.profilePic;
			}
			return null;
		});

		const stickerNameRules = [
			v => !!v || 'Sticker name Required',
			v => v.length <= 25 || 'Max 25 characters allowed.',
		];

		const editToggles = reactive({
			showImageEdit: false,
			showNameEdit: false,
			showNameInput: false,
		});

		const errorDialog = reactive({
			show: false,
			primaryHeader: 'Error',
			copy: 'An error occurred.',
		});

		// Data values for custom value handling.
		const stickerName = ref(props.sticker.name);

		// Set Voice Type
		const stickerVoiceType = ref(
			props.sticker.ttsVoice && props.sticker.ttsVoice.audioSource === 'AMAZON'
				? VoiceType.STANDARD
				: VoiceType.AI,
		);

		const imageInput = ref(null);
		const stickerImage = ref(null);

		const replacedImage = ref(null);
		const stickerImageSrc = computed(() => {
			if (!replacedImage.value) {
				return getHostedAssetPath() + '/' + props.sticker.tts.baseImage;
			} else {
				return replacedImage.value;
			}
		});

		// Compute voice list for AI or Standard.
		const voices = computed(() => {
			const voiceList = store.state.app.ttsVoices.filter(v => {
				if (stickerVoiceType.value === VoiceType.AI) {
					return v.voiceType === VoiceType.AI;
				} else {
					return (
						v.voiceType === VoiceType.STANDARD &&
						v.languageCode.startsWith(props.sticker.tts.language + '-')
					);
				}
			});

			return voiceList;
		});

		// Specific values for UI list items.
		const aiVoices = computed(() => {
			if (stickerVoiceType.value === VoiceType.AI) {
				return voices.value;
			} else {
				return [];
			}
		});

		// Specific values for UI list items.
		const standardVoices = computed(() => {
			if (stickerVoiceType.value === VoiceType.STANDARD) {
				return voices.value;
			} else {
				return [];
			}
		});

		watch(
			() => stickerVoiceType.value,
			() => {
				props.sticker.tts.voiceId = voices.value[0].id;
				actions.onVoiceChange();
			},
		);

		// When language changes, set to first voice.
		watch(
			() => props.sticker.tts.language,
			() => {
				props.sticker.tts.voiceId = voices.value[0].id;
				actions.onVoiceChange();
			},
		);

		// Calculated UI value for stickiness.
		const stickiness = computed({
			get() {
				return rangeValueConverter.toUiValue(props.sticker.stickiness, 10, 45, 100);
			},
			set(newValue) {
				props.sticker.stickiness = rangeValueConverter.toDbValue(newValue, 10, 45, 100);
			},
		});

		// If a co-host sticker, ensure that the browser source is active.
		// Note that this returns 'true' if the sticker is not a co-host sticker.
		const isCoHostBrowserSourceActive = computed(() => {
			if (props.sticker.stickerType == StickerType.TTS) {
				if (props.sticker.tts.displayMode == TtsStickerDisplayMode.CO_HOST) {
					const slot = store.state.app.ttsCoHostSlotStatuses.find(e => e.slot == props.stickerSlot);
					return slot ? slot.online : false;
				}
			}

			return true;
		});

		const audioInput = ref(null);
		const alertInput = ref(null);
		let changedAlertUrl = null;

		const actions = {
			// Puts the sticker name into edit mode.
			showInput: function () {
				editToggles.showNameInput = true;
			},
			// Opens the browse for local file dialog.
			editSticker: function () {
				ctx.emit('onEditSticker', {
					sticker: props.sticker,
					type: props.stickerType,
					stickerSlot: props.stickerSlot,
				});
			},
			getAiIcon(item) {
				const name = item.name;
				if (name.includes('[A.I. Personal]')) {
					return require('@/assets/icons/AIPersonal.png');
				} else if (name.includes('[A.I. Shared]')) {
					return require('@/assets/icons/AIShared.png');
				} else {
					return require('@/assets/icons/AIGlobal.png');
				}
			},
			// Called after an image is selected from the file dialog;
			onImagePick: function (e) {
				const afterScale = () => {
					if (props.sticker.stickerBlob) {
						replacedImage.value = createObjectUrlFromBlob(props.sticker.stickerBlob);
					}
				};

				const onScaleError = error => {
					if (error.response) {
						errorDialog.copy = error.response.data.error;
					} else {
						errorDialog.copy = error;
					}
					errorDialog.show = true;
				};

				onImagePicked(e, props.sticker, afterScale, onScaleError);
			},
			// Called after the sticker name is edited.
			setStickerName: function () {
				if (stickerName.value.length <= 25) {
					props.sticker.name = stickerName.value;
				} else {
					stickerName.value = props.sticker.name;
				}
				editToggles.showNameInput = false;
			},
			cancelStickerName: function () {
				stickerName.value = props.sticker.name;
				editToggles.showNameInput = false;
			},
			viewAllTags() {
				ctx.emit('onViewAllTags', props.sticker);
			},
			// Called after the # of bits is selected.
			setSku: function (item) {
				props.sticker.sku = item.value;
			},
			// Called when a voice is changed so we can change the minimum sku, etc.
			onVoiceChange() {
				const voice = store.state.app.ttsVoices.find(v => v.id === props.sticker.tts.voiceId);
				if (voice) {
					// Set min bits.
					const bits = props.sticker.sku;
					if (bits && typeof bits === 'string' && bits.includes('ticket_')) {
						const value = Number(bits.split('_')[1]);
						if (value < voice.minBits) {
							props.sticker.tts.bits = 'ticket_' + voice.minBits;
						}
					} else if (!bits || (bits && typeof bits === 'string' && bits === 'free')) {
						props.sticker.tts.bits = 'ticket_' + voice.minBits;
					}

					// Set Min Bits for CREATE Sticker
					if (!props.sticker.id) {
						if (voice.audioSource === 'AMAZON' && voice.minBits < 100) {
							props.sticker.sku = 'ticket_100';
						}
						if (voice.audioSource !== 'AMAZON' && voice.minBits < 200) {
							props.sticker.sku = 'ticket_200';
						}
					}
				}
			},
			getImagePath: function () {
				if (props.stickerType == StickerType.GLOBAL) {
					return `${getGlobalStickerAssetPath()}`;
				} else {
					return `${getCustomStickerAssetPath(getUserId())}`;
				}
			},
			copy: () => {
				navigator.clipboard.writeText(browserSourceLink).then(() => {
					copied.value = true;
					setTimeout(() => {
						copied.value = false;
					}, 2000);
				});
			},
			playAudio: function () {
				if (props.sticker.tts.alertSound) {
					actions.playAlertSound();
				} else {
					actions.playTestAudio();
				}
			},
			playAlertSound() {
				const audioElement = document.createElement('audio');
				audioElement.id = 'stickerAudio';

				let audioFile = null;

				if (props.sticker.tts.alertSound) {
					audioFile = getHostedAssetPath() + '/' + props.sticker.tts.alertSound;
				}

				audioElement.src = audioFile;

				audioElement.play();

				audioElement.addEventListener('playing', () => {
					preview.value = true;
				});

				audioElement.addEventListener('ended', () => {
					preview.value = false;
				});
			},
			playTestAudio() {
				const audioCtx = new AudioContext();
				const audioElement = document.createElement('audio');
				audioElement.id = 'stickerAudio';

				let audioFile = null;

				if (props.sticker.tts.alertSound) {
					audioFile = getHostedAssetPath() + '/' + props.sticker.tts.alertSound;
				} else {
					audioFile = getAudioPreviewUrl(props.sticker.tts.voiceId, 'sticker-voice-preview');
				}

				audioElement.src = audioFile;

				audioElement.crossOrigin = 'anonymous';

				audioPlayer = audioElement;

				const source = audioCtx.createMediaElementSource(audioElement);

				const analyser = audioCtx.createAnalyser();
				analyser.fftSize = 2048;

				source.connect(analyser);

				analyser.connect(audioCtx.destination);

				const frequencyData = new Uint8Array(analyser.frequencyBinCount);

				audioElement.volume = Number(props.sticker.volume) / 100;

				audioElement.addEventListener('error', () => {
					store.commit(
						'app/SET_GLOBAL_ERROR_MESSAGE',
						'Sorry, an error occurred playing audio. You may have exceeded your daily limit?',
					);
				});

				audioElement.play();

				audioElement.addEventListener('playing', () => {
					preview.value = true;
				});

				let animationId = null;

				function renderFrame() {
					if (audioElement.ended) {
						cancelAnimationFrame(animationId);
						audioPlayer = null;
						audioFrequency.value = 0;
						return;
					}

					animationId = requestAnimationFrame(renderFrame);
					analyser.getByteFrequencyData(frequencyData);
					// process frequency data
					const sum = frequencyData.reduce((a, b) => a + b, 0);
					const avg = sum / frequencyData.length;

					audioFrequency.value = avg;
				}

				renderFrame();

				audioElement.addEventListener('ended', () => {
					audioFrequency.value = 0;
					preview.value = false;
					audioPlayer = null;
					cancelAnimationFrame(animationId);
				});
			},
			previewSticker: function () {
				let url;
				if (props.stickerType == StickerType.GLOBAL) {
					url = `/global-sticker/preview/${props.sticker.uuid}`;
				} else {
					url = `/custom-sticker/preview/${props.sticker.uuid}`;
				}
				axios.post(url).catch(error => console.log(error));
			},
			// Opens the browse for local file dialog.
			pickAlertFile: function () {
				alertInput.value.click();
			},
			onAlertPick: function (e) {
				const afterPicked = () => {
					if (props.sticker.tts.alertSoundBlob) {
						changedAlertUrl = createObjectUrlFromBlob(props.sticker.tts.alertSoundBlob);
					}
				};

				const onError = error => {
					if (error.response) {
						errorDialog.copy = error.response.data.error;
					} else {
						errorDialog.copy = error;
					}
					errorDialog.show = true;
				};

				onAlertPicked(e, props.sticker, afterPicked, onError);
			},
			pickAudioFile: function () {
				audioInput.value.click();
			},
			onAudioPick: function (e) {
				const afterPicked = () => {
					if (props.sticker.audioBlob) {
						changedAudioUrl = createObjectUrlFromBlob(props.sticker.audioBlob);
					}
				};

				const onError = error => {
					if (error.response) {
						errorDialog.copy = error.response.data.error;
					} else {
						errorDialog.copy = error;
					}
					errorDialog.show = true;
				};

				onAudioPicked(e, props.sticker, afterPicked, onError);
			},
			onDeleteSticker: function (uuid) {
				ctx.emit('onDeleteSticker', uuid);
			},
			onLockedClick: function (sticker) {
				if (sticker.isTwitterSlot) {
					ctx.emit('onOpenTwitterFollow', true);
					return;
				}
				if (sticker.isDiscordSlot) {
					ctx.emit('onOpenDiscordFollow', true);
					return;
				} else if (!isBitsEnabled.value) {
					ctx.emit('onNoBitsTryUpload');
				}
			},
			// A slider has started to change. We check this to disable the watch on
			// data changes until the final change is complete to avoid many server calls.
			onRangeDataStart: function () {
				isInDataRangeChange.value = true;
				ctx.emit('onRangeDataStart', props.sticker);
			},
			onRangeDataEnd: function () {
				isInDataRangeChange.value = false;
				ctx.emit('onRangeDataEnd', props.sticker);
			},

			defaultProfilePic: function () {
				return require('@/assets/images/misc/profile.png');
			},

			reduceAsset(value, percentage) {
				return (value / 100) * Number(percentage);
			},
		};

		return {
			copied,
			browserSourceLink,
			editToggles,
			errorDialog,

			languageList,
			supportedVoicesString,
			VoiceType,
			voices,
			aiVoices,
			standardVoices,
			stickerVoiceType,

			audioFrequency,
			preview,
			actions,

			stickerName,
			stickerImage,
			stickerImageSrc,
			stickiness,
			imageInput,
			audioInput,
			alertInput,
			changedAlertUrl,
			parseBits,
			isBitsEnabled,

			bonusSharedVoice,
			bonusAudioUrl,
			bonusProfilePic,
			isInDataRangeChange,

			isCustom: props.stickerType == StickerType.CUSTOM,
			isReward: props.stickerType == StickerType.REWARD,
			isGlobal: props.stickerType == StickerType.GLOBAL,
			isTTS: props.stickerType == StickerType.TTS,
			isCoHostBrowserSourceActive,
			stickerNameRules,
			TtsStickerDisplayMode,

			icons: {
				closeIcon: mdiClose,
				pencilIcon: mdiPencil,
				deleteIcon: mdiDelete,
				plusIcon: mdiPlus,
				arrow: mdiChevronDown,
				volume: mdiVolumeLow,
				lock: mdiLock,
				drag: mdiDrag,
				check: mdiCheck,
				mute: mdiVolumeMute,
			},
		};
	},
};
</script>

<style lang="scss" scoped>
.card-border {
	border: 1px solid transparent;
}

.card-border:hover {
	border: 1px solid #4a93d9;
}

.upload-area {
	height: 315px;
}

.upload-btn,
.lock-btn {
	position: absolute;
	bottom: 20px;
	left: 5%;
	width: 90%;
}

.unlock-area {
	height: 298px;
}

.sticker-header {
	position: relative;
	height: 70px;
	z-index: 0;
}

.base-image {
	position: relative;
	margin: auto;
}

.global-sticker-header {
	position: relative;
	height: 60px;
	z-index: 0;
}

.sticker-image {
	position: relative;
	z-index: 0;
}

.disabled-image {
	position: absolute;
	width: 100%;
	max-width: 350px;
	height: 150px;
	top: 0;
	left: 0;
	background: var(--v-cardBg-base) !important;
	opacity: 0.8;
	z-index: 9;
}

.name-edit-icon {
	position: absolute;
	right: 45%;
	top: 14px;
	transition: 0.3s ease-in-out;
}

.edit-icon {
	position: absolute;
	top: -10px;
	right: 20%;
	z-index: 2;
	transition: 0.2s ease-in-out;
}

.sticker-header .drag-icon {
	position: absolute;
	top: 22px;
	right: 0px;
}

.bits-btn {
	position: relative;
}

.bits-dropdown {
	height: 200px;
	overflow-y: scroll;
}

.twitter-icon,
.discord-icon {
	position: absolute;
	left: 5px;
	top: 20px;
}

@media screen and (min-width: 1264px) and (max-width: 1340px) {
	.text-xs {
		font-size: 11px !important;
	}

	.text-md {
		font-size: 13px !important;
	}
}
</style>
