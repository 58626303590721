<template>
	<v-card>
		<v-snackbar v-model="isFileError" color="error" :timeout="2500" top>
			{{ fileErrorMessage }}
		</v-snackbar>
		<v-card-text class="pt-0">
			<div class="step-section">
				<h4 class="text-center textColor--text fs--17 fw--500">Upload your sticker image or animated GIF:</h4>

				<div v-if="!sticker.stickerBlob" class="upload-area px-3 px-sm-0 text-center mt-4">
					<v-icon color="boldPurple" size="120">{{ icons.uploadIcon }}</v-icon>

					<div class="text-center mt-4">
						<v-btn
							@click="actions.pickFile"
							color="pink"
							:width="$vuetify.breakpoint.xsOnly ? 'auto' : '220'"
							min-height="46"
							depressed>
							Select File
						</v-btn>
						<input
							type="file"
							accept="image/png,image/gif"
							class="d-none"
							@change="actions.onImagePick"
							ref="imageInput" />

						<p class="fs--13 fw--500 mb-0 mt-5 shinyPurple--text">
							Hint: Apply a 10px white stroke/outline to your image to give it a "sticker" look and feel.
						</p>
					</div>
				</div>
				<div v-else class="upload-area px-3 px-sm-0 text-center mt-4">
					<img :src="imageSource" width="150" alt="Sticker Image" />

					<div class="text-center mt-4">
						<v-btn
							@click="actions.pickFile"
							color="pink"
							outlined
							:width="$vuetify.breakpoint.xsOnly ? 'auto' : '220'"
							depressed>
							Change File
						</v-btn>
						<input
							type="file"
							accept="image/png,image/gif"
							class="d-none"
							@change="actions.onImagePick"
							ref="imageInput" />

						<p class="fs--13 fw--500 mb-0 mt-5 shinyPurple--text">
							Hint: Apply a 10px white stroke/outline to your image to give it a "sticker" look and feel.
						</p>
					</div>
				</div>
				<p class="fs--12 fw--500 mt-5 shinyPurple--text text-center">
					Custom stickers will render on screen at the resolution they are uploaded. For optimal visibility,
					we strongly recommend uploading images that are approximately 275-350 pixels on their widest side
					and no smaller than 200 pixels.
				</p>
				<p class="fs--12 fw--500 mb-0 shinyPurple--text text-center">
					The system will automatically scale down stickers to be no larger than 350 pixels on their widest
					side. The final file size after resizing must be no larger than 512kb.
				</p>
			</div>
		</v-card-text>
		<div class="footer-section text-center" :class="!sticker.stickerBlob && 'py-9'">
			<v-btn v-if="sticker.stickerBlob" class="my-6 px-12" depressed @click="actions.moveNext" color="pink"
				>Next</v-btn
			>
		</div>
	</v-card>
</template>

<script>
import { computed, ref } from '@vue/composition-api';
import { onImagePicked, createObjectUrlFromBlob } from '../../../../utils/sticker.js';
import { mdiCloudUploadOutline } from '@mdi/js';
export default {
	props: {
		sticker: {
			type: Object,
			required: true,
		},
	},
	emits: ['onMove'],
	setup(props, ctx) {
		const isFileError = ref(false);
		const fileErrorMessage = ref(null);
		const imageInput = ref(null);

		const imageSource = computed(() => {
			if (props.sticker.stickerBlob) {
				return createObjectUrlFromBlob(props.sticker.stickerBlob);
			} else {
				return '';
			}
		});

		const actions = {
			moveNext: function () {
				ctx.emit('onMove', 2);
			},
			pickFile: function () {
				imageInput.value.click();
			},
			onImagePick: function (e) {
				onImagePicked(e, props.sticker, null, function (error) {
					fileErrorMessage.value = error;
					isFileError.value = true;
				});
			},
		};

		return {
			actions,
			imageSource,
			imageInput,
			isFileError,
			fileErrorMessage,
			icons: {
				uploadIcon: mdiCloudUploadOutline,
			},
		};
	},

	methods: {},
};
</script>
