<template>
	<v-card>
		<v-snackbar v-model="copied" :timeout="2000" top color="purple"> Link Copied Successfully. </v-snackbar>
		<v-card-text class="pt-0">
			<div class="step-section">
				<h4 class="text-center textColor--text fs--17 fw--500">Select a Display Mode:</h4>

				<div class="upload-area text-center mt-4">
					<v-row class="mode-images" justify="center" no-gutters>
						<v-col cols="5" class="sticker-mode">
							<img src="@/assets/images/StickerMode.png" width="100%" alt="Sticker Mode" />
						</v-col>
						<v-col cols="5" class="cohost-mode">
							<img src="@/assets/images/Co-Host.png" width="100%" alt="Co-Host Mode" />
						</v-col>

						<img class="or-image" src="@/assets/images/-OR-.png" alt="OR" />
					</v-row>

					<v-row justify="center" no-gutters>
						<v-col cols="12" sm="5" class="text-center">
							<div class="d-flex align-center justify-center">
								<v-radio-group v-model="mode" style="max-width: 150px">
									<v-radio
										:value="TtsStickerDisplayMode.STICKER"
										color="pink"
										label="Sticker Mode"></v-radio>
								</v-radio-group>
							</div>

							<p class="fs--12 offWhite--text mb-0">- Displays in the main browser source</p>
							<p class="fs--12 offWhite--text mb-0">- Only visible when activated by a user.</p>
							<p class="fs--12 offWhite--text mb-0">- Rolls on like a sticker, talks, then rolls off.</p>
							<p class="fs--12 offWhite--text mb-0">- Position / tilt will be random.</p>
						</v-col>
						<v-col cols="12" sm="1" class="text-center">
							<v-divider v-if="$vuetify.breakpoint.xsOnly"></v-divider>
							<v-divider v-else vertical></v-divider>
						</v-col>
						<v-col cols="12" sm="5">
							<div class="d-flex align-center justify-center">
								<v-radio-group v-model="mode" style="max-width: 150px">
									<v-radio
										:value="TtsStickerDisplayMode.CO_HOST"
										color="pink"
										label="Co-Host Mode"></v-radio>
								</v-radio-group>
							</div>

							<p class="fs--12 offWhite--text mb-0">- Displays in separate browser source</p>
							<p class="fs--12 offWhite--text mb-0">- Stays on screen with you at all times.</p>
							<p class="fs--12 offWhite--text mb-0">- Activated when a user sends TTS message.</p>
							<p class="fs--12 offWhite--text mb-0">- Position set in your streaming software.</p>

							<div class="d-flex mt-3">
								<v-text-field
									v-model="browserSourceLink"
									solo
									hide-details
									dense
									flat
									background-color="purple"
									color="fieldFore"
									:class="mode === TtsStickerDisplayMode.STICKER && 'blury-link'"
									disabled>
								</v-text-field>
								<v-btn
									color="secondary"
									depressed
									:disabled="mode === TtsStickerDisplayMode.STICKER"
									class="ml-1"
									@click="actions.copy()"
									>Copy</v-btn
								>
							</div>
						</v-col>
					</v-row>
				</div>
			</div>
		</v-card-text>
		<div class="footer-section d-flex flex-wrap justify-center px-3 px-sm-0">
			<v-btn class="mt-3 my-sm-6 px-12 mr-2" depressed @click="actions.moveBack" outlined color="pink"
				>Back
			</v-btn>
			<v-btn class="mt-3 my-sm-6 px-12" depressed @click="actions.moveNext" color="pink">Next</v-btn>
		</div>
	</v-card>
</template>

<script>
import { onMounted, ref, watch } from '@vue/composition-api';
import { getUserId } from '@/utils/auth';
import { getEndpointHost } from '@/utils/config';
import { TtsStickerDisplayMode } from '@/utils/constants';
import { mdiVolumeHigh } from '@mdi/js';
export default {
	props: {
		sticker: {
			type: Object,
			default: null,
		},
	},
	emits: ['onMove', 'onConfigureMode'],
	setup(props, ctx) {
		// If mode changes, notify the parent.
		const mode = ref(TtsStickerDisplayMode.STICKER);
		watch(
			() => mode.value,
			newVal => {
				ctx.emit('onConfigureMode', { displayMode: newVal });
			},
		);

		const browserSourceLink = `https://${getEndpointHost()}/tts-co-host/${getUserId()}-${
			props.sticker.tts.stickerSlot + 1
		}/ttsch`;

		const copied = ref(false);

		onMounted(() => {
			const data = { ...props.sticker.tts };
			mode.value = data.displayMode || TtsStickerDisplayMode.STICKER;
		});

		const actions = {
			moveNext: function () {
				ctx.emit('onMove', 5);
			},
			moveBack: function () {
				ctx.emit('onMove', 3);
			},
			copy: () => {
				navigator.clipboard.writeText(browserSourceLink).then(() => {
					copied.value = true;
					setTimeout(() => {
						copied.value = false;
					}, 2000);
				});
			},
		};

		return {
			mode,
			copied,
			browserSourceLink,
			actions,
			TtsStickerDisplayMode,
			icons: {
				volumeIcon: mdiVolumeHigh,
			},
		};
	},
};
</script>

<style lang="scss" scoped>
.mode-images,
.sticker-mode,
.cohost-mode {
	position: relative;
}

.or-image {
	position: absolute;
	top: 30%;
	left: 45.5%;
}

.sticker-mode {
	img {
		margin-right: -10px;
	}
}
.cohost-mode {
	img {
		margin-left: -20px;
	}
}

@media screen and (max-width: 800px) {
	.or-image {
		top: 27%;
		left: 43%;
	}
}

@media screen and (max-width: 600px) {
	.or-image {
		top: 25%;
		left: 42%;
	}
}
</style>
