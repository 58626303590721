<template>
	<div class="tts-demo">
		<v-row class="demo">
			<v-col cols="12" class="px-3 py-1">
				<h3 class="fs--23 fw--500">How it works</h3>
			</v-col>
			<v-col cols="12" sm="6" md="5" lg="5" xl="6">
				<v-textarea
					v-model="setting.text"
					background-color="purple"
					class="tts-demo-textarea"
					solo
					flat
					no-resize
					rows="6"
					hide-details
					readonly></v-textarea>

				<div class="d-flex align-center justify-center">
					<v-btn
						small
						depressed
						color="secondary"
						class="mt-3"
						:disabled="!setting.text || preview"
						@click="actions.playAudio">
						<v-icon>{{ icons.volume }}</v-icon> Preview TTS
					</v-btn>
				</div>
			</v-col>
			<v-col cols="12" sm="3" md="3" lg="3" xl="3" class="text-center voice-controls">
				<h3 class="fs--13 fw--300 text-left mb-1">Preview different styles!</h3>
				<div class="d-flex align-center my-1">
					<span class="fs--12">Voice: </span>
					<v-autocomplete
						hide-details
						v-model="setting.voiceId"
						item-text="name"
						item-value="id"
						label="Eye"
						:items="voices"
						dense
						flat
						solo
						background-color="secondary"
						class="ml-3">
					</v-autocomplete>
				</div>

				<div class="d-flex align-center my-1">
					<span class="fs--12">Eyes:</span>
					<v-autocomplete
						hide-details
						v-model="currentEye"
						item-text="name"
						:items="eyeImages.items"
						dense
						flat
						solo
						return-object
						background-color="secondary"
						class="ml-4">
					</v-autocomplete>
				</div>

				<div class="d-flex align-center my-1">
					<span class="fs--12">Mouth:</span>
					<v-autocomplete
						hide-details
						v-model="currentMouth"
						item-text="name"
						:items="mouthImages.items"
						dense
						flat
						solo
						return-object
						background-color="secondary"
						class="ml-2">
					</v-autocomplete>
				</div>

				<div class="text-center mt-5 mt-md-4 mt-lg-7">
					<p class="demo-tip text-italic fs--12 offWhite--text mb-0">
						Tip: Drag, Rotate and Resize facial elements!
					</p>
				</div>
			</v-col>
			<v-col cols="12" sm="3" md="4" lg="4" xl="3" class="tts-character">
				<div
					class="current-sticker cursor-pointer"
					:class="[$vuetify.breakpoint.xsOnly && 'xs-sticker', $vuetify.breakpoint.smOnly && 'sm-sticker', $vuetify.breakpoint.mdOnly && 'md-sticker']">
					<drr
						id="eyeddr"
						:parent="true"
						:x="position.eyeX"
						:y="position.eyeY"
						:w="position.eyeW"
						:h="position.eyeH"
						:angle="position.eyeAngle"
						:draggable="true"
						:rotateable="true"
						:resizable="true"
						:outerBound="{ w: 250, h: 290, x: 125, y: 145 }"
						:aspectRatio="true">
						<div
							v-if="currentEye"
							id="eyeImage"
							style="position: absolute; width: 100%; height: 100%"
							:style="{
								backgroundImage: `url(${actions.getImage('eyes', currentEye, null)})`,
								backgroundSize: 'contain',
								backgroundPosition: 'center center',
							}"></div>
					</drr>

					<drr
						id="mouthddr"
						:parent="true"
						:x="position.mouthX"
						:y="position.mouthY"
						:w="position.mouthW"
						:h="position.mouthH"
						:angle="position.mouthAngle"
						:draggable="true"
						:resizable="true"
						:aspectRatio="true"
						:outerBound="{ w: 250, h: 290, x: 125, y: 145 }">
						<div
							v-show="currentMouth && preview && audioFrequency > 0"
							id="mouthImage"
							style="position: absolute; width: 100%; height: 100%"
							:style="{
								backgroundImage: `url(${actions.getImage('mouth', currentMouth, null)})`,
								backgroundSize: 'contain',
								backgroundPosition: 'center center',
							}"></div>

						<div
							v-show="!preview || (preview && audioFrequency <= 0)"
							style="position: absolute; width: 100%; height: 100%"
							:style="{
								backgroundImage: `url(${actions.getClosedImage(currentMouth)})`,
								backgroundSize: 'contain',
								backgroundPosition: 'center center',
							}"></div>
					</drr>
				</div>

				<!-- <div class="stickers-slider d-flex align-center justify-space-between">
						<v-btn icon @click="actions.setCurrentSticker('left')">
							<v-icon>{{ icons.arrowLeft }}</v-icon>
						</v-btn>
						<span class="fieldFore--text">{{ currentSticker.name }}</span>
						<v-btn icon @click="actions.setCurrentSticker('right')">
							<v-icon>{{ icons.arrowRight }}</v-icon>
						</v-btn>
					</div> -->

				<!-- <v-carousel hide-delimiters>
						<v-carousel-item
							v-for="(item, i) in stickerImages"
							:key="i"
							reverse-transition="fade-transition"
							transition="fade-transition">
							<template #default>
								<div
									class="current-sticker cursor-pointer"
									:style="{ 'background-image': `url(${actions.getImage('mouth', item)})` }"
									@click="actions.setCurrentSticker(item)">
								</div>
								<div class="text-center image-name fieldFore--text">{{ item.name }}</div>
							</template>
						</v-carousel-item>

						<template v-slot:prev="{ on, attrs }">
							<v-btn class="arrow-btn left" icon v-bind="attrs" v-on="on">
								<v-icon>{{ icons.arrowLeft }}</v-icon>
							</v-btn>
						</template>

						<template v-slot:next="{ on, attrs }">
							<v-btn class="arrow-btn right" icon v-bind="attrs" v-on="on">
								<v-icon>{{ icons.arrowRight }}</v-icon>
							</v-btn>
						</template>
					</v-carousel> -->
			</v-col>
		</v-row>
	</div>
</template>

<script>
import { computed, ref, reactive, watch, onBeforeUnmount } from '@vue/composition-api';
import store from '@/store';
import { DefaultTtsVoiceId, VoiceLabOwnership } from '@/utils/constants';
import { TTS_ASSETS_BASE, eyePresets, mouthPresets } from '@/utils/utils';
import { getMessage } from '@/utils/messages';
import { getAudioPreviewUrl } from '@/utils/tts-utils';
import { mdiChevronLeft, mdiChevronRight, mdiVolumeLow, mdiUpload } from '@mdi/js';

export default {
	setup() {
		const preview = ref(false);
		const currentSticker = ref({ name: 'Mascot', file: 'mascot.gif' });

		let eyeImages = reactive({
			items: [...eyePresets],
		});

		let mouthImages = reactive({
			items: [...mouthPresets],
		});

		const position = ref({
			eyeX: 110,
			eyeY: 129,
			mouthX: 116,
			mouthY: 160,
			eyeW: 117,
			eyeH: 50,
			mouthW: 80,
			mouthH: 45,
			eyeAngle: -10,
			mouthAngle: -10,
		});

		const voices = computed(() => {
			return store.state.app.ttsVoices.filter(v => v.ownership == VoiceLabOwnership.GLOBAL);
		});

		const setting = reactive({
			text: getMessage('tts-how-it-works-copy'),
			voiceId: DefaultTtsVoiceId,
		});

		// Change to first voice.
		watch(
			() => voices.value,
			() => {
				setting.voiceId = voices.value[0] ? voices.value[0].id : DefaultTtsVoiceId;
			},
		);

		const currentEye = ref({ name: 'Eyes 20', file: '20.gif' });
		const currentMouth = ref({ name: 'Mouth 20', file: '20.gif' });

		let audioPlayer = null;
		const audioFrequency = ref(0);

		onBeforeUnmount(() => {
			if (audioPlayer) {
				audioPlayer.pause();
				audioPlayer = null;
			}
		});

		const actions = {
			getImage(type, image) {
				if (type === 'mouth' && image && typeof image.file === 'string') {
					return TTS_ASSETS_BASE + '/' + type + '/animated/' + image.file;
				}
				if (type === 'eyes' && image && typeof image.file === 'string') {
					return TTS_ASSETS_BASE + '/' + type + '/' + image.file;
				}

				return null;
			},
			getClosedImage(sticker) {
				return TTS_ASSETS_BASE + '/mouth' + '/closed/' + sticker.file;
			},

			playAudio() {
				// create an AudioContext object
				const audioCtx = new AudioContext();

				// load the audio file into a buffer
				const audioFile = getAudioPreviewUrl(setting.voiceId, 'how-it-works-voice-preview');

				// const audioFile = require("./../web/sounds/speech_pause.mp3");

				const audioElement = document.createElement('audio');
				audioElement.id = 'stickerAudio';

				audioElement.src = audioFile;
				audioElement.crossOrigin = 'anonymous';

				audioPlayer = audioElement;

				const source = audioCtx.createMediaElementSource(audioElement);

				const analyser = audioCtx.createAnalyser();
				analyser.fftSize = 2048;

				source.connect(analyser);

				analyser.connect(audioCtx.destination);

				const frequencyData = new Uint8Array(analyser.frequencyBinCount);

				audioElement.addEventListener('error', () => {
					store.commit(
						'app/SET_GLOBAL_ERROR_MESSAGE',
						'Sorry, an error occurred playing audio. You may have exceeded your daily limit?',
					);
				});

				audioElement.play();
				audioElement.addEventListener('playing', () => {
					preview.value = true;
				});

				let animationId = null;

				function renderFrame() {
					if (audioElement.ended) {
						cancelAnimationFrame(animationId);
						audioPlayer = null;
						audioFrequency.value = 0;
						return;
					}

					animationId = requestAnimationFrame(renderFrame);
					analyser.getByteFrequencyData(frequencyData);
					// process frequency data
					const sum = frequencyData.reduce((a, b) => a + b, 0);
					const avg = sum / frequencyData.length;

					audioFrequency.value = avg;
				}

				renderFrame();

				audioElement.addEventListener('ended', () => {
					audioFrequency.value = 0;
					preview.value = false;
					audioPlayer = null;
					cancelAnimationFrame(animationId);
				});
			},
		};

		return {
			preview,
			audioPlayer,
			audioFrequency,
			position,
			eyeImages,
			mouthImages,
			currentEye,
			currentMouth,
			currentSticker,
			setting,
			actions,
			voices: voices,
			icons: {
				volume: mdiVolumeLow,
				arrowLeft: mdiChevronLeft,
				arrowRight: mdiChevronRight,
			},
		};
	},
};
</script>

<style scoped>
.current-sticker {
	position: absolute;
	width: 250px;
	height: 325px;
	background-image: url('../../../assets/images/mascot/TTS-Mascott.png');
	background-repeat: no-repeat;
	background-size: contain;
	background-position: right;
	bottom: -10.5%;
	right: -16px;
	float: right;
	z-index: 9 !important;
	border-radius: 0px;
	background-color: var(--v-lightPurple-base);
}

.md-sticker {
	bottom: -12%;
}

.sm-sticker {
	bottom: -12%;
}

.xs-sticker {
	position: relative;
	margin-bottom: -22px;
	margin-right: -20px;
	overflow-x: visible;
	/* bottom: -10%; */
}

#eyeddr:hover,
#mouthddr:hover {
	cursor: all-scroll !important;
}

.stickers-slider {
	width: 150px;
	float: right;
	margin-top: 10px;
}

.arrow-btn {
	top: -30px;
	background: transparent;
}

.image-name {
	margin-top: 17px;
}

.demo-tip {
	line-height: 0.9rem !important;
}

@media screen and (max-width: 1366px) {
	/* .current-sticker {
		width: 240px;
		height: 260px;
		top: 35px;
		right: -36px;
		display: none;
	} */

	/* .demo {
		display: none;
	} */
}

.v-window__prev {
	background: none !important;
}

/* .drr {
	pointer-events: none !important;
} */

/* .drr::before,
.drr.active::before {
	border: none !important;
	outline: none !important;
} */
</style>
