<template>
	<v-card
		min-height="457"
		height="100%"
		class="card-border"
		:class="sticker.tts && sticker.tts.isBonusTtsShareSlot && 'shared-slot'">
		<v-card-text v-if="sticker">
			<!-- Unlocked Slot -->

			<template v-if="!sticker.isSlotConfigured && sticker.isSlotUnlocked">
				<v-row justify="center" align="center" class="sticker-header mb-0 mb-lg-3">
					<template v-if="sticker.isTwitterSlot">
						<img class="twitter-icon" src="@/assets/images/Twitter.png" alt="Twitter" />
					</template>
					<template v-if="sticker.isDiscordSlot">
						<img class="discord-icon" src="@/assets/images/discord-icon.png" alt="Twitter" />
					</template>
					<v-col cols="9" class="text-center mb-3">
						<template v-if="sticker.tts && sticker.tts.isBonusTtsShareSlot">
							<h4 class="fs--18 fw--300">Bonus TTS Sticker Slot Voice Model Share from</h4>
							<div class="text-center mt-3">
								<!-- Set Icon Dynamically based on the Voice -->
								<img src="@/assets/icons/AIShared.png" class="mr-1" />
								<span
									class="fs--16 fw--500 text-center mb-3 mt-2"
									:class="!sticker.enabled && 'disabledForm--text'">
									{{
										bonusSharedVoice && bonusSharedVoice.sharedFrom
											? bonusSharedVoice.sharedFrom.displayName
											: 'Unknown'
									}}
								</span>
							</div>
						</template>
						<template v-else>
							<h4 class="fs--18 fw--300">{{ sticker.name }}</h4>
						</template>
					</v-col>
				</v-row>
				<div class="upload-area d-flex align-center justify-center">
					<v-btn @click="actions.onOpenUploadWizard" fab color="pink" x-large width="110" height="110">
						<v-icon size="34">
							{{ icons.plusIcon }}
						</v-icon>
					</v-btn>
				</div>
				<v-btn class="upload-btn" @click="actions.onOpenUploadWizard" depressed color="pink">
					<span>Upload</span>
				</v-btn>
			</template>
		</v-card-text>

		<GenericPopup v-if="errorDialog.show" :modal="errorDialog.show" @close="errorDialog.show = false">
			<template #primaryHeader>{{ errorDialog.primaryHeader }}</template>
			<template>
				<p class="fs--13 fw--400 fieldFore--text">
					{{ errorDialog.copy }}
				</p>
			</template>
		</GenericPopup>
	</v-card>
</template>

<script>
import { reactive, computed } from '@vue/composition-api';
import { StickerType } from '@/utils/constants';
import GenericPopup from '@/components/GenericPopup';
import store from '@/store';
import { mdiPlus } from '@mdi/js';

export default {
	props: {
		sticker: {
			type: Object,
			default: null,
		},
		stickerType: {
			type: String,
			required: true,
		},
		stickerSlot: {
			type: Number,
		},
		isExtensionOnline: {
			type: Boolean,
			default: false,
		},
	},
	components: {
		GenericPopup,
	},
	emits: ['onOpenUploadWizard'],
	setup(props, ctx) {
		const errorDialog = reactive({
			show: false,
			primaryHeader: 'Error',
			copy: 'An error occurred.',
		});

		// For a bonus slot for voice shares, retrieve the shared from voice.
		const bonusSharedVoice = computed(() => {
			if (props.sticker.tts.isBonusTtsShareSlot) {
				return props.sticker.ttsVoice;
			}
			return null;
		});

		const actions = {
			// Initiate the new sticker upload wizard dialog.
			onOpenUploadWizard: function () {
				store.dispatch('app/saveAnalytic', {
					event: 'upload_sticker',
					category: 'engagement',
					label: 'upload_sticker_clicked',
				});

				ctx.emit('onOpenUploadWizard', {
					sticker: props.sticker,
					stickerSlot: props.stickerSlot,
					type: props.stickerType,
				});
			},
		};

		return {
			errorDialog,
			bonusSharedVoice,
			actions,
			isCustom: props.stickerType == StickerType.CUSTOM,
			isReward: props.stickerType == StickerType.REWARD,
			isGlobal: props.stickerType == StickerType.GLOBAL,
			icons: {
				plusIcon: mdiPlus,
			},
		};
	},
};
</script>

<style lang="scss" scoped>
.card-border {
	border: 1px solid transparent;
}

.card-border:hover {
	border: 1px solid #4a93d9;
}

.upload-area {
	height: 315px;
}

.upload-btn,
.lock-btn {
	position: absolute;
	bottom: 20px;
	left: 5%;
	width: 90%;
}

.unlock-area {
	height: 298px;
}

.sticker-header {
	position: relative;
	height: 70px;
	z-index: 0;
}

.global-sticker-header {
	position: relative;
	height: 60px;
	z-index: 0;
}

.sticker-image {
	position: relative;
	z-index: 0;
}

.disabled-image {
	position: absolute;
	width: 100%;
	max-width: 350px;
	height: 120px;
	top: 0;
	left: 0;
	background: var(--v-cardBg-base) !important;
	opacity: 0.8;
	z-index: 9;
}

.name-edit-icon {
	position: absolute;
	right: 45%;
	top: 0px;
	transition: 0.3s ease-in-out;
}

.edit-icon {
	position: absolute;
	top: -10px;
	right: 20%;
	z-index: 2;
	transition: 0.2s ease-in-out;
}

.sticker-header .drag-icon {
	position: absolute;
	top: 8px;
	right: 0px;
}

.bits-btn {
	position: relative;
}

.bits-dropdown {
	height: 200px;
	overflow-y: scroll;
}

.twitter-icon,
.discord-icon {
	position: absolute;
	left: 5px;
	top: 20px;
}

@media screen and (min-width: 1264px) and (max-width: 1340px) {
	.text-xs {
		font-size: 11px !important;
	}
	.text-md {
		font-size: 13px !important;
	}
}
</style>
